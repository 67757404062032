/**
 * We have daterange picker here..
 */
import React, { useState } from 'react'
import { Autocomplete, TextField, Typography, createFilterOptions } from '@mui/material'
import { FilterTable } from 'components/table/FilterTable'
import { useDispatch, useSelector } from 'react-redux'
import { get } from 'lodash'
import {
  saveCurrentFilter,
  setAssessmentFilterCreatedAt,
  setAssessmentFilterProductEventId,
  setAssessmentFilterStatus,
  setAssessmentFilterUpdatedAt,
  setAssessmentName,
  setFilterUserId,
  setUser,
} from 'store/modules/assessments'
import { useNavigate, useOutletContext, useParams } from 'react-router'
import { useOnValueChange } from 'utils/hooks/useOnValueChange'
import {
  AssessmentOutletContextProps,
  FilterButtonProps,
  OptionProps,
} from 'views/assessments/index'

// we want to add client emial to autocomplete
const clientFilterOptions = createFilterOptions({
  // matchFrom: 'start',
  stringify: (option: OptionProps) => `${option?.label}${option?.metadata?.email}`,
})

/**
 * DefaultID:
 * - the default option to use when on load
 * - this occurs when we navigate into assessment via an external source:
 *   - Dashboard Card
 *   - Via URL with User ID
 */
const FilterButton = ({
  defaultId,
  label,
  options,
  onSelect,
  filterOptions,
}: FilterButtonProps) => {
  // use REF to prevent rerendering!!
  const [option, setOption] = useState<OptionProps | null | undefined>(null)
  const [variant, setVariant] = useState<'outlined' | 'filled' | 'standard'>('outlined')
  const [autocompleteKey, setAutocompleteKey] = useState<number>(0) // Step 1: Add a key state
  const savedFilter = useSelector((state) => get(state, 'assessments.savedFilter', {}))
  const navigate = useNavigate()
  const { setPage } = useOutletContext<AssessmentOutletContextProps>()

  const handleChange = (_, changeOption, type) => {
    if (changeOption) {
      setOption(changeOption)
      onSelect(changeOption.value)
    } else {
      onSelect()
      setOption(null)
    }

    if (type !== 'clear') {
      setVariant('filled')
    } else {
      setVariant('outlined')
      setAutocompleteKey((prevKey) => prevKey + 1) // Step 2: Update the key to reset the component

      // if we have clients loaded, ensure url is also cleared
      if (defaultId && label === 'Client') {
        navigate('/assessments')
      }
    }

    // everytime we change this filter, set Page to 0
    setPage(0)
  }

  useOnValueChange(JSON.stringify({ options, defaultId }), () => {
    if (label === 'Client') {
      // this is when we have user Id on the url params
      const defaultValue = options?.find(({ value }) => value?.toString() === defaultId)
      const savedValue = options?.find(({ value }) => value === savedFilter.userId)

      if (defaultValue) {
        setOption(defaultValue)
        onSelect(defaultValue?.value)
        setVariant('filled')
      }
      // this is from redux
      else if (savedValue) {
        setOption(savedValue)
        onSelect(savedValue?.value)
        setVariant('filled')
      }
    } else if (label === 'Assessment') {
      // defaullt value is when user clicks on
      const defaultValue = options?.find(({ value }) => value === defaultId)
      const savedValue = options?.find(({ value }) => value === savedFilter.productEventId)

      if (defaultValue) {
        setOption(defaultValue)
        onSelect(defaultValue?.value)
        setVariant('filled')
      } else if (savedValue) {
        setOption(savedValue)
        onSelect(savedValue?.value)
        setVariant('filled')
        // default case occurs when a user press back / redux data is corrupted
      } else {
        setOption(null)
        onSelect()
        setVariant('outlined')
      }
    } else if (label === 'Status' && savedFilter.status) {
      const defaultValue = options?.find(({ value }) => value === savedFilter.status)
      if (defaultValue) {
        setOption(defaultValue)
        onSelect(defaultValue?.value)
        setVariant('filled')
      }
    } else if (label === 'Created Date' && savedFilter.createdAt) {
      const defaultValue = options?.find(
        ({ value }) => get(value, 'id', null) === savedFilter.createdAt.id
      )
      if (defaultValue) {
        setOption(defaultValue)
        onSelect(defaultValue?.value)
        setVariant('filled')
      }
    } else if (label === 'Last Updated' && savedFilter.updatedAt) {
      const defaultValue = options?.find(
        ({ value }) => get(value, 'id', null) === savedFilter.updatedAt.id
      )
      if (defaultValue) {
        setOption(defaultValue)
        onSelect(defaultValue?.value)
        setVariant('filled')
      }
    }
  })

  const conditionalPopupIndicatorStyle = {
    ...(variant === 'filled' && {
      '& .MuiAutocomplete-popupIndicator': {
        display: 'none',
      },
    }),
  }

  return (
    <Autocomplete
      key={autocompleteKey} // Use the key here to force remount
      fullWidth
      id={`select-${label}`}
      options={options}
      filterOptions={filterOptions}
      value={option}
      onChange={handleChange}
      size="small"
      aria-label={label}
      sx={{
        minWidth: '150px',
        '& .MuiFormLabel-root': {
          fontSize: '0.8125rem',
        },
        '& .MuiInputBase-root .MuiInputBase-input': {
          borderRadius: '9999px', // Apply border radius to the input element
          fontSize: '0.8125rem',
        },
        '& .MuiOutlinedInput-root': {
          borderRadius: '9999px', // Apply border radius to the outline (if using outlined variant)
          fontSize: '0.8125rem',
        },
        '& .MuiFilledInput-input': {
          borderRadius: '9999px', // Apply border radius to the input element specifically
          fontSize: '0.8125rem',
        },
        '& .MuiFilledInput-root': {
          borderRadius: '9999px', // Apply border radius to the filled variant root to match the input
          fontSize: '0.8125rem',
          '&:before': {
            // Remove the underline on focus
            display: 'none',
          },
          '&:after': {
            // Remove the underline after input
            display: 'none',
          },
        },
        ...conditionalPopupIndicatorStyle, // Apply the conditional styling here
      }}
      renderInput={(params) => (
        <TextField {...params} variant={variant} label={label} color="primary" />
      )}
      renderOption={(props, option) => {
        return (
          <Typography
            {...props}
            variant="body2"
            component="li"
            whiteSpace="nowrap"
            key={`filter-open-${JSON.stringify(option)}`}
          >
            {option.label}
          </Typography>
        )
      }}
    />
  )
}

export default function AssessmentFilter() {
  // init hooks
  const dispatch = useDispatch()
  const { userId } = useParams()

  // get clients
  const filterUserId = useSelector((state) => get(state, 'assessments.filter.userId'))
  const filterProductEventId = useSelector((state) =>
    get(state, 'assessments.filter.productEventId', undefined)
  )
  const clients = useSelector((state) => get(state, 'assessments.userData', []))
  const clientOptions = clients.map((client) => {
    // const email = client.email ? ` (${client.email})` : ''
    return {
      label: `${client.fullName}`,
      value: client.id,
      metadata: {
        email: client.email,
      },
    }
  })

  // get assessments names
  const allAssessmentNames = useSelector((state) =>
    get(state, 'assessments.productAssessments', [])
  ).filter(({ metadata }) => !get(metadata, 'isInactive', false))
  const assessmentSessions = useSelector((state) => get(state, 'assessments.sessions', []))
  const filteredAssessmentNames = allAssessmentNames.filter((productEvent) => {
    // check if user has this productEventId
    return !!assessmentSessions.find(
      ({ user, productEventId }) => user.id === filterUserId && productEvent.id === productEventId
    )
  })

  // Normalizing strings to ignore punctuation and case
  function normalizeString(str) {
    return str.toLowerCase().replace(/[\W_]+/g, '') // Remove non-word characters and underscores
  }

  const assessmentNames = (filterUserId
    ? Object.values(filteredAssessmentNames).filter(Boolean)
    : Object.values(allAssessmentNames)
  )
    .map((assessment) => {
      const id = get(assessment, 'id', '')
      const variant = get(assessment, 'metadata.variant', '')
      const assessmentType = get(assessment, 'metadata.assessmentType', '')
      const label = variant ? `${assessmentType} - ${variant}` : assessmentType

      return {
        label,
        value: id,
        metadata: {
          id,
        },
      }
    })
    .sort((a, b) => {
      const normalizedLabelA = normalizeString(a.label)
      const normalizedLabelB = normalizeString(b.label)
      return normalizedLabelA.localeCompare(normalizedLabelB)
    })

  // hardcode status options
  const statusOptions = [
    {
      label: 'Completed',
      value: 'Completed',
    },
    {
      label: 'In Progress',
      value: 'In Progress',
    },
    {
      label: 'Sent to Client',
      value: 'Sent to Client',
    },
  ]

  // Create Dynamic Date filter
  const now = new Date()
  now.setHours(0, 0, 0, 0) // Ensure 'now' is set to midnight before calculations
  const tomorrow = new Date(now)
  tomorrow.setDate(tomorrow.getDate() + 1) // Increment the day by one
  const yesterday = new Date(now)
  yesterday.setDate(yesterday.getDate() - 1)

  const lastSevenDays = new Date(now)
  lastSevenDays.setDate(now.getDate() - 7)

  const lastThirtyDays = new Date(now)
  lastThirtyDays.setDate(now.getDate() - 30)

  const lastSixMonths = new Date(now)
  lastSixMonths.setMonth(now.getMonth() - 6)

  const lastTwelveMonths = new Date(now)
  lastTwelveMonths.setMonth(now.getMonth() - 12)

  const dateOptions = [
    {
      label: 'Today',
      value: { start: now, end: tomorrow, id: 'today' },
    },
    {
      label: 'Yesterday',
      value: { start: yesterday, end: now, id: 'yesterday' },
    },
    {
      label: 'Last 7 days',
      value: { start: lastSevenDays, end: tomorrow, id: 'last7days' },
    },
    {
      label: 'Last 30 days',
      value: { start: lastThirtyDays, end: tomorrow, id: 'last30days' },
    },
    {
      label: 'Last 6 months',
      value: { start: lastSixMonths, end: tomorrow, id: 'last6months' },
    },
    {
      label: 'Last 12 months',
      value: { start: lastTwelveMonths, end: tomorrow, id: 'last12months' },
    },
  ]

  const handleClientSelect = (userId) => {
    if (userId) {
      const user = clients.find(({ id }) => id === userId)
      if (user) {
        dispatch(
          setUser({
            ...user,
            userId: user.id,
          })
        )
        dispatch(setFilterUserId(userId))
      }
    } else {
      // unselect case
      dispatch(
        setUser({
          user: {},
          userId: undefined,
        })
      )
      dispatch(setFilterUserId())
    }
    dispatch(saveCurrentFilter())
  }
  const handleAssessmentSelect = (assessmentName) => {
    if (assessmentName) {
      dispatch(setAssessmentName(assessmentName))
      dispatch(setAssessmentFilterProductEventId(assessmentName))
    } else {
      dispatch(setAssessmentName())
      dispatch(setAssessmentFilterProductEventId())
    }
    dispatch(saveCurrentFilter())
  }
  const handleStatusSelect = (status) => {
    if (status) {
      dispatch(setAssessmentFilterStatus(status))
    } else {
      // unselect case
      dispatch(setAssessmentFilterStatus())
    }
    dispatch(saveCurrentFilter())
  }
  const handleCreatedAt = (dateRange: OptionProps['value']) => {
    if (
      typeof dateRange === 'object' &&
      dateRange !== null &&
      'start' in dateRange &&
      'end' in dateRange
    ) {
      dispatch(
        setAssessmentFilterCreatedAt({
          start: dateRange?.start,
          end: dateRange?.end,
          id: dateRange?.id,
        })
      )
    } else {
      dispatch(setAssessmentFilterCreatedAt({}))
    }
    dispatch(saveCurrentFilter())
  }
  const handleUpdatedAt = (dateRange: OptionProps['value']) => {
    if (
      typeof dateRange === 'object' &&
      dateRange !== null &&
      'start' in dateRange &&
      'end' in dateRange
    ) {
      dispatch(
        setAssessmentFilterUpdatedAt({
          start: dateRange?.start,
          end: dateRange?.end,
          id: dateRange?.id,
        })
      )
    } else {
      dispatch(setAssessmentFilterUpdatedAt({}))
    }
    dispatch(saveCurrentFilter())
  }

  return (
    <FilterTable>
      <FilterButton
        defaultId={userId}
        label="Client"
        options={clientOptions}
        onSelect={handleClientSelect}
        filterOptions={clientFilterOptions}
      />
      <FilterButton
        label="Assessment"
        defaultId={filterProductEventId}
        options={assessmentNames}
        onSelect={handleAssessmentSelect}
      />
      <FilterButton label="Status" options={statusOptions} onSelect={handleStatusSelect} />
      <FilterButton label="Created Date" options={dateOptions} onSelect={handleCreatedAt} />
      <FilterButton label="Last Updated" options={dateOptions} onSelect={handleUpdatedAt} />
    </FilterTable>
  )
}
