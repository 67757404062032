import React from 'react'
import ClientConnectionIcon from './client_connections_icon.svg'
import { Avatar } from '@mui/material'

export default function ClientConnectionsAvatar() {
  return (
    <Avatar
      src={ClientConnectionIcon}
      alt="client-connection-icon"
      sx={{
        width: 60,
        height: 60,
        backgroundColor: 'white',
        boxSizing: 'content-box',
      }}
    />
  )
}
