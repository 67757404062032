import React, { useContext } from 'react'
import { Grid, Stack, Typography, Card, Switch, Alert, Button, Link } from '@mui/material'

import { ProviderProfileContext } from '../MyProfile'

import { useSelector } from 'react-redux'
import ClientConnectionsAvatar from 'components/avatar/ClientConnectionAvatar'
import { alpha, styled } from '@mui/material/styles'

const PinkSwitch = styled(Switch)(({ theme }) => ({
  '& .MuiSwitch-switchBase.Mui-checked': {
    color: theme.palette.unyteSecondary.main,
    '&:hover': {
      backgroundColor: alpha(theme.palette.unyteSecondary.main, theme.palette.action.hoverOpacity),
    },
  },
  '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
    backgroundColor: theme.palette.unyteSecondary.main,
  },
}))

export default function ClientConnectionsBanner({
  providerProfileInfo,
  productPreferences,
  setOpenUnavailableModal,
  handleFirstClick,
  setOpenDialog,
}) {
  const { hasSspProducts, hasFocusProducts, hasRrpProducts } = useSelector((state) => state.ff)
  const { form, setWizardState, setOpenWizard, handleSave, handleCheckBox } = useContext(
    ProviderProfileContext
  )

  const isFirstClicked = !!providerProfileInfo?.gettingStartedClickedAt
  const isSspCertificationCompleted = !!productPreferences?.sspCertification?.completedAt
  const isFocusCertificationCompleted = !!productPreferences?.focusCertification?.completedAt

  const isClientConnectionsDisabled = !(
    (isSspCertificationCompleted || isFocusCertificationCompleted) &&
    (hasSspProducts || hasFocusProducts || hasRrpProducts)
  )

  const onSwitch = (event) => {
    // if you don't have subscription, show modal
    if (!hasSspProducts && !hasFocusProducts) {
      setOpenUnavailableModal(true)
      return
    }

    // if we are unselecting this, we want to save the results
    if (!event?.target?.checked) {
      handleCheckBox('openToNewClients')(event)
      handleSave({ openToNewClients: false })
    } else {
      setWizardState()
      setOpenWizard(event?.target?.checked)
    }
  }

  const onFirstClick = () => {
    setOpenWizard(true)
    setWizardState()
    handleFirstClick()
  }

  // old wizard logic
  const onLearnMore = () => {
    setOpenDialog(true)
  }

  return (
    <Grid item alignItems="stretch" className="w-full">
      <Card
        sx={{
          // card css
          border: '1px solid rgba(0, 0, 0, 0.12)',
          borderRadius: '4px',
          boxShadow: 'none',
          // background css
          backgroundColor: '#edf6f2',
        }}
      >
        <Stack direction="row" justifyContent="space-between" alignItems="center" p={2} spacing={2}>
          <Stack direction="row" alignItems="center" spacing={2}>
            <ClientConnectionsAvatar />

            <Stack direction="column">
              <Typography variant="h5" sx={{ fontWeight: 500 }}>
                Unyte Client Connections
              </Typography>

              <Stack direction="row" spacing={1} alignItems="center">
                <Typography variant="body1">
                  Connecting Clients with Valued Providers{' '}
                  <Link
                    sx={{ color: '#ce0093', textDecoration: 'underline', cursor: 'pointer' }}
                    onClick={onLearnMore}
                  >
                    Learn More
                  </Link>
                </Typography>
              </Stack>
            </Stack>
          </Stack>

          {!isClientConnectionsDisabled && (
            <Stack direction="column" alignItems="center">
              <>
                {!isFirstClicked && (
                  <Stack alignItems="center" direction="row" spacing={2}>
                    <Typography fontSize="1.1rem">Connect me with new clients!</Typography>
                    <Button
                      variant="contained"
                      onClick={onFirstClick}
                      color="primary"
                      className="p-2 w-28"
                    >
                      Get Started
                    </Button>
                  </Stack>
                )}
                {isFirstClicked && (
                  <Stack direction="row" alignItems="center">
                    <Typography fontSize="1.1rem" alignItems="center">
                      {form.openToNewClients
                        ? 'We are looking for potential new clients for you!'
                        : 'Connect me to new potential clients'}
                    </Typography>
                    <Stack direction="row" alignItems="center" mx={3}>
                      <Typography>OFF</Typography>
                      <PinkSwitch
                        checked={form.openToNewClients}
                        onChange={onSwitch}
                        disabled={isClientConnectionsDisabled}
                      />
                      <Typography>ON</Typography>
                    </Stack>
                  </Stack>
                )}
              </>
            </Stack>
          )}
          {isClientConnectionsDisabled && (
            <Alert
              variant="outlined"
              severity="warning"
              sx={{ backgroundColor: '#FEFAED', width: '520px' }}
            >
              Sorry! Client Connections requires you to be a certified provider with an active
              subscription.
            </Alert>
          )}
        </Stack>
      </Card>
    </Grid>
  )
}
