import React from 'react'
import makeStyles from '@mui/styles/makeStyles'
import { Grid, Card, CardHeader, CardContent, Box, Typography } from 'components'
import { useSelector } from 'react-redux'
import { get } from 'lodash'
import EMAILS from 'utils/constants/emails'
import ROLES from 'utils/constants/roles'

const useStyles = makeStyles((theme) => ({
  card: {
    width: '100%',
    backgroundColor: '#edf2f7',
  },
  link: {
    color: '#616161',
  },
  circle: {
    width: 20,
    height: 20,
    borderRadius: '50%',
    backgroundColor: 'var(--red)',
    marginTop: 3,
    flexShrink: 0,
  },
}))

/**
 * TODO: UW-1375: Update SSP related copy
 * https://unytehealth.atlassian.net/browse/UW-1375
 */
export default function NoProducts({ gridProps }) {
  const classes = useStyles()

  const currentUserRoles = useSelector((state) => get(state, 'auth.user.roles', []))
  const isClient = currentUserRoles.some((role) => ROLES.CLIENT_ROLES.includes(role))

  return (
    <Grid item {...gridProps}>
      <Card
        data-test="dashboard-card-no-products"
        className={`${classes.card} h-full p-4`}
        elevation={0}
      >
        <CardHeader title={'No Program Access'} titleTypographyProps={{ variant: 'h5' }} />
        <CardContent>
          <Box display="flex">
            {!isClient && (
              <div>
                <Typography variant="body2">
                  You currently don't have access to any available programs.
                </Typography>
                <Typography variant="body2">You may not have an active subscription.</Typography>
                <Typography variant="body2" className="pt-5" gutterBottom>
                  Please contact{' '}
                  <a className="text-link" href={`mailto:${EMAILS.supportEmail}`}>
                    Unyte-iLs support
                  </a>{' '}
                  for more details.
                </Typography>
              </div>
            )}

            {isClient && (
              <div>
                <Typography variant="body2">
                  You currently don't have access to any available programs.
                </Typography>
                <Typography variant="body2" className="py-3">
                  Please contact your Provider for more details.
                </Typography>
              </div>
            )}
          </Box>
        </CardContent>
      </Card>
    </Grid>
  )
}
