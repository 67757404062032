export const SSP_CERTIFICATION_SKUS = ['sspCertification']
export const VOICE_PRO_CERTIFICATION_SKUS = ['voiceProCertification']
export const FOCUS_CERTIFICATION_SKUS = ['focusCertification']

export const FOCUS_SYSTEM_SKUS = ['FOCUS-STRT']
export const FOCUS_TEMPORARY = [
  'focusTemporary',
  'focusUnyteEmployee',
  'focusComplimentary',
  'focusCustom',
  'focusAustralia',
]

export const FOCUS_SUBSCRIPTIONS_SKUS = [
  'focusYearToYear_Yearly',
  'focusMonthToMonth_Yearly_Bundle',
  'focusMonthToMonth_Yearly',
]
export const ALL_FOCUS_SUBSCRIPTIONS_SKUS = [...FOCUS_TEMPORARY, ...FOCUS_SUBSCRIPTIONS_SKUS]

export const PROMO_SKUS = ['SSPPromoQ22024', 'ILSPromoQ22024', '2024ActionTraumaVirtual']

// Temporary placeholder for sspTemporary related SKUS
// https://unytehealth.atlassian.net/browse/UW-3144
export const TEMPORARY_SSP_SUBSCRIPTION_SKUS = [
  'sspUnyteEmployee',
  'sspAustralia',
  'sspCheque',
  'sspWireTransfer',
  'sspPayPal',
  'sspComplimentary',
  'sspBrainHarmony',
  'sspCustom',
]

export const SSP_MONTHLY_SUBSCRIPTION_SKUS = [
  'sspMonthToMonth_3Monthly',
  'sspMonthToMonth_3Monthly_Bundle',
  'sspMonthToMonth_Yearly',
  'sspMonthToMonth_Yearly_Bundle',
  'sspMonthToMonth_Monthly', // purchased via Stripe dashboard only
]
export const SSP_SUBSCRIPTION_SKUS = [...SSP_MONTHLY_SUBSCRIPTION_SKUS, 'sspYearToYear_Yearly']
export const ALL_SSP_SUBSCRIPTION_SKUS = [
  ...TEMPORARY_SSP_SUBSCRIPTION_SKUS,
  ...SSP_SUBSCRIPTION_SKUS,
]

// ALL [category] SKUS
export const ALL_CERTIFICATION_SKUS = [
  ...SSP_CERTIFICATION_SKUS,
  ...FOCUS_CERTIFICATION_SKUS,
  ...VOICE_PRO_CERTIFICATION_SKUS,
]
export const ALL_FOCUS_SKUS = [
  ...FOCUS_CERTIFICATION_SKUS,
  ...FOCUS_SYSTEM_SKUS,
  ...ALL_FOCUS_SUBSCRIPTIONS_SKUS,
]
export const ALL_VOICE_PRO_SKUS = [...VOICE_PRO_CERTIFICATION_SKUS]
export const ALL_SSP_SKUS = [...SSP_CERTIFICATION_SKUS, ...SSP_SUBSCRIPTION_SKUS]
export const ALL_VALID_SKUS = [
  ...PROMO_SKUS,
  ...ALL_FOCUS_SKUS,
  ...ALL_VOICE_PRO_SKUS,
  ...SSP_CERTIFICATION_SKUS,
  ...SSP_SUBSCRIPTION_SKUS,
]

// DISCOUNTS
export const BUNDLE_PROMO_CODES = [
  {
    couponName: 'SSP To Be Loved Discount',
    requiredSkus: ['sspCertification', 'SSPPromoQ22024'],
    applyToSku: 'sspCertification',
    discountAmount: 125,
    key: 'sspToBeLovedBundleDiscount',
  },
  {
    couponName: 'ILS To Be Loved Discount',
    requiredSkus: ['focusCertification', 'ILSPromoQ22024'],
    applyToSku: 'focusCertification',
    discountAmount: 75,
    key: 'ilsToBeLovedBundleDiscount',
  },
  {
    couponName: 'SSP Bundle Discount',
    requiredSkus: ['sspCertification', 'sspYearToYear_Yearly'],
    discountAmount: 100,
    key: 'sspYearlyBundleDiscount',
  },
  {
    couponName: 'SSP Bundle Discount',
    requiredSkus: ['sspCertification', 'sspMonthToMonth_Yearly_Bundle'],
    discountAmount: 100,
    key: 'sspYearlyBundleDiscount',
  },
  {
    couponName: 'Training Bundle Discount',
    requiredSkus: ['sspCertification', 'focusCertification'],
    discountAmount: 99,
    key: 'trainingBundleDiscount',
  },
  {
    couponName: 'FREE ILS Starter Pack',
    requiredSkus: ['FOCUS-STRT', 'focusYearToYear_Yearly'],
    discountAmount: 299,
    key: 'focusBundleDiscount',
  },
  {
    couponName: 'FREE ILS Starter Pack',
    requiredSkus: ['FOCUS-STRT', 'focusMonthToMonth_Yearly_Bundle'],
    discountAmount: 299,
    key: 'focusBundleDiscount',
  },
  {
    couponName: 'ILS Bundle Discount',
    requiredSkus: ['focusCertification', 'focusYearToYear_Yearly'],
    discountAmount: 50,
    key: 'focusBundleDiscount',
  },
  {
    couponName: 'ILS Bundle Discount',
    requiredSkus: ['focusCertification', 'focusMonthToMonth_Yearly'],
    discountAmount: 50,
    key: 'focusBundleDiscount',
  },
  {
    couponName: 'ILS Bundle Discount',
    requiredSkus: ['focusCertification', 'focusMonthToMonth_Yearly_Bundle'],
    discountAmount: 50,
    key: 'focusBundleDiscount',
  },
]

export const INTERNAL_PURCHASE_PROMOS = [
  {
    couponName: 'SSP Bundle Discount',
    requiredSkus: ['sspCertification', 'sspYearToYear_Yearly'],
    applyToSku: 'sspCertification',
    discountAmount: 100,
    key: 'sspYearlyBundleDiscount_Internal',
  },
  {
    couponName: 'SSP Bundle Discount',
    requiredSkus: ['sspCertification', 'sspMonthToMonth_Yearly_Bundle'],
    applyToSku: 'sspCertification',
    discountAmount: 100,
    key: 'sspYearlyBundleDiscount_Internal',
  },
  {
    couponName: 'ILS subscriber discount',
    requiredSkus: ['sspYearToYear_Yearly'],
    requiredAnyExistingSkus: ['focusYearToYear_Yearly', 'focusMonthToMonth_Yearly'],
    applyToSku: 'sspYearToYear_Yearly',
    discountAmount: 500,
    key: 'sspExistingFocusDiscount_Internal',
  },
  {
    couponName: 'ILS subscriber discount',
    requiredSkus: ['sspMonthToMonth_Yearly'],
    requiredAnyExistingSkus: ['focusYearToYear_Yearly', 'focusMonthToMonth_Yearly'],
    shouldApplyToSubscription: true,
    applyToSku: 'sspMonthToMonth_Yearly',
    discountAmount: 42,
    key: 'sspExistingFocusDiscount_Internal',
  },
  {
    couponName: 'ILS subscriber discount',
    requiredSkus: ['sspMonthToMonth_Yearly_Bundle'],
    requiredAnyExistingSkus: ['focusYearToYear_Yearly', 'focusMonthToMonth_Yearly'],
    shouldApplyToSubscription: true,
    applyToSku: 'sspMonthToMonth_Yearly_Bundle',
    shouldExcludeDiscountFromCartItems: true,
    discountAmount: 42,
    key: 'sspExistingFocusDiscount_Internal',
  },
  {
    couponName: 'ILS Bundle Discount',
    requiredSkus: ['focusCertification', 'focusYearToYear_Yearly'],
    applyToSku: 'focusCertification',
    discountAmount: 50,
    key: 'focusYearlyBundleDiscount_Internal',
  },
  {
    couponName: 'ILS Bundle Discount',
    requiredSkus: ['focusCertification', 'focusMonthToMonth_Yearly_Bundle'],
    applyToSku: 'focusCertification',
    discountAmount: 50,
    key: 'focusYearlyBundleDiscount_Internal',
  },
  {
    couponName: 'SSP subscriber discount',
    requiredSkus: ['focusYearToYear_Yearly'],
    requiredAnyExistingSkus: ['sspYearToYear_Yearly', 'sspMonthToMonth_Yearly'],
    applyToSku: 'focusYearToYear_Yearly',
    discountAmount: 500,
    key: 'focusExistingFocusDiscount_Internal',
  },
  {
    couponName: 'SSP subscriber discount',
    requiredSkus: ['focusMonthToMonth_Yearly'],
    requiredAnyExistingSkus: ['sspYearToYear_Yearly', 'sspMonthToMonth_Yearly'],
    shouldApplyToSubscription: true,
    applyToSku: 'focusMonthToMonth_Yearly',
    discountAmount: 42,
    key: 'focusExistingFocusDiscount_Internal',
  },
  {
    couponName: 'SSP subscriber discount',
    requiredSkus: ['focusMonthToMonth_Yearly_Bundle'],
    requiredAnyExistingSkus: ['sspYearToYear_Yearly', 'sspMonthToMonth_Yearly'],
    shouldApplyToSubscription: true,
    applyToSku: 'focusMonthToMonth_Yearly_Bundle',
    shouldExcludeDiscountFromCartItems: true,
    discountAmount: 42,
    key: 'focusExistingFocusDiscount_Internal',
  },
  {
    couponName: 'FREE ILS Starter Pack',
    requiredSkus: ['focusMonthToMonth_Yearly_Bundle', 'FOCUS-STRT'],
    applyToSku: 'FOCUS-STRT',
    discountAmount: 299,
    key: 'focusBundleDiscount',
  },
  {
    couponName: 'FREE ILS Starter Pack',
    requiredSkus: ['focusMonthToMonth_Yearly', 'FOCUS-STRT'],
    applyToSku: 'FOCUS-STRT',
    discountAmount: 299,
    key: 'focusBundleDiscount',
  },
  {
    couponName: 'FREE ILS Starter Pack',
    requiredSkus: ['focusYearToYear_Yearly', 'FOCUS-STRT'],
    applyToSku: 'FOCUS-STRT',
    discountAmount: 299,
    key: 'focusBundleDiscount',
  },
]

/**
 * items here will be added to cart
 *   eg `{ sspUpfrontBundlePromoFeb2021: ['polyvagalFlipChart', 'psychotherapyNetwork'] }`
 */
export const BUNDLE_PROMO_SKUS = {
  SSPPromoQ22024: ['ToBeLovedBookCredit', 'FrankAndersonWebinar', 'FreeTherapistListing'],
  ILSPromoQ22024: ['ToBeLovedBookCredit', 'FrankAndersonWebinar', 'MedBridgeDiscount'],
  '2024ActionTraumaVirtual': ['2024ActionTraumaVirtual'],
}

export const INVALID_SKUS_COMBO = {}

/**
 * sku: stripe name
 * type: differentiate between certification and subscription
 *   - this field does not determine the frequency when a user is charged
 *     - an annual bundle is charged upfront and is a subscription
 */

const PROMO_PRODUCTS = {
  /*
  Keeping below as an example of a removable line item, and adding shipping prompt
  SSPPromoQ42021: {
    sku: 'SSPPromoQ42021',
    description: '', // description text goes under cartTitle for Promos. Defaults to "One Time Payment"
    ...
  },
  SSPPromoQ42021Bundle: {
    sku: 'SSPPromoQ42021Bundle',
    isRemovable: true,
    skuToRemove: 'SSPPromoQ42021',
    isPromoWithShipping: true, // Needed to show shipping screen
    weight: 0, // Needed to avoid shipstation error
    ...
  },
  */
  '2024ActionTraumaVirtual': {
    sku: '2024ActionTraumaVirtual',
    type: 'promo',
    price: 0,
    immediateChargePrice: 0,
    description:
      'Virtual ticket to Action Trauma Belfast on June 15-18 + 1 year Action Trauma Network membership.',
    frequency: 'once',
    cartTitle: 'Free 2024 Action Trauma Summit Virtual Ticket',
    gtmVariant: 'Physical',
    productCategories: ['ssp', 'focus'],
    accessoryCategories: [],
    weight: 0,
  },
  SSPPromoQ22024: {
    sku: 'SSPPromoQ22024',
    type: 'promo',
    price: 0,
    immediateChargePrice: 0,
    description: '',
    frequency: 'once',
    gtmCategory: 'Physical',
    cartTitle: 'SSP Promo: To Be Loved',
    gtmVariant: 'bundle',
    productCategories: ['ssp'],
    accessoryCategories: [],
    weight: 0,
  },
  ILSPromoQ22024: {
    sku: 'ILSPromoQ22024',
    type: 'promo',
    price: 0,
    immediateChargePrice: 0,
    description: '',
    frequency: 'once',
    gtmCategory: 'Physical',
    cartTitle: 'ILS Promo: To Be Loved',
    gtmVariant: 'bundle',
    productCategories: ['focus'],
    accessoryCategories: [],
    weight: 0,
  },
  ToBeLovedBookCredit: {
    sku: 'ToBeLovedBookCredit',
    type: 'promo',
    price: 0,
    immediateChargePrice: 0,
    description:
      "A free copy of Frank Anderson, MD's new book To be Loved: A Story of Truth, Hope, and Healing",
    cartTitle: 'To Be Loved Book Credit',
    gtmVariant: 'bundle',
    productCategories: ['ssp', 'focus'],
    accessoryCategories: [],
    weight: 0,
  },
  FrankAndersonWebinar: {
    sku: 'FrankAndersonWebinar',
    type: 'promo',
    price: 0,
    immediateChargePrice: 0,
    description:
      'Invitation to a live, exclusive book discussion webinar with Frank Anderson on July 18, 2024 just for new providers and purchasers of this offer',
    cartTitle: 'Live Book Discussion Webinar',
    gtmVariant: 'bundle',
    productCategories: ['ssp', 'focus'],
    accessoryCategories: [],
    weight: 0,
  },
  FreeTherapistListing: {
    sku: 'FreeTherapistListing',
    type: 'promo',
    price: 0,
    immediateChargePrice: 0,
    description: ' ',
    cartTitle: 'Free therapist.com listing for 6 months',
    gtmVariant: 'bundle',
    productCategories: ['ssp'],
    accessoryCategories: [],
    weight: 0,
  },
  MedBridgeDiscount: {
    sku: 'MedBridgeDiscount',
    type: 'promo',
    price: 0,
    immediateChargePrice: 0,
    description: 'Earn CEUs and advance your career',
    cartTitle: '$150 off of MedBridge Education or Premium Annual Subscriptions',
    gtmVariant: 'bundle',
    productCategories: ['focus'],
    accessoryCategories: [],
    weight: 0,
  },
}

const SSP_PRODUCTS = {
  sspCertification: {
    sku: 'sspCertification',
    type: 'digitalProduct',
    price: 349,
    immediateChargePrice: 349,
    description: 'SSP Training Basic',
    frequency: 'once',
    cartTitle: 'SSP Certification',
    gtmCategory: 'Training',
    productCategories: ['ssp'],
    accessoryCategories: [],
    weight: 0,
  },
  sspMonthToMonth_3Monthly: {
    sku: 'sspMonthToMonth_3Monthly',
    type: 'subscription',
    price: 169,
    immediateChargePrice: 169,
    description: 'Paid monthly, 3 month term, auto renewal',
    frequency: 'month',
    cartTitle: 'SSP Monthly, 3 Month Subscription',
    gtmCategory: 'SSP',
    gtmVariant: 'monthly',
    productCategories: ['ssp'],
    accessoryCategories: ['ssp'],
    weight: 0,
  },
  sspMonthToMonth_3Monthly_Bundle: {
    sku: 'sspMonthToMonth_3Monthly_Bundle',
    type: 'subscription',
    price: 169,
    immediateChargePrice: 0,
    description: 'Paid monthly, 3 month term, auto renewal',
    frequency: 'month',
    cartTitle: 'SSP Monthly, 3 Month Subscription',
    gtmCategory: 'SSP',
    gtmVariant: 'monthly',
    productCategories: ['ssp'],
    accessoryCategories: ['ssp'],
    weight: 0,
    showDisclaimer: true,
  },
  sspMonthToMonth_Yearly: {
    sku: 'sspMonthToMonth_Yearly',
    type: 'subscription',
    price: 139,
    immediateChargePrice: 139,
    description: 'Paid monthly, 1 year term, auto renewal',
    frequency: 'month',
    cartTitle: 'SSP Monthly, Annual Subscription',
    gtmCategory: 'SSP',
    gtmVariant: 'monthly',
    productCategories: ['ssp'],
    accessoryCategories: ['ssp'],
    weight: 0,
  },
  sspMonthToMonth_Yearly_Bundle: {
    sku: 'sspMonthToMonth_Yearly_Bundle',
    type: 'subscription',
    price: 139,
    immediateChargePrice: 0,
    description: 'Paid monthly, 1 year term, auto renewal',
    frequency: 'month',
    cartTitle: 'SSP Monthly, Annual Subscription',
    gtmCategory: 'SSP',
    gtmVariant: 'monthly',
    productCategories: ['ssp'],
    accessoryCategories: ['ssp'],
    weight: 0,
    showDisclaimer: true,
  },
  sspYearToYear_Yearly: {
    sku: 'sspYearToYear_Yearly',
    type: 'subscription',
    price: 1499,
    immediateChargePrice: 1499,
    description: '1 year term, auto renewal',
    frequency: 'year',
    gtmCategory: 'SSP',
    cartTitle: 'SSP Annual Subscription',
    gtmVariant: 'yearly',
    productCategories: ['ssp'],
    accessoryCategories: ['ssp'],
    weight: 0,
  },
}

const FOCUS_PRODUCTS = {
  focusCertification: {
    sku: 'focusCertification',
    type: 'digitalProduct',
    price: 199,
    immediateChargePrice: 199,
    description: 'Integrated Listening System Training Certification',
    frequency: 'once',
    gtmCategory: 'Training',
    cartTitle: 'ILS Training & Certification',
    productCategories: ['focus'],
    accessoryCategories: [],
    weight: 0,
  },
  focusMonthToMonth_Yearly_Bundle: {
    sku: 'focusMonthToMonth_Yearly_Bundle',
    type: 'subscription',
    price: 139,
    immediateChargePrice: 0,
    description: 'Paid monthly, 1 year term, auto renewal',
    frequency: 'month',
    cartTitle: 'ILS Subscription',
    gtmCategory: 'Focus',
    gtmVariant: 'monthly',
    productCategories: ['focus'],
    accessoryCategories: ['focusAccessory'],
    weight: 0,
  },
  focusMonthToMonth_Yearly: {
    sku: 'focusMonthToMonth_Yearly',
    type: 'subscription',
    price: 139,
    immediateChargePrice: 139,
    description: 'Paid monthly, 1 year term, auto renewal',
    frequency: 'month',
    cartTitle: 'ILS Subscription',
    gtmCategory: 'Focus',
    gtmVariant: 'monthly',
    productCategories: ['focus'],
    accessoryCategories: ['focusAccessory'],
    weight: 0,
  },
  focusYearToYear_Yearly: {
    sku: 'focusYearToYear_Yearly',
    type: 'subscription',
    price: 1499,
    immediateChargePrice: 1499,
    description: '1 year term, auto renewal',
    frequency: 'year',
    gtmCategory: 'Focus',
    cartTitle: 'ILS Subscription',
    gtmVariant: 'bundle',
    productCategories: ['focus'],
    accessoryCategories: ['focusAccessory'],
    weight: 0,
  },
  'FOCUS-STRT': {
    sku: 'FOCUS-STRT',
    type: 'physicalProduct',
    price: 299,
    immediateChargePrice: 299,
    description: '(Includes Hardware Kit and Integration Kit)',
    frequency: 'once',
    gtmCategory: 'Physical',
    cartTitle: 'ILS Starter Pack',
    gtmVariant: 'bundle',
    productCategories: ['focus'],
    accessoryCategories: ['focusAccessory'],
    weight: 11,
  },
}

const VOICE_PRO_PRODUCTS = {
  voiceProCertification: {
    sku: 'voiceProCertification',
    type: 'digitalProduct',
    price: 249,
    immediateChargePrice: 249,
    description: 'VoicePro Training',
    frequency: 'once',
    gtmCategory: 'Training',
    cartTitle: 'VoicePro Training',
    productCategories: ['voice_pro'],
    accessoryCategories: [],
    weight: 0,
  },
}

export const PRODUCTS = {
  ...PROMO_PRODUCTS,
  ...SSP_PRODUCTS,
  ...FOCUS_PRODUCTS,
  ...VOICE_PRO_PRODUCTS,
}

// items here are for internal purchase
export const ALL_BUNDLES = {
  // ssp
  SSP_PURCHASE_ANNUAL: {
    id: 'SSP_PURCHASE_ANNUAL',
    title: 'Annual SSP Subscription',
    header: 'Billed Annually ($1499)',
    oneTimeCharge: 0,
    subscriptionCharge: PRODUCTS.sspYearToYear_Yearly.price,
    subscriptionFrequency: 'year',
    productDescription: PRODUCTS.sspYearToYear_Yearly.description,
    products: [PRODUCTS.sspYearToYear_Yearly],
    includedTitle: 'Subscription includes:',
  },
  SSP_PURCHASE_COMMITTED_1_YEAR: {
    id: 'SSP_PURCHASE_COMMITTED_1_YEAR',
    title: 'Annual SSP Subscription',
    header: 'Billed Monthly',
    oneTimeCharge: 0,
    oneTimeChargeCaption: null,
    subscriptionCharge: PRODUCTS.sspMonthToMonth_Yearly.price,
    productDescription: PRODUCTS.sspMonthToMonth_Yearly.description,
    products: [PRODUCTS.sspMonthToMonth_Yearly],
    includedTitle: 'Subscription includes:',
  },
  SSP_PURCHASE_COMMITTED_3_MONTH: {
    id: 'SSP_PURCHASE_COMMITTED_3_MONTH',
    title: '3 Month SSP Subscription',
    header: 'Billed Monthly',
    oneTimeCharge: 0,
    oneTimeChargeCaption: 'One-time cost',
    subscriptionCharge: PRODUCTS.sspMonthToMonth_3Monthly.price,
    productDescription: PRODUCTS.sspMonthToMonth_3Monthly.description,
    products: [PRODUCTS.sspMonthToMonth_3Monthly],
    includedTitle: 'Subscription includes:',
  },
  // certifications
  SSP_NEW_CERTIFICATION: {
    id: 'SSP_NEW_CERTIFICATION',
    title: 'SSP Certification & Remote Training',
    oneTimeCharge: PRODUCTS.sspCertification.price,
    oneTimeChargeCaption: 'One-time cost',
    subscriptionCharge: 0,
    products: [PRODUCTS.sspCertification],
    includedTitle: 'Training includes:',
  },
  SSP_NEW_COMMITTED_1_YEAR: {
    id: 'SSP_NEW_COMMITTED_1_YEAR',
    title: 'SSP Certification + SSP Annual Subscription',
    oneTimeCharge: PRODUCTS.sspCertification.price,
    oneTimeChargeCaption: 'One-time cost',
    subscriptionCharge: PRODUCTS.sspMonthToMonth_Yearly_Bundle.price,
    subscriptionCaption: 'Subscription starts after you’ve completed your SSP certification',
    products: [PRODUCTS.sspCertification, PRODUCTS.sspMonthToMonth_Yearly_Bundle],
    includedTitle: 'Subscription includes:',
  },
  SSP_NEW_COMMITTED_3_MONTH: {
    id: 'SSP_NEW_COMMITTED_3_MONTH',
    title: 'SSP Certification + SSP 3 Month Subscription',
    oneTimeCharge: PRODUCTS.sspCertification.price,
    oneTimeChargeCaption: 'One-time payment',
    subscriptionCharge: PRODUCTS.sspMonthToMonth_3Monthly_Bundle.price,
    subscriptionCaption: 'Subscription starts after you’ve completed your SSP certification',
    products: [PRODUCTS.sspCertification, PRODUCTS.sspMonthToMonth_3Monthly_Bundle],
    includedTitle: 'Subscription includes',
  },
  SSP_NEW_ANNUAL: {
    id: 'SSP_NEW_ANNUAL',
    title: 'SSP Annual Introductory Bundle',
    oneTimeCharge:
      PRODUCTS.sspYearToYear_Yearly.price +
      PRODUCTS.sspCertification.price -
      BUNDLE_PROMO_CODES.find((bundle) => bundle.key === 'trainingBundleDiscount').discountAmount,
    oneTimeChargeCaption: 'Includes SSP Certification training!',
    subscriptionCharge: 0,
    products: [PRODUCTS.sspCertification, PRODUCTS.sspYearToYear_Yearly],
    text1: 'Paid annually',
    includedTitle: 'Subscription includes:',
  },
  // focus
  FOCUS_CERTIFICATION: {
    id: 'FOCUS_CERTIFICATION',
    title: 'Integrated Listening System Training Certification',
    oneTimeCharge: PRODUCTS.focusCertification.price,
    oneTimeChargeCaption: 'One-time payment',
    subscriptionCharge: 0,
    products: [PRODUCTS.focusCertification],
    includedTitle: 'Certification Includes',
  },
  // new storefront does not use any other info
  FOCUS_COMMITTED_1_YEAR: {
    id: 'FOCUS_NEW_COMMITTED_1_YEAR',
    oneTimeCharge: PRODUCTS.focusCertification.price,
    subscriptionCharge: PRODUCTS.focusMonthToMonth_Yearly_Bundle.price,
    products: [
      PRODUCTS.focusCertification,
      PRODUCTS.focusMonthToMonth_Yearly_Bundle,
      PRODUCTS.focusHardwareKit,
    ],
  },
}
