/**
 * This component shows detailed information containing all questions asked
 * response is optimized for smallest desktop screen size: 1280x720
 */
import React from 'react'
import {
  Paper as MuiPaper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from 'components'
import { get } from 'lodash'
import { isEmpty } from 'utils/isEmpty'
import moment from 'moment'
import { parseMetadata } from './utils/parse-metadata'

const Paper = (props) => {
  return <MuiPaper variant="outlined" {...props} />
}

// need to put state logic in parent component due to infinite loop from recharts
export default function ResponseTable({ subScores, assessment, completedAssessments, metadata }) {
  let questionsMap = {}
  let newQuestions = {}
  let rateValues = {}
  let finalAnswers = {}
  // this currently does not work with subscales..
  let newRateValues = {}

  const filteredCompletedData = completedAssessments

  // transform questions into object
  get(metadata, 'survey.pages', []).forEach(({ elements }) =>
    elements.forEach(({ name, title }) => {
      if (title) {
        questionsMap[name] = title
        newQuestions[title] = 0
      }
    })
  )

  // transform rates into object depending if survey has subscales
  const { subScales, isAIS, isCATS, catsScoring } = parseMetadata(metadata)

  if (isEmpty(subScales) && !isAIS) {
    get(metadata, 'survey.pages[0].elements', []).forEach((element) => {
      element?.rateValues?.forEach(({ text, value }) => {
        rateValues[value] = text
        finalAnswers[text] = { ...newQuestions }
      })
    })
  } else {
    get(metadata, 'survey.pages[1].elements', []).forEach((element) => {
      element?.rateValues?.forEach(({ text, value }) => {
        rateValues[value] = text
        finalAnswers[text] = { ...newQuestions }
        // this is for AIS
        if (isAIS) {
          newRateValues[element.name] = { ...newRateValues[element.name], [value]: text }
        }
      })
    })
  }

  // add answers to final
  filteredCompletedData.forEach(({ data: { answers } }) => {
    if (answers) {
      Object.entries(answers).forEach(([question, answer]) => {
        if (typeof answer === 'number') {
          const expandedText = rateValues[answer]
          const expandedQuestion = questionsMap[question]
          if (finalAnswers[expandedText]) {
            finalAnswers[expandedText] = {
              ...finalAnswers[expandedText],
              [expandedQuestion]: finalAnswers[expandedText][expandedQuestion] + 1,
            }
          } else {
            finalAnswers[expandedText] = { ...finalAnswers[expandedText], [expandedQuestion]: 1 }
          }
        }
      })
    }
  })

  return (
    // we need to use a custom component for paper
    <Stack spacing={1}>
      <Typography variant="h5" px={3} pb={2} pt={5}>
        Response
      </Typography>
      <TableContainer
        component={Paper}
        sx={{
          // maxWidth: '100vw',
          overflowX: 'auto',
          marginLeft: '1.5rem !important',
          marginRight: '1.5rem !important',
        }}
      >
        <Table
          aria-label="response table"
          stickyHeader
          style={{ minWidth: '600px', backgroundColor: '#fafafa' }}
        >
          <TableHead>
            <TableRow>
              <TableCell
                style={{
                  top: 0,
                  left: 0,
                  zIndex: 2,
                  position: 'sticky',
                  minWidth: '600px',
                }}
              >
                Items
              </TableCell>
              {filteredCompletedData.map(({ updatedAt }) => (
                <TableCell style={{ minWidth: '168px', zIndex: 0 }}>
                  {moment(updatedAt).format('MMMM DD YYYY')}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {Object.entries(questionsMap).map(([key, question], index) => (
              <TableRow
                key={key}
                style={{
                  backgroundColor: 'var(--white)',
                }}
              >
                <TableCell
                  component="th"
                  style={{
                    top: 0,
                    left: 0,
                    zIndex: 2,
                    position: 'sticky',
                    backgroundColor: 'var(--white)',
                  }}
                >
                  {index + 1}. {question}
                </TableCell>
                {filteredCompletedData.map(({ data: { answers } }) => {
                  // answer is '-' if we're at BBCSS
                  const catsTransform = (a) => {
                    if (a === 0) {
                      return 'No'
                    } else if (a === 1) {
                      return 'Yes'
                    }
                    return '-'
                  }

                  // we'll use let here..
                  let answer = answers[key] < 1000 && answers[key]
                  answer = ['0', 0].includes(answer) || !answer ? '-' : answer
                  const isCATSAnswer = catsScoring.includes(key) ? answer : catsTransform(answer)
                  return (
                    <TableCell>
                      {isCATS && <Typography variant="body1">{isCATSAnswer}</Typography>}
                      {!isCATS && <Typography variant="body1">{answer}</Typography>}
                    </TableCell>
                  )
                })}
              </TableRow>
            ))}
            <TableRow>
              <TableCell>
                <Typography variant="h5">Total Score</Typography>
              </TableCell>
              {completedAssessments?.map((assessment) => {
                const _score = get(assessment, 'data.score.sum', 0)
                return (
                  <TableCell align="left">
                    <Typography variant="h5">{_score}</Typography>
                  </TableCell>
                )
              })}
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </Stack>
  )
}
