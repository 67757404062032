export const MODALITIES = [
  'Accelerated Experiential-Dynamic Psychotherapy (AEDP)',
  'Acceptance and Commitment Therapy (ACT)',
  'Alexander Technique',
  'Applied Behavioral Analysis',
  'Aquatic Therapy',
  'Art/Music/Dance Therapy',
  'Biofeedback',
  'Brainspotting',
  'Certified Autism Specialist (CAS)',
  'Cognitive Behavioral Therapy (CBT)',
  'Cognitive Processing Therapy (CPT)',
  'Craniosacral Therapy',
  'Dialectical Behavior Therapy (DBT)',
  'DIR Floortime',
  'Energy Psychology',
  'Equine/Hippotherapy',
  'Eye Movement Desensitization and Reprocessing (EMDR)',
  'Family/Marital/Couples Therapy',
  'Feeding Therapy',
  'Feldenkrais',
  'Functional Medicine',
  'Internal Family Systems (IFS)',
  'Learning Disabilities/Educational Support',
  'LSVT Big/Loud',
  'Mindfulness-Based',
  'Neuro-Developmental',
  'Neuro-Psychiatric Rehabilitation',
  'Neuro-Rehabilitation',
  'Neurofeedback',
  'Play Therapy',
  'Psychotherapy',
  'Sand Therapy',
  'Sensory Integration/Sensory Processing',
  'Sex Therapy',
  'Somatic Experiencing (SE)',
  'Sports/Performance Coaching',
  'Structural Integration/Rolfing',
  'Substance Use/Addiction Treatment',
  'Trauma-Focused/Trauma-Responsive',
  'Other',
]

export const POPULATION = [
  'Older Adults (65+)',
  'Adults (18-64)',
  'Adolescents (13-17)',
  'Children (3-12)',
  'Infants (0-2)',
]

export const CLINICAL_SPECIALTIES = [
  'Addiction',
  'ADHD/ADD',
  'Anxiety',
  'Autism Spectrum Disorder (ASD)',
  'Chronic Pain/Fibromyalgia',
  'Depression',
  'Eating Disorders',
  'Grief & Loss',
  'Learning Differences',
  'Long-Covid',
  'Obsessive-Compulsive (OCD)',
  'Personality Disorders',
  'Physical or Chronic Illness',
  'Pregnancy, Prenatal, Postpartum',
  'Relationship or Family Difficulties',
  'Sensory Challenges',
  'Sexual or Gender Identity',
  'Sleep or Insomnia',
  'Social Difficulties',
  'Stress Management',
  'Trauma and PTSD',
  'Traumatic Brain Injury (TBI)',
  'Other',
]

export const DELIVERY_MODELS = ['Remote', 'In-person', 'Hybrid', 'Group', '1:1']

export const LANGUAGES = [
  'AAC',
  'Afrikaans',
  'Albanian',
  'Amharic',
  'Arabic',
  'Armenian',
  'ASL',
  'Assamese',
  'Aymara',
  'Azerbaijani',
  'Bambara',
  'Basque',
  'Belarusian',
  'Bengali',
  'Bhojpuri',
  'Bosnian',
  'Bulgarian',
  'Catalan',
  'Cebuano',
  'Chichewa',
  'Chinese (Simplified)',
  'Chinese (Traditional)',
  'Corsican',
  'Croatian',
  'Czech',
  'Danish',
  'Dhivehi',
  'Dogri',
  'Dutch',
  'English',
  'Esperanto',
  'Estonian',
  'Ewe',
  'Filipino',
  'Finnish',
  'French',
  'Frisian',
  'Galician',
  'Georgian',
  'German',
  'Greek',
  'Guarani',
  'Gujarati',
  'Haitian Creole',
  'Hausa',
  'Hawaiian',
  'Hebrew',
  'Hindi',
  'Hmong',
  'Hungarian',
  'Icelandic',
  'Igbo',
  'Ilocano',
  'Indonesian',
  'Irish',
  'Italian',
  'Japanese',
  'Javanese',
  'Kannada',
  'Kazakh',
  'Khmer',
  'Kinyarwanda',
  'Konkani',
  'Korean',
  'Krio',
  'Kurdish (Kurmanji)',
  'Kurdish (Sorani)',
  'Kyrgyz',
  'Lao',
  'Latin',
  'Latvian',
  'Lingala',
  'Lithuanian',
  'Luganda',
  'Luxembourgish',
  'Macedonian',
  'Maithili',
  'Malagasy',
  'Malay',
  'Malayalam',
  'Maltese',
  'Maori',
  'Marathi',
  'Meiteilon (Manipuri)',
  'Mizo',
  'Mongolian',
  'Myanmar (Burmese)',
  'Nepali',
  'Norwegian',
  'Odia (Oriya)',
  'Oromo',
  'Pashto',
  'Persian',
  'Polish',
  'Portuguese',
  'Punjabi',
  'Quechua',
  'Romanian',
  'Russian',
  'Samoan',
  'Sanskrit',
  'Scots Gaelic',
  'Sepedi',
  'Serbian',
  'Sesotho',
  'Shona',
  'Sindhi',
  'Sinhala',
  'Slovak',
  'Slovenian',
  'Somali',
  'Spanish',
  'Sundanese',
  'Swahili',
  'Swedish',
  'Tajik',
  'Tamil',
  'Tatar',
  'Telugu',
  'Thai',
  'Tibetan',
  'Tigrinya',
  'Tsonga',
  'Turkish',
  'Turkmen',
  'Twi',
  'Ukrainian',
  'Urdu',
  'Uyghur',
  'Uzbek',
  'Vietnamese',
  'Welsh',
  'Xhosa',
  'Yiddish',
  'Yoruba',
  'Zulu',
]
