/**
 * This component is used in my profile
 * It is different from wizard because we have margins and save automatically
 */

import React, { useContext, useEffect, useState } from 'react'
import { ProviderProfileContext } from '../MyProfile'
import { getCountries, getStates } from 'utils/constants/getIsoRegion'
import { Box, Stack, Typography, Button, Autocomplete, TextField, Grid } from '@mui/material'
import { formatRestrictionAddress } from 'utils/formatAddress'

const COUNTRY_NAMES = getCountries().map((c) => c.name)

export default function RemoteDeliveryRestriction({ idx, restriction }) {
  const {
    form,
    setWizardState,
    handleLists,
    upsertAddress,
    showErrors,
    setShowErrors,
    wizardErrors,
    setWizardErrors,
    handleSave,
    wizardState,
  } = useContext(ProviderProfileContext)
  const [open, setOpen] = useState(false)
  const [currentRestriction, setCurrentRestriction] = useState({
    countries: [],
    states: [],
    ...restriction,
  })
  const isNewRemoteRestriction = wizardState?.includes(
    `REMOTE_DELIVERY_INFO/EDIT_${form.remoteRestrictions.length}`
  )

  // handles
  const handleEdit = () => {
    setWizardState(`REMOTE_DELIVERY_INFO/EDIT_${idx}`)
    setOpen(true)
  }
  const handleAdd = async () => {
    // stop adding if we have error
    const currentErrors = {
      countries: !currentRestriction.countries.length,
    }

    if (Object.values(currentErrors).find((val) => !!val)) {
      setShowErrors(true)
      setWizardErrors(currentErrors)
      return
    }

    try {
      upsertAddress(idx, 'remoteRestrictions', currentRestriction)
      setWizardState()
      setOpen(false)
      setWizardErrors({})
      setShowErrors(false)
      // reset adding to default value (nothing)
      if (idx === form.remoteRestrictions.length) {
        setCurrentRestriction({ ...restriction, countries: [], states: [] })
      }
    } finally {
      const newRemoteRestriction = !form.remoteRestrictions.length
        ? [currentRestriction]
        : idx === form.remoteRestrictions.length
        ? [...form.remoteRestrictions, currentRestriction]
        : form.remoteRestrictions.with(idx, currentRestriction)
      handleSave({ remoteRestrictions: newRemoteRestriction })
    }
  }
  const handleRemove = () => {
    const newRemoteRestriction = form.remoteRestrictions.filter((_, index) => idx !== index)
    handleLists('remoteRestrictions')('', newRemoteRestriction)
    handleSave({ remoteRestrictions: newRemoteRestriction })
    setCurrentRestriction({ ...restriction, countries: [], states: [] })
    setWizardState()
    setOpen(false)
    setWizardErrors({})
    setShowErrors(false)
  }
  const handleCancel = () => {
    if (isNewRemoteRestriction) {
      setCurrentRestriction({ ...restriction, countries: [], states: [] })
    } else {
      setCurrentRestriction({ ...restriction })
    }
    setWizardState()
    setOpen(false)
    setWizardErrors({})
    setShowErrors(false)
  }
  const handleCountryChange = (_, val) => {
    const countries = val.map((countryName) => {
      const isoCode = getCountries().find((country) => country.name === countryName)?.code
      return {
        name: countryName,
        isoCode,
      }
    })
    // update current state
    setCurrentRestriction({ ...currentRestriction, countries, states: [] })
    // update context
    handleLists('countries')(countries)
    handleLists('states')([])
  }
  const handleStateChange = (_, val) => {
    const states = val.map((stateName) => {
      const isoCode = getStates(currentRestriction.countries[0].isoCode).find(
        (state) => state.name === stateName
      )?.code
      return {
        name: stateName,
        isoCode,
      }
    })
    // update context
    setCurrentRestriction({ ...currentRestriction, states })
    handleLists('state')(states)
  }

  const error = showErrors ? wizardErrors : {}

  // if we are not in edit mode and still open, reset
  useEffect(() => {
    if (!wizardState?.includes('EDIT') && open) {
      setOpen(false)
    }

    // eslint-disable-next-line
  }, [wizardState])

  if (
    wizardState?.includes('REMOTE_DELIVERY_INFO') &&
    !wizardState?.includes(`REMOTE_DELIVERY_INFO/EDIT_${idx}`)
  ) {
    return null
  }

  return (
    <>
      {!open && (
        <Grid container direction="row" justifyContent="space-between" alignItems="center">
          {idx !== form.remoteRestrictions.length && (
            <>
              <Grid item xs={3}>
                <Typography variant="subtitle1" fontWeight={500}>
                  Remote Delivery Area {idx + 1}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="body1">
                  I deliver remotely in {formatRestrictionAddress(restriction)}
                </Typography>
              </Grid>
            </>
          )}
          <Grid
            item
            container
            sx={{
              marginLeft: '-0.5rem',
            }}
            xs={3}
            justifyContent={idx === form.remoteRestrictions.length ? 'flex-start' : 'flex-end'}
          >
            <Button variant="text" onClick={handleEdit}>
              {idx === form.remoteRestrictions.length
                ? 'Specify Remote Delivery Area/s'
                : 'Edit Remote Delivery Area'}
            </Button>
          </Grid>
        </Grid>
      )}
      {open && (
        <Stack direction="column" alignItems="flex-start">
          <Stack sx={{ width: '100%' }}>
            <Typography variant="subtitle1" sx={{ fontWeight: 600, color: 'rgba(0, 0, 0, 0.87)' }}>
              Specify Remote Delivery Area:
            </Typography>

            <Stack direction="column" className="w-full">
              <Stack direction="row" spacing={1} alignItems="center">
                <Autocomplete
                  fullWidth
                  multiple
                  options={COUNTRY_NAMES}
                  value={currentRestriction.countries?.map((country) => country.name)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Countries"
                      placeholder="Countries"
                      error={error.countries}
                    />
                  )}
                  onChange={handleCountryChange}
                />
              </Stack>

              {currentRestriction.countries.length === 1 && (
                <Autocomplete
                  fullWidth
                  multiple
                  options={
                    currentRestriction.countries.length === 1
                      ? getStates(currentRestriction.countries[0].isoCode).map(
                          (state) => state.name
                        )
                      : []
                  }
                  label="State/Province/Region (optional)"
                  value={currentRestriction.states.map((state) => state.name)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="State (optional)"
                      placeholder="State (optional)"
                    />
                  )}
                  onChange={handleStateChange}
                />
              )}
            </Stack>
          </Stack>
        </Stack>
      )}

      {wizardState?.includes('REMOTE_DELIVERY_INFO/EDIT_') && (
        <Stack direction="row" justifyContent="space-between" my={2}>
          <Box sx={{ marginLeft: '-0.5rem' }}>
            <Button onClick={handleAdd}>
              {wizardState === `REMOTE_DELIVERY_INFO/EDIT_${form.remoteRestrictions.length}`
                ? 'Add Remote Delivery Area'
                : 'Save'}
            </Button>
            <Button onClick={handleCancel}>Cancel</Button>
          </Box>
          {wizardState !== `REMOTE_DELIVERY_INFO/EDIT_${form.remoteRestrictions.length}` && (
            <Button color="secondary" onClick={handleRemove}>
              Remove
            </Button>
          )}
        </Stack>
      )}
    </>
  )
}
