import React, { useState } from 'react'
import MainLayout from 'components/containers/main/Main'
import { matchPath, Outlet, useLocation } from 'react-router'
import { useSelector } from 'react-redux'
import { get } from 'lodash'
import { includesSome } from 'utils/includes'
import ROLES from 'utils/constants/roles'
import BackButton from 'components/button/BackButton'
import LoadingPage from 'components/LoadingPage'

export default function MyAccountWrapper() {
  const currentUserRoles = useSelector((state) => get(state, 'auth.user.roles', []))

  const isProvider = includesSome(currentUserRoles, [ROLES.PROVIDER, ROLES.PROVIDER_ALL_CLIENTS])
  const isBilling = includesSome(currentUserRoles, [ROLES.BILLING])
  const location = useLocation()
  const [title, setTitle] = useState('My Profile')
  const [loading, setLoading] = useState(false)

  const tabs = [
    {
      text: 'My Details',
      url: '/my/profile',
      isEnabled: ({ isProvider }) => isProvider,
      isActive: (pathname) => matchPath({ path: '/my/profile' }, pathname),
    },
    {
      text: 'My Organization',
      url: '/my/organization',
      isEnabled: ({ isProvider, isBilling }) => isProvider || isBilling,
      isActive: (pathname) => matchPath({ path: '/my/organization' }, pathname),
    },
    {
      text: 'My Account',
      url: '/my/account',
      pathPattern: '/my/account/*',
      isEnabled: () => true,
      isActive: (pathname) => matchPath({ path: '/my/account/*' }, pathname),
    },
  ].filter(({ isEnabled }) =>
    isEnabled({
      isProvider,
      isBilling,
    })
  )

  return (
    <LoadingPage bringToFront loading={loading} text="saving...">
      <MainLayout title={title} tabs={tabs} loading={loading}>
        {// back goes here
        matchPath({ path: '/my/account/*' }, location.pathname) &&
          !matchPath({ path: '/my/account' }, location.pathname) && (
            <BackButton sx={{ mt: 4, ml: 4 }} />
          )}

        <Outlet context={{ setTitle, loading, setLoading }} />
      </MainLayout>
    </LoadingPage>
  )
}
