import { gql } from '@apollo/client'

export const CREATE_SESSION = gql`
  mutation CreateSession($session: CreateSessionInput!) {
    createSession(session: $session) {
      id
    }
  }
`

export const UPDATE_DEMOGRAPHIC_INFO = gql`
  mutation UPDATE_DEMOGRAPHIC_INFO($user: UpdateUserInput!) {
    updateUser(user: $user) {
      id
      country
      birthYear
      gender
      productPreferences
    }
  }
`

// get completed assessments for current user
export const GET_COMPLETED_ASSESSMENTS_FOR_REPORTS = gql`
  query GetCompletedAssessmentsForReports($filter: FilterSessionsInput) {
    getSessions(filter: $filter) {
      data
      productEventId
      id
      updatedAt
    }
  }
`
export const UPDATE_ASSESSMENTS_SCORE_FROM_REPORTS = gql`
  mutation UpdateAssessmentsScoreFromReports($session: UpdateSessionInput!) {
    updateSession(session: $session) {
      id
    }
  }
`

export const GET_USERS_FOR_ASSESSMENTS = gql`
  query GetUsersForAssessments($filter: FilterUsersInput) {
    getUsers(filter: $filter) {
      fullName
      id
      email
      dob
      city
      country
      state
      gender
      birthYear
      productPreferences
    }
  }
`

export const UPDATE_SESSION = gql`
  mutation UpdateSession($session: UpdateSessionInput!) {
    updateSession(session: $session) {
      id
    }
  }
`

export const GET_ASSESSMENT_BY_STATUS = gql`
  query GetTotalAssessments($filter: FilterSessionsInput) {
    getSessions(filter: $filter, includeCount: true, limit: 0) {
      id
    }
  }
`

export const GET_ASSESSMENTS_NEW = gql`
  query AssessmentsGetSessionsNEW(
    $filter: FilterSessionsInput
    $offset: Int
    $limit: Int
    $sort: [[String]]
    $includeCount: Boolean
  ) {
    getSessions(
      includeCount: $includeCount
      filter: $filter
      offset: $offset
      limit: $limit
      sort: $sort
    ) {
      type
      data
      productId
      productEventId
      id
      user {
        id
        fullName
        email
        gender
        country
        birthYear
      }
      updatedAt
      createdAt
    }
  }
`

export const GET_ASSESSMENT_FOR_UPSERT = gql`
  query GetAssessmentForUpsert($filter: FilterSessionsInput, $limit: Int) {
    getSessions(filter: $filter, limit: $limit) {
      type
      data
      productId
      productEventId
      id
      user {
        id
        fullName
        email
        gender
        country
        birthYear
      }
      updatedAt
      createdAt
    }
  }
`
