import React from 'react'
import { CardHeader as MuiCardheader } from '@mui/material'

export default function CardHeader({ title, color, marginLeft, align = 'inherit', ...props }) {
  return (
    <MuiCardheader
      title={title}
      titleTypographyProps={{
        variant: 'h5',
        align,
        sx: {
          padding: '0 0 0 0',
          fontSize: '1.5rem',
          fontWeight: 500,
          color,
        },
      }}
      {...props}
    />
  )
}
