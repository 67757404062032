import React from 'react'
import { useNavigate } from 'react-router'
import { Box, Button, Card, Grid, Stack, Typography } from 'components'
import { useSnackbar } from 'notistack'
import ConnecteeSubmissionFields from '../shared/ConnecteeSubmissionFields'
import CardHeader from 'views/purchase/components/PurchaseCardHeader'
import { useMutation, useQuery } from '@apollo/client'
import { GET_LATEST_PROVIDER_PROFILE_INFO, UPDATE_PROVIDER_PROFILE_INFO } from '../shared/queries'
import CloseSnackbarAction from 'components/CloseSnackbarAction'
import { useDispatch, useSelector } from 'react-redux'
import { extendUser } from 'store/modules/auth'
import { setFF } from 'store/modules/ff'
import ClientConnectionsAvatar from 'components/avatar/ClientConnectionAvatar'

export default function ExpiredCards({ connecteeMatch, setLoading }) {
  const user = useSelector((state) => state?.auth?.user)
  const { enqueueSnackbar } = useSnackbar()
  const navigate = useNavigate()
  const dispatch = useDispatch()

  // fetch getMe for the newest providerProfileInfo, so that the newer data is not overwritten
  const { data } = useQuery(GET_LATEST_PROVIDER_PROFILE_INFO, {
    fetchPolicy: 'network-only',
  })
  const [updateProviderProfileInfo] = useMutation(UPDATE_PROVIDER_PROFILE_INFO)

  const handleStillOpenToNewClients = (openToNewClients) => () => {
    // in user.providerProfileInfo update { openToNewClients, showExpiredConnecteeCards: false }
    setLoading(true)
    if (data?.getMe?.providerProfileInfo) {
      const providerProfileInfo = data.getMe.providerProfileInfo
      const newProviderProfileInfo = {
        ...providerProfileInfo,
        openToNewClients,
        showExpiredConnecteeCards: false,
      }
      updateProviderProfileInfo({
        variables: {
          user: {
            id: user.id,
            providerProfileInfo: newProviderProfileInfo,
          },
        },
        onCompleted: () => {
          dispatch(
            extendUser({
              ...data.user,
              providerProfileInfo: newProviderProfileInfo,
            })
          )
          dispatch(setFF({ showClientConnectionsBadge: false }))
          setLoading(false)

          openToNewClients ? navigate('/connections/history') : navigate('/')
        },
        onError: (err) => {
          console.error(err)
          enqueueSnackbar('Failed to update settings. Please try again later', {
            variant: 'error',
            action: CloseSnackbarAction,
          })
          setLoading(false)
        },
      })
    }
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={6}>
        <Stack spacing={2}>
          <Card sx={{ backgroundColor: '#656565' }}>
            <CardHeader
              title="CONNECTION INVITATION EXPIRED"
              subheader={
                <Typography sx={{ color: '#fff' }}>
                  The 48 hour window to contact this potential client has passed and they have been
                  referred to an alternative Provider.
                </Typography>
              }
              avatar={<ClientConnectionsAvatar />}
              titleTypographyProps={{
                color: '#fff',
                variant: 'h5',
              }}
              sx={{ p: 2, m: 0 }}
            />
          </Card>

          <Card>
            <Stack direction="column" padding={2} alignItems="center">
              <Stack spacing={2} direction="column">
                <Box display="flex" justifyContent="center">
                  <Typography variant="h5">
                    Are you still open to receiving potential clients?
                  </Typography>
                </Box>
                <Stack
                  spacing={2}
                  flexDirection="row"
                  justifyContent="space-between"
                  alignItems="flex-end"
                >
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={handleStillOpenToNewClients(false)}
                  >
                    No, thank you
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleStillOpenToNewClients(true)}
                  >
                    Yes, please
                  </Button>
                </Stack>
              </Stack>
            </Stack>
          </Card>
        </Stack>
      </Grid>
      <Grid item xs={12} md={6}>
        <ConnecteeSubmissionFields connecteeMatch={connecteeMatch} />
      </Grid>
    </Grid>
  )
}
