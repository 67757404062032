/**
 * This form is for demographic info
 * v1: in a dialog
 * v2: now in a stepper
 */
import React, { useEffect, useState } from 'react'
import {
  Card,
  CardActions,
  CardContent,
  Collapse,
  Grid,
  IconButton,
  TextField,
  Typography,
  FormControlLabel,
  Checkbox,
  StepContent,
  Stack,
} from '@mui/material'
import { useSelector } from 'react-redux'
import { get } from 'lodash'

// custom components
import SelectGender from 'components/form/select-gender'
import SelectCountry from '../../../components/ecommerce/shipping/SelectCountry'
import { LoadingButton } from './loading-button'

// custom hooks
import useGetAssessmentId from '../utils/useGetAssessmentId'

// icons
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'

// constants
import EMAILS from 'utils/constants/emails'

export const isValidBirthYear = (value) => {
  const age = parseInt(value)
  if (age < 0 || age > 100) {
    return false
  } else if (0 <= age <= 100) {
    return true
  }
  return false
}

export default function DemographicInfoStep({
  showError,
  onSave,
  onCancel,
  loading,
  errorMessage,
}) {
  const [expanded, setExpanded] = useState(false)
  const { country, birthYear, gender, productPreferences, dob, openSelect: open } = useSelector(
    (state) => state.assessments
  )
  const [form, setForm] = useState({})
  const { assessmentProductId } = useGetAssessmentId()

  const formSkipAssessmentDemographicsRequest = get(
    form,
    `productPreferences.${assessmentProductId}.skipAssessmentDemographicsRequest`,
    false
  )

  useEffect(() => {
    const currentYear = new Date().getFullYear()
    const age = birthYear ? currentYear - birthYear : ''
    // save values once they are loaded/ changed
    setForm({ country, birthYear, gender, productPreferences, age })

    // we need open here, so we can refresh values to default on open
    // eslint-disable-next-line
  }, [open])

  const onChange = (type) => async (event) => {
    // birthyear cannot be negative
    if (type === 'birthYear') {
      const currentYear = new Date().getFullYear()
      const age = parseInt(event.target.value)
      const birthYear = currentYear - age

      if (!isNaN(age) && isValidBirthYear(age)) {
        setForm({
          ...form,
          birthYear,
          age: event.target.value,
          error: { ...form.error, age: false },
        })
      } else {
        setForm({
          ...form,
          birthYear,
          age: event.target.value,
          error: { ...form.error, age: true },
        })
      }
    } else if (type !== 'consent') {
      setForm({ ...form, [type]: event.target.value })
    } else {
      const isChecked = event.target.checked
      if (isChecked) {
        setForm({
          age: '',
          productPreferences: {
            ...productPreferences,
            [assessmentProductId]: {
              skipAssessmentDemographicsRequest: event.target.checked,
            },
          },
        })
      } else {
        const currentYear = new Date().getFullYear()
        const age = birthYear ? currentYear - birthYear : ''
        setForm({
          age,
          birthYear,
          gender: '',
          country: '',
          productPreferences: {
            ...productPreferences,
            [assessmentProductId]: {
              skipAssessmentDemographicsRequest: event.target.checked,
            },
          },
        })
      }
    }
  }

  // disableSave when checkbox is not true
  // or when all fields are not filled
  const disableSave =
    formSkipAssessmentDemographicsRequest ^
    (!(form.gender && form.birthYear && form.country) || form?.error?.age)

  return (
    <StepContent>
      <Typography variant="body1" className="mb-3">
        Please enter your client's demographic information to help us better understand the effects
        of the program and to help contribute to the program's growing evidence base. Your client's
        information is protected. All client data is used anonymously.
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={12} md={4}>
          <TextField
            label={`Age in ${new Date().getFullYear()}*`}
            value={form.age}
            className="w-full m-0"
            error={(showError && !form?.age) || form?.error?.age}
            onChange={onChange('birthYear')}
            helperText={
              ((showError && !form?.age) || form?.error?.age) && 'Age must be between 0-100'
            }
            disabled={!!formSkipAssessmentDemographicsRequest || !!dob}
            data-test="confirm-age-input"
          />
        </Grid>
        <Grid item xs={12} sm={12} md={4}>
          <SelectGender
            disabled={!!formSkipAssessmentDemographicsRequest}
            value={form.gender}
            onChange={onChange('gender')}
            error={showError && !form?.gender}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={4}>
          <SelectCountry
            showFullName
            error={showError && !form?.country}
            value={form.country}
            onChange={onChange('country')}
            disabled={!!formSkipAssessmentDemographicsRequest}
          />
        </Grid>
      </Grid>
      {showError && errorMessage && (
        <Grid container className="h-23 py-3">
          <Typography variant="caption" color="error">
            There was an error updating client Information.
            <br />
            {errorMessage}
            <br />
            For assistance, please contact our Client Success team at{' '}
            <a className="text-link" href={`mailto:${EMAILS.supportEmail}`}>
              {EMAILS.supportEmail}
            </a>
            .
          </Typography>
        </Grid>
      )}
      <Card
        variant="outlined"
        sx={{
          color: 'rgb(30, 70, 32)',
          backgroundColor: 'rgb(237, 247, 237)',
          marginTop: '0.25rem',
        }}
      >
        <CardActions className="pl-5">
          <Typography variant="body1">Why are we asking for this information?</Typography>
          <IconButton
            onClick={() => setExpanded((prevState) => !prevState)}
            aria-expanded={expanded}
            aria-label="show more"
            className="ml-auto"
            size="large"
          >
            <ExpandMoreIcon />
          </IconButton>
        </CardActions>
        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <CardContent>
            <Typography paragraph>
              By understanding more about clients, we can advance our knowledge of the effects of
              the program and continue to grow the program's evidence base. We can also make future
              assessments more accurate and relevant. This information will be used to support
              advancements in program clinical training and delivery methods, which will benefit all
              members of the program provider community.
            </Typography>
            <Typography paragraph>
              Your client's information is protected. We adhere to strict privacy guidelines and
              will never share client information (you can read about our privacy policy{' '}
              <a href="https://unyte.com/pages/privacy-policy">here</a>).
            </Typography>
          </CardContent>
        </Collapse>
      </Card>
      <FormControlLabel
        control={
          <Checkbox
            color="default"
            name="consent"
            onChange={onChange('consent')}
            value={
              !!form.productPreferences?.[assessmentProductId]?.skipAssessmentDemographicsRequest
            }
            data-test="assessment-consent-checkbox"
          />
        }
        label="I don't wish to contribute this client's anonymized data to the program's evidence base."
        className="pt-5"
      />
      <Stack direction="row" justifyContent="flex-start" spacing={2} py={4}>
        <LoadingButton loading={loading} onClick={onCancel}>
          {loading ? 'Loading...' : 'Cancel'}
        </LoadingButton>
        <LoadingButton
          loading={loading}
          variant="contained"
          onClick={onSave(form)}
          disabled={!!disableSave}
          data-test="confirm-client-information-button"
        >
          {loading ? 'Loading...' : 'Save'}
        </LoadingButton>
      </Stack>
    </StepContent>
  )
}
