/**
 * PAGINATION LAYOUT WRAPPER
 * - uses lazyquery with useEffect on variable changes
 * - set loadign should be debounced 1s
 * - query variables are stored in redux
 *
 * TODO: fetch slots stuff..
 *
 */
import PrivateRouteWrapper from 'components/routes/PrivateRouteWrapper'
import React, { useCallback, useRef, useState } from 'react'
import MainLayout from 'components/containers/main/Main'
import { Outlet, matchPath } from 'react-router'
import { routes } from 'utils/constants/routes'
import { useLazyQuery, useQuery } from '@apollo/client'
import { useDispatch, useSelector } from 'react-redux'
import { get } from 'lodash'
import ROLES from 'utils/constants/roles'
import ProgramsAndClientLicenses from '../components/modals/ProgramsAndClientLicenses'
import { PRODUCTS_QUERY, USER_QUERY_WITH_SLOTS, GET_AVAILABLE_SLOTS } from './constants/constants'

import { useOnValueChange } from 'utils/hooks/useOnValueChange'
import { debounce } from 'utils/debounce'
import LoadingPage from 'components/LoadingPage'
import { setClientsSlots } from 'store/modules/clients'

export const CLIENTS_PAGE_SIZE = 20

const TABS = [
  {
    text: 'Clients',
    url: '/clients',
    isActive: (pathname) => matchPath({ path: '/clients' }, pathname),
  },
  {
    text: 'Pending Invitations',
    url: '/clients/pending-invitations',
    isActive: (pathname) => matchPath({ path: '/clients/pending-invitations' }, pathname),
  },
]

const initialQuery = {
  anyRoles: [ROLES.CLIENT],
  any: '',
  isArchived: false,
  categories: [],
  includeNoSlotClients: true,
}
export default function ClientsLayoutWrapper2() {
  // hooks
  const ref = useRef(null)

  // redux variables
  const productPreferences = useSelector((state) => get(state, 'auth.user.productPreferences', {}))
  const hasCompletedFocusCertification = !!productPreferences.focusCertification?.completedAt
  const hasCompletedSspCertification = !!productPreferences.sspCertification?.completedAt
  const { showNewSubscriptionPlan } = useSelector((state) => state.ff)

  // state variables
  const [currentOffset, setCurrentOffset] = useState(0)
  const [loading, setLoading] = useState(false)
  const [loadingMessage, setLoadingMessage] = useState('loading...')
  const [allDataFetched, setAllDataFetched] = useState(false)
  const [openLicensePrompt, setOpenLicensePrompt] = useState(false) // we need a temporary copy of all clients to prevent unneccessary loading UI
  const [openCreateClientPrompt, setOpenCreateClientPrompt] = useState(false)
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [data, setData] = useState([])
  const [totalCount, setTotalCount] = useState(0)
  const [chipState, setChipState] = useState({})
  const [filterState, setFilterState] = useState({})
  const [sort, setSort] = useState([])
  const [refetchCounter, setRefetchCounter] = useState(0) // we will watch refetch counter ()

  // GQL
  const { data: products } = useQuery(PRODUCTS_QUERY)

  /**
   * Using network only because we have encountered caching error during really quick refreshes
   * - we cannot dynamically change queries because it will lead to caching errors
   */
  const [loadUsersWithSlots] = useLazyQuery(USER_QUERY_WITH_SLOTS, {
    fetchPolicy: 'no-cache',
  })

  const [loadSlots] = useLazyQuery(GET_AVAILABLE_SLOTS, {
    fetchPolicy: 'no-cache',
    skip: !showNewSubscriptionPlan,
  })

  // this is used after we update the seats of a client `ManageSeats.js`
  // we won't use set query variables here because we don't want the table to shrink to one variable..
  /**
   * 1. load users
   * 2. load slots for each user
   * 3. load total users
   */
  const onSort = ({ sort: _sort }) => {
    setSort([..._sort])
  }

  useOnValueChange(
    JSON.stringify({ rowsPerPage, page, filterState, sort, refetchCounter }),
    async () => {
      const { data } = await loadUsersWithSlots({
        variables: {
          filter: filterState,
          includeCount: true,
          limit: rowsPerPage,
          offset: rowsPerPage * page,
          sort,
        },
      })
      const users = get(data, 'getUsers', [])
      const totalCount = get(data, 'getUsersCount', 0)
      setData(users)
      setTotalCount(totalCount)
    }
  )
  // everytime we refetch, also refetch available slots..
  const [slotsInfo, setSlotsInfo] = useState({ ssp: [], focus: [] })
  const dispatch = useDispatch()

  useOnValueChange(JSON.stringify({ refetchCounter }), async () => {
    const { data } = await loadSlots()
    const slots = get(data, 'getSlots', [])
    const sspSlots = slots.filter((slot) => slot.category === 'ssp')
    const focusSlots = slots.filter((slot) => slot.category === 'focus')
    const availableSspSlots = slots.filter(
      ({ category, status }) => category === 'ssp' && status === 'available'
    )
    const availableFocusSlots = slots.filter(
      ({ category, status }) => category === 'focus' && status === 'available'
    )

    // store slots in REdux for header and local state
    dispatch(
      setClientsSlots({
        availableSspSlots: availableSspSlots.length,
        availableFocusSlots: availableFocusSlots.length,
      })
    )
    setSlotsInfo({
      ssp: sspSlots,
      focus: focusSlots,
      availableFocusSlots,
      availableSspSlots,
    })
  })

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  const handleSearch = (value) => {
    setFilterState({ ...filterState, any: value })
  }
  const debouncedSearch = useCallback(debounce(handleSearch, 1000), [handleSearch])

  return (
    <PrivateRouteWrapper notAuthorized="hasProducts" roles={routes.clients.roles}>
      <LoadingPage text={loadingMessage} loading={loading}>
        <MainLayout title="Clients" tabs={TABS}>
          <Outlet
            context={{
              data,
              allDataFetched,
              currentOffset,
              fetch,
              initialQuery,
              loading,
              loadingMessage,
              openCreateClientPrompt,
              openLicensePrompt,
              products: get(products, 'getProducts', []),
              queryVars: {
                sort: [],
                filter: initialQuery,
                limit: CLIENTS_PAGE_SIZE,
                offset: 0,
              },
              ref,
              setAllDataFetched,
              setCurrentOffset,
              setLoading,
              setLoadingMessage,
              setOpenCreateClientPrompt,
              setOpenLicensePrompt,

              // pagination
              totalCount,
              page,
              rowsPerPage,
              handleChangePage,
              handleChangeRowsPerPage,

              // filters
              filterState,
              setFilterState,
              chipState,
              setChipState,
              activeIds: [],
              debouncedSearch,
              sort,
              onSort,
              setRefetchCounter,

              // slots
              refetchOne: () => {}, // we need this for legacy functions
              refetchSlots: () => {},
              slotsInfo,
              hasCompletedFocusCertification,
              hasCompletedSspCertification,
            }}
          />
          {showNewSubscriptionPlan && <ProgramsAndClientLicenses />}
        </MainLayout>
      </LoadingPage>
    </PrivateRouteWrapper>
  )
}
