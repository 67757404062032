import aws from 'utils/aws'
import { Auth } from 'aws-amplify'
import { loadFFValues, resetFF } from './ff'
import { client, apolloFetch, apolloMutate } from 'utils/apollo'
import { gql } from '@apollo/client'
import { setNotifications } from 'store/modules/app'
import { setAssessmentsIntialState } from './assessments'
import { resetSessions } from 'store/modules/sessions'
import { resetOrganization } from './organization'
import { resetClients } from './clients'
import { resetActiveClients } from './activeClients'
import { resetAdmins } from './admins'
import { resetSeats } from './seats'
import { resetLicenses } from './licenses'
import { resetProviders } from './providers'
import { resetStore } from './new-purchase'

const GET_ME_QUERY = gql`
  query getMe {
    getMe {
      id
      email
      uuid
      firstName
      lastName
      fullName
      gender
      createdAt
      updatedAt
      organizationId
      roles
      organization {
        id
        name
        createdAt
        settings
      }
      providerId
      lastLoginAt
      isSuspended
      address1
      address2
      birthYear
      city
      state
      country
      phone
      dob
      otherProfessionalCredentials
      professionalCredentials
      zip
      clientsCount
      productPreferences
      providerProfileInfo
      tags {
        value
      }
    }
  }
`

const INVITATION_ACCEPT_NEW = gql`
  mutation acceptInvitationNewUser($invitation: acceptInvitationNewUserInput!) {
    acceptInvitationNewUser(invitation: $invitation) {
      id
    }
  }
`

export const SIGN_IN_SUCCESS = 'AUTH/SIGN_IN_SUCCESS'
export const LOGOUT_SUCCESS = 'AUTH/LOGOUT_SUCCESS'
export const SET_PENDING = 'AUTH/SET_PENDING'
export const SET_LOADED_AUTH = 'AUTH/SET_LOADED_AUTH'
export const SET_SIGN_IN_FLAG = 'AUTH/SET_SIGN_IN_FLAG'
export const EXTEND_USER = 'AUTH/EXTEND_USER'
export const SET_DEEPLINK = 'AUTH/SET_DEEPLINK'
export const SET_ERROR = 'AUTH/SET_ERROR'
export const SHOW_ERROR = 'AUTH/SHOW_ERROR'

const initialState = {
  pending: false,
  user: null,
  error: '',
  showError: false,
  deepLink: true,
  loadedAuth: false,
  signInFlag: false,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case SIGN_IN_SUCCESS:
      return {
        ...state,
        ...action.payload,
      }
    case SET_DEEPLINK:
      return {
        ...state,
        ...action.payload,
      }
    case SET_LOADED_AUTH:
      return {
        ...state,
        ...action.payload,
      }
    case SET_SIGN_IN_FLAG:
      return {
        ...state,
        ...action.payload,
      }
    case EXTEND_USER:
      return {
        ...state,
        user: {
          ...state.user,
          ...action.payload,
        },
      }
    case SET_ERROR:
      return {
        ...state,
        error: action.payload,
      }
    case SHOW_ERROR:
      return {
        ...state,
        showError: action.payload,
      }
    default:
      return state
  }
}

export function loginWithoutRedux(payload) {
  return async (dispatch) => {
    await dispatch(setPending(true))
    await aws.Auth.signIn(payload.email, payload.password)
    await dispatch(setPending(false))
  }
}
export function login(payload) {
  return async (dispatch) => {
    await dispatch(setPending(true))
    try {
      await aws.Auth.signIn(payload.email, payload.password)
      await dispatch(getUser())
      await dispatch(setDeepLink({ deepLink: null }))
      await dispatch(setSignInFlag({ signInFlag: true }))
    } finally {
      await dispatch(setPending(false))
    }
  }
}

export function authenticateUser(payload) {
  return async (dispatch) => {
    await dispatch(setPending(true))
    try {
      await aws.Auth.signIn(payload.email, payload.password)
      await dispatch(setLoadedAuth({ loadedAuth: true }))
    } finally {
      await dispatch(setPending(false))
    }
  }
}

function clearLocalStorage() {
  try {
    Object.keys(window.localStorage).forEach((key) => {
      if (key.startsWith('Cognito')) {
        window.localStorage.removeItem(key)
      }
      window.localStorage.removeItem('AuthState')
      window.localStorage.removeItem('token')
    })
  } catch (error) {
    console.error(error)
  }
}

export function logout() {
  return async (dispatch) => {
    try {
      // order of operation matters
      aws.Auth.signOut()
      await dispatch(resetStore())
      await dispatch(setUserInfo({ token: null, email: null, username: null, user: null }))
      await dispatch(setAssessmentsIntialState())
      await dispatch(resetClients())
      await dispatch(resetActiveClients())
      await dispatch(resetSeats())
      await dispatch(resetLicenses())
      await dispatch(resetSessions())
      await dispatch(resetOrganization())
      await dispatch(resetFF())
      await dispatch(resetAdmins())
      await dispatch(resetProviders())
      await client.resetStore()
      await clearLocalStorage()
    } catch (error) {
      console.error('logout error', error)
    }
  }
}

export function resetDeepLink() {
  return async (dispatch) => {
    try {
      await dispatch(setDeepLink({ deepLink: null }))
    } catch (error) {
      console.error('set deeplink error', error)
    }
  }
}

export function changePassword(currentPassword, newPassword) {
  return async () => {
    try {
      const cognitoUser = await aws.Auth.currentAuthenticatedUser()
      await aws.Auth.changePassword(cognitoUser, currentPassword, newPassword)
    } catch (err) {
      if (err.message?.includes('Password must have numeric characters')) {
        throw new Error('Password must have numeric characters')
      } else if (
        err.message?.includes('Member must have length greater than or equal to 6') ||
        err.message?.includes('Password did not conform with policy: Password not long enough')
      ) {
        throw new Error('Password must have length greater than or equal to 6')
      } else if (err.message?.includes('Incorrect username or password.')) {
        throw new Error('Current password is incorrect')
      }
      throw err
    }
  }
}

export function resetPassword(newPassword, newPasswordConfirmed, email, code) {
  return async () => {
    try {
      const num = /\d/
      if (!newPassword) {
        throw new Error('Password cannot be empty')
      } else if (newPassword !== newPasswordConfirmed) {
        throw new Error('Passwords do not match')
      } else if (newPassword.length < 8) {
        throw new Error('Password must be 8 characters or longer')
      } else if (newPassword.search(num) === -1) {
        throw new Error('Password must have at least 1 number and lowercase character')
      }
      // In order for this to work with email, cognito record must allow sign-in with email attribute
      await Auth.forgotPasswordSubmit(email, code, newPassword)
    } catch (err) {
      if (err.message.includes('Invalid code provided, please request a code again.')) {
        throw new Error("Reset link has expired, click 'Resend Link' for a new link.")
      } else {
        throw new Error(err.message)
      }
    }
  }
}

export function forgotPassword(email) {
  return async (dispatch) => {
    await dispatch(setPending(true))
    try {
      if (!email) {
        throw new Error('Please enter an email')
      }
      await Auth.forgotPassword(email)
      await dispatch(setPending(false))
    } catch (err) {
      await dispatch(setPending(false))
      if (err.message.includes('Attempt limit exceeded, please try after some time.')) {
        throw new Error('Reset attempt limit exceeded, please try after some time.')
      } else if (err.message.includes('Username/client id combination not found.')) {
        throw new Error(`No user was found with email: ${email}`)
      } else {
        throw new Error(err.message)
      }
    }
  }
}

export function invitationAccept({
  invitationId,
  email,
  password,
  confirmPassword,
  firstName,
  lastName,
}) {
  return async (dispatch) => {
    if (password !== confirmPassword) {
      throw new Error('Passwords do not match')
    }
    try {
      await aws.Auth.signUp({
        username: email.replace('@', '-'),
        password,
        attributes: {
          email,
        },
        validationData: [],
      })
    } catch (error) {
      if (error['code'] !== 'UsernameExistsException') {
        console.error(error)
        throw new Error(
          `Sorry but we had an issue creating your account.\n` +
            `Please try again or contact support if this issue persists.\n` +
            `For reference: "${error.message}"`
        )
      }
    }

    try {
      await aws.Auth.signIn(email, password)
    } catch (e) {
      console.error(e)
      if (e['code'] === 22) {
        throw new Error(
          `Sorry, we had an issue activating your new account.\n` +
            `Please try again or contact support if this issue persists.\n` +
            `For reference: "localStorage_full"\n
            ${e.name}: ${e.message}\n` +
            `Possible solution: clear cache and cookies and restart browser.`
        )
      } else {
        throw new Error(
          `Sorry, we had an issue activating your new account.\n` +
            `Please try again or contact support if this issue persists.\n` +
            `For reference: "${e['code']}"`
        )
      }
    }

    try {
      await apolloMutate({
        mutation: INVITATION_ACCEPT_NEW,
        variables: {
          invitation: {
            id: invitationId,
            user: {
              email,
              firstName,
              lastName,
            },
          },
        },
      })
    } catch (e) {
      console.error('InvitationAccept > apolloMutate: ', e)
      throw new Error(
        `Sorry, we had an issue activating your new account.\n` +
          `Please try again or contact support if this issue persists.\n`
      )
    }

    await dispatch(getUser())
  }
}

export function getUser() {
  return async (dispatch) => {
    if (window.Cypress) {
      return
    }
    try {
      const cognitoUser = await Auth.currentAuthenticatedUser()
      const token = cognitoUser.signInUserSession.idToken.jwtToken
      const user = await apolloFetch({
        query: GET_ME_QUERY,
      })

      if (!user.data.getMe) {
        localStorage.removeItem('token')
        if (!user.errors) {
          throw new Error('null user')
        } else {
          // eslint-disable-next-line
          throw {
            message: user.errors.map((e) => e.message).join(' '),
          }
        }
      }

      await dispatch(extendUser(user.data.getMe))
      await dispatch(
        setUserInfo({
          token,
          email: cognitoUser.attributes.email,
          username: cognitoUser.username,
        })
      )
      await dispatch(loadFFValues())
    } catch (e) {
      if (e.message === 'NetworkError when attempting to fetch resource.') {
        dispatch(setNotifications(['Server error, please try again later']))
      } else if (e.message === 'null user') {
        dispatch(setError('Null Error'))
      } else if (e !== 'not authenticated') {
        console.error('getuser', e)
      }
      dispatch(logout())
    }
  }
}

export function setUserInfo(payload) {
  return {
    type: SIGN_IN_SUCCESS,
    payload,
  }
}

export function setDeepLink(payload) {
  return {
    type: SET_DEEPLINK,
    payload,
  }
}

export function extendUser(payload) {
  return {
    type: EXTEND_USER,
    payload,
  }
}

export function setPending(payload) {
  return {
    type: SET_PENDING,
    payload,
  }
}
export function setLoadedAuth(payload) {
  return {
    type: SET_LOADED_AUTH,
    payload,
  }
}
export function setSignInFlag(payload) {
  return {
    type: SET_SIGN_IN_FLAG,
    payload,
  }
}

export function setError(payload) {
  return {
    type: SET_ERROR,
    payload,
  }
}

export function showError(payload) {
  return {
    type: SHOW_ERROR,
    payload,
  }
}
