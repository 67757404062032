import React, { useState } from 'react'
import {
  Button,
  Card,
  CardHeader,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Stack,
} from '@mui/material'

const PROFILE_FIELD_COLUMNS = [
  {
    key: 1,
    fields: [
      { label: 'Modalities', fieldName: 'showModalities' },
      { label: 'Clinical Specialties', fieldName: 'showClinicalSpecialties' },
      { label: 'Population Served', fieldName: 'showPopulation' },
      { label: 'Languages', fieldName: 'showLanguagesSpoken' },
    ],
  },
  {
    key: 2,
    fields: [
      { label: 'Location (state)', fieldName: 'showState' },
      { label: 'Website', fieldName: 'showWebsite' },
      { label: 'Bio', fieldName: 'showBio' },
    ],
  },
]

export default function CommunityProfileInfo({ form, handleSave }) {
  const [editForm, setEditForm] = useState({
    showModalities: form.showModalities,
    showClinicalSpecialties: form.showClinicalSpecialties,
    showPopulation: form.showPopulation,
    showLanguagesSpoken: form.showLanguagesSpoken,
    showState: form.showState,
    showWebsite: form.showWebsite,
    showBio: form.showBio,
  })
  const [isEditMode, setIsEditMode] = useState(false)

  const toggleEditMode = () => setIsEditMode(!isEditMode)

  const handleChange = (fieldName) => (event) => {
    setEditForm({ ...editForm, [fieldName]: event.target.checked })
  }

  const handleSubmit = () => {
    handleSave(editForm)
    toggleEditMode()
  }

  const handleCancel = () => {
    setEditForm({
      showModalities: form.showModalities,
      showClinicalSpecialties: form.showClinicalSpecialties,
      showPopulation: form.showPopulation,
      showLanguagesSpoken: form.showLanguagesSpoken,
      showState: form.showState,
      showWebsite: form.showWebsite,
      showBio: form.showBio,
    })
    toggleEditMode()
  }

  return (
    <Card variant="outlined" elevation={0}>
      <CardHeader
        title="MyUnyte Community Profile"
        subheader="Display this additional information on myUnyte Community profile"
        titleTypographyProps={{
          variant: 'h5',
          fontSize: '2rem',
          fontWeight: 500,
        }}
        subheaderTypographyProps={{
          fontSize: '1.1rem',
        }}
      />
      <Stack direction="column" p={2} alignItems="center">
        <Stack direction="row" alignItems="center" spacing={2}>
          {PROFILE_FIELD_COLUMNS.map((column) => (
            <FormGroup key={column.key}>
              {column.fields.map(({ label, fieldName }) => (
                <FormControlLabel
                  key={fieldName}
                  label={label}
                  disabled={!isEditMode}
                  control={
                    <Checkbox checked={editForm[fieldName]} onChange={handleChange(fieldName)} />
                  }
                />
              ))}
            </FormGroup>
          ))}
        </Stack>
      </Stack>
      {!isEditMode && (
        <Button sx={{ margin: '16px' }} onClick={toggleEditMode}>
          Edit Fields to Display
        </Button>
      )}
      {isEditMode && (
        <Stack direction="row" justifyContent="space-around" p={2}>
          <Button color="secondary" onClick={handleCancel}>
            Cancel
          </Button>
          <Button onClick={handleSubmit}>Save</Button>
        </Stack>
      )}
    </Card>
  )
}
