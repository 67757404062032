import React, { useState } from 'react'
import { Box, Button, Card, Grid, MenuItem, Select, Stack, TextField, Typography } from 'components'
import { useSnackbar } from 'notistack'
import CloseSnackbarAction from 'components/CloseSnackbarAction'

import ConnecteeSubmissionFields from '../shared/ConnecteeSubmissionFields'

import { ACCEPTED_DECLINE_REASONS } from '../shared/rejectionReasons'
import { useMutation } from '@apollo/client'
import { UPDATE_CONNECTEE_MATCH } from '../shared/queries'
import CardHeader from 'views/purchase/components/PurchaseCardHeader'
import { useNavigate } from 'react-router'
import ClientContactInfo from '../shared/ClientContactInfo'
import { useDispatch } from 'react-redux'
import { setFF } from 'store/modules/ff'
import { ENGAGED, DECLINED } from '../shared/constants'
import ClientConnectionsAvatar from 'components/avatar/ClientConnectionAvatar'

export default function AcceptedCards({ connecteeMatch, setConnecteeMatch, setLoading }) {
  const [showRejectionReasons, setShowRejectionReasons] = useState(false)
  const [rejectionReason, setRejectionReason] = useState('')
  const [otherRejectionReason, setOtherRejectionReason] = useState()
  const dispatch = useDispatch()

  const isDisabledDeclineButton =
    !rejectionReason || (rejectionReason === 'Other' && !otherRejectionReason?.length)

  const navigate = useNavigate()
  const { enqueueSnackbar } = useSnackbar()

  const [updateConnecteeMatch] = useMutation(UPDATE_CONNECTEE_MATCH, {
    onCompleted: (data) => {
      if (data?.updateConnecteeMatch) {
        setConnecteeMatch(data?.updateConnecteeMatch)
      }
    },
  })
  const acceptConnecteeSubmission = async () => {
    try {
      setLoading(true)
      await updateConnecteeMatch({
        variables: {
          connecteeMatch: { id: connecteeMatch.id, status: ENGAGED },
        },
      })
    } catch (err) {
      console.error(err)
      enqueueSnackbar('Failed to accept client. Please try again', {
        variant: 'error',
        action: CloseSnackbarAction,
      })
    } finally {
      setLoading(false)
    }
  }

  const rejectConnecteeSubmission = async () => {
    try {
      setLoading(true)
      await updateConnecteeMatch({
        variables: {
          connecteeMatch: {
            id: connecteeMatch.id,
            status: DECLINED,
            rejectReason: rejectionReason,
            otherRejectReason: otherRejectionReason,
          },
        },
      })
      dispatch(setFF({ showClientConnectionsBadge: false }))
      navigate('/connections/history')
    } catch (err) {
      console.error(err)
      enqueueSnackbar('Failed to reject client. Please try again', {
        variant: 'error',
        action: CloseSnackbarAction,
      })
    } finally {
      setLoading(false)
    }
  }

  const cancelReject = () => {
    setRejectionReason()
    setOtherRejectionReason()
    setShowRejectionReasons(false)
  }

  const handleRejectionReasonChange = (event) => {
    setRejectionReason(event.target.value)
  }

  const handleOtherRejectionReasonChange = (event) => {
    setOtherRejectionReason(event.target.value)
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={6}>
        <Stack spacing={2}>
          <Card sx={{ backgroundColor: '#3783ad' }}>
            <CardHeader
              title="CONTACT POTENTIAL CLIENT"
              subheader={
                <Typography sx={{ color: '#fff' }}>
                  Please contact this potential client to determine whether they are a fit for your
                  practice.
                </Typography>
              }
              avatar={<ClientConnectionsAvatar />}
              titleTypographyProps={{
                color: '#fff',
                variant: 'h5',
              }}
              sx={{ p: 2, m: 0 }}
            />
          </Card>

          <ClientContactInfo connecteeSubmission={connecteeMatch.connecteeSubmission} />

          <Card>
            <Stack direction="column" spacing={2} alignItems="center">
              {!showRejectionReasons && (
                <Stack spacing={2} padding={2} direction="column">
                  <Box display="flex" justifyContent="center">
                    <Typography variant="h5">
                      Have you successfully engaged with this client?
                    </Typography>
                  </Box>
                  <Stack
                    spacing={2}
                    flexDirection="row"
                    justifyContent="space-between"
                    alignItems="flex-end"
                  >
                    <Button
                      variant="contained"
                      color="secondary"
                      onClick={() => setShowRejectionReasons(true)}
                    >
                      No, Decline This Match
                    </Button>
                    <Button variant="contained" color="primary" onClick={acceptConnecteeSubmission}>
                      Yes I Have
                    </Button>
                  </Stack>
                </Stack>
              )}

              {showRejectionReasons && (
                <Stack spacing={2} padding={2} direction="column">
                  <Box display="flex" justifyContent="center">
                    <Typography variant="h5">
                      Why was this client not a good fit for you?
                    </Typography>
                  </Box>
                  <Select
                    labelId="reject-connectee-select-label"
                    id="reject-connectee-select-label"
                    value={rejectionReason || ''}
                    renderValue={() => rejectionReason}
                    onChange={handleRejectionReasonChange}
                    label="Rejection Reason"
                    variant="standard"
                  >
                    {ACCEPTED_DECLINE_REASONS.map(({ key, additionalInfo }) => (
                      <MenuItem key={key} value={key}>
                        <Typography fontWeight={500}>{key}:</Typography>
                        &nbsp;
                        <Typography color="textSecondary">{additionalInfo}</Typography>
                      </MenuItem>
                    ))}
                  </Select>
                  <Stack
                    spacing={2}
                    flexDirection="row"
                    justifyContent="space-between"
                    alignItems="flex-end"
                  >
                    <TextField
                      onChange={handleOtherRejectionReasonChange}
                      label={rejectionReason === 'Other' ? 'Other Reason' : 'Additional info'}
                    />
                  </Stack>
                  <Stack
                    spacing={2}
                    flexDirection="row"
                    justifyContent="space-between"
                    alignItems="flex-end"
                  >
                    <Button variant="contained" color="secondary" onClick={cancelReject}>
                      Go Back
                    </Button>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={rejectConnecteeSubmission}
                      disabled={isDisabledDeclineButton}
                    >
                      Submit Reason and Decline Match
                    </Button>
                  </Stack>
                </Stack>
              )}
            </Stack>
          </Card>
        </Stack>
      </Grid>

      <Grid item xs={12} md={6}>
        <Stack spacing={2}>
          <ConnecteeSubmissionFields connecteeMatch={connecteeMatch} />
        </Stack>
      </Grid>
    </Grid>
  )
}
