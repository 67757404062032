import React from 'react'
import { Card, CardContent, Typography, CardMedia, Box, Grid, Link } from 'components'
import makeStyles from '@mui/styles/makeStyles'

import LeafLogo from '../icons/LeafLogo'
import LINKS from 'utils/constants/links'

const useStyles = makeStyles({
  container: {
    display: 'flex',
    flexFlow: 'column',
  },
  root: {
    display: 'flex',
    justifyContent: 'center',
  },
  cover: {
    width: 200,
    height: 300,
  },
  details: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: 40,
    marginBottom: 40,
  },
  inlineFlex: {
    display: 'inline-flex',
  },
  googleLogo: {
    width: 140,
    paddingTop: '25%',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  appleLogo: {
    width: 140,
    paddingTop: '27%',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  typography: {
    fontSize: '0.875rem',
    lineHeight: '1rem',
  },
  copyrightText: {
    color: '#718096',
    fontSize: '0.75rem',
    lineHeight: '0.875rem',
  },
  links: {
    textDecoration: 'none',
    '&:hover': {
      color: '#a0aec0',
    },
  },
  footer: {
    bottom: 0,
    left: 0,
    right: 0,
    margin: '0 auto',
    paddingTop: '10px',
    paddingBottom: '10px',
  },
})

/**
 * TODO: UW-1375: Change sspActive to hasActiveProducts
 * https://unytehealth.atlassian.net/browse/UW-1375
 */
export default ({ showDownloadImage }) => {
  const classes = useStyles()
  return (
    <Grid
      container
      className={classes.container}
      direction="column"
      justifyContent="center"
      alignItems="center"
    >
      {showDownloadImage && (
        <Grid item mt={2}>
          <Card className={classes.root} elevation={0}>
            <CardMedia className={classes.cover} image="/images/dashboard/MockupPhone.png" />
            <CardContent className={`m-auto`}>
              <div className={`${classes.inlineFlex} pb-4`}>
                <LeafLogo />
                <Typography className={`${classes.typography} pl-1 m-auto`}>
                  Get the Unyte-iLs App
                </Typography>
              </div>
              <a href={LINKS.download.playStore.href} target="_blank" rel="noopener noreferrer">
                <CardMedia
                  className={`${classes.googleLogo} my-1`}
                  image={LINKS.download.playStore.imgSrc}
                />
              </a>
              <a href={LINKS.download.appStore.href} target="_blank" rel="noopener noreferrer">
                <CardMedia
                  className={`my-1 ${classes.appleLogo}`}
                  image={LINKS.download.appStore.imgSrc}
                />
              </a>
            </CardContent>
          </Card>
        </Grid>
      )}
      <footer className={classes.footer}>
        <Box
          mb={1}
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          display="flex"
          alignSelf="flex-end"
        >
          <Typography className={`text-gray ${classes.copyrightText}`}>
            Copyright © Unyte Health Inc {new Date().getFullYear()}
          </Typography>
          <Box display="flex" flexDirection="row">
            <Link
              className={`text-gray px-1 ${classes.copyrightText} ${classes.links}`}
              target="_blank"
              to={LINKS.invitation.termsOfService}
            >
              Terms & Conditions
            </Link>
            <Link
              className={`text-gray px-1 ${classes.copyrightText} ${classes.links}`}
              target="_blank"
              to={LINKS.invitation.privacyNotice}
            >
              Privacy Notice
            </Link>
          </Box>
        </Box>
      </footer>
    </Grid>
  )
}
