/**
 * This component will be for opening a video or viewing pdfs from resources
 */
import React, { useEffect, useState, useRef } from 'react'
import { useNavigate, useParams, useOutletContext, useLocation } from 'react-router-dom'
import { useSnackbar } from 'notistack'
import { useReactToPrint } from 'react-to-print'
import { Container, Grid, Button, Typography, IconButton, Stack } from '@mui/material'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos'

import resources from 'utils/constants/resources'
import useGTM from 'utils/hooks/useGTM'
import FocusActivity from '../FocusActivity'
import CloseSnackbarAction from 'components/CloseSnackbarAction'
import RateResource from './RateResource'
import TitrationTool from './TitrationTool'
import SecureStorageFile from './SecureStorageFile'
import RegulatingActivity from 'components/RegulatingActivity'

export default function ResourceItemDetails() {
  // load hooks
  const navigate = useNavigate()
  const { gtmBegin } = useGTM()
  const { enqueueSnackbar } = useSnackbar()
  const location = useLocation()

  // get redux data
  const [height, setHeight] = React.useState(0)

  const {
    hasSspProducts,
    hasFocusProducts,
    hasAssessmentProducts,
    userRoles,
    loading,
    ...rest
  } = useOutletContext()

  // get url data
  const { id: params_id } = useParams()
  const resource = resources.find(({ id }) => id === params_id)

  // Do not allow provider specific resources page to be shown to Clients.
  if (resource.tag === 'provider-toolkit' && userRoles.includes('client')) {
    navigate('/resources')
  }

  // set height of video
  const measuredRef = React.useCallback((node) => {
    if (node !== null) {
      setHeight(node.getBoundingClientRect().height)
    }
  }, [])

  const goBack = () => {
    const tag = resource.tag ? `/${resource.tag}` : ''
    const search = location.search
    navigate(`/resources${tag}${search}`)
  }

  // on load send to analytics
  useEffect(() => {
    gtmBegin('resource_open')
    // eslint-disable-next-line
  }, [])

  if (!resource) {
    navigate('/resources')
    console.error('resources not found', params_id)
  }

  // ??
  if (!loading) {
    const isOnlyBilling = userRoles.length === 1 && userRoles?.includes('billing')
    const isEnabledByRole = resource.isEnabledByRole({ userRoles })
    const isEnabledByProductsStatus = resource.isEnabledByProductsStatus({
      hasSspProducts,
      hasFocusProducts,
      hasAssessmentProducts,
      clientHasFocus: hasFocusProducts,
      ...rest,
    })

    // Redirect to dashboard if:
    // - your role is only billing
    // - valid role (ie client, provider)
    // - valid product status (ie sspCert..)
    if (!isEnabledByRole || isOnlyBilling || !isEnabledByProductsStatus) {
      enqueueSnackbar('Access to this page is not authorized', {
        variant: 'error',
        action: CloseSnackbarAction,
      })
      navigate('/')
    }
  }

  // need to have a reference to the component we want to print.
  const componentRef = useRef(null)
  const [showPrintObjects, setShowPrintObjects] = useState(false)
  const [hidePrintButton, setHidePrintButton] = useState(false)

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: `MyUnyte Dashboard _ MyUnyte - ${resource.title}`,
  })

  const setStateThenPrint = async () => {
    await setShowPrintObjects(true)
    await handlePrint()
    await setShowPrintObjects(false)
  }

  // apply the page style for the paper.
  const pageStyle = () => `
    @media print {
      html,
      body {
        height: initial !important;
        width: 1080px !important;
        overflow: initial !important;
        -webkit-print-color-adjust: exact;
        margin: 5mm !important;
        padding: 0 0 20px 0 !important;
      }
    }
  `

  const resourceComponentMap = {
    'titration-tool': (
      <div ref={componentRef}>
        <style>{pageStyle()}</style>
        <TitrationTool
          printLayoutEnabled={showPrintObjects}
          setHidePrintButton={setHidePrintButton}
        />
      </div>
    ),
    focusActivity: (
      <div ref={componentRef}>
        <style>{pageStyle()}</style>
        <FocusActivity name={resource.title} printLayoutEnabled={showPrintObjects} />
      </div>
    ),
    regulatingActivity: <RegulatingActivity resource={resource} />,
    'secure-storage-file': <SecureStorageFile resource={resource} height={height} />,
    error: null,
    default: (
      <iframe
        title="account video"
        src={resource.src}
        width="100%"
        // if its a video, dividing the height by half will push it to the top
        height={Math.round(height) / (resource.productCategory.includes('Video') ? 2 : 1)}
        frameBorder="0"
        allowFullScreen
      />
    ),
  }
  const ContentComponent =
    resourceComponentMap[resource.resourceType] || resourceComponentMap['default']

  return (
    <>
      <Grid container alignItems="center" className="pt-3 pb-2 pl-5">
        <Grid item xs={2}>
          <Button
            variant="outlined"
            startIcon={<ArrowBackIosIcon />}
            onClick={goBack}
            noWrap
            sx={{
              display: {
                xs: 'none',
                sm: 'inline-flex', // this is the default styling
              },
            }}
          >
            Back
          </Button>
          <IconButton
            aria-label="delete"
            sx={{
              display: {
                xs: 'inline-flex',
                sm: 'none',
              },
            }}
          >
            <ArrowBackIosIcon />
          </IconButton>
        </Grid>
        <Grid item xs align="center">
          <Typography variant="h5" className="text-gray">
            {resource.titleInHeader && resource.title}
          </Typography>
        </Grid>
        <Grid item xs={2}>
          {['focusActivity', 'titration-tool'].includes(resource.resourceType) && !hidePrintButton && (
            <Button className="m-1" color="primary" variant="contained" onClick={setStateThenPrint}>
              Print PDF
            </Button>
          )}
        </Grid>
      </Grid>
      {resource && !loading && (
        <Container
          sx={{
            height: 'calc( 100vh - 225px )',
            ...(['focusActivity', 'regulatingActivity'].includes(resource.resourceType) && {
              minWidth: '100%',
              justifyContent: 'space-between',
            }),
          }}
          ref={measuredRef}
        >
          <Stack spacing={4} direction="column">
            {resource.resourceType === 'link' && (
              <Typography variant="subtitle1" className="text-gray" align="center">
                If you are having trouble viewing this resource, please try accessing the resource{' '}
                <a
                  className="text-link underline cursor-pointer font-medium"
                  href={resource.src}
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  here
                </a>
                .
              </Typography>
            )}
            {ContentComponent}
            <RateResource resource={resource} />
          </Stack>
        </Container>
      )}
    </>
  )
}
