import React, { useState, useEffect } from 'react'

import { useDispatch, useSelector } from 'react-redux'
import { matchPath, useNavigate } from 'react-router'
import LoadingPage from 'components/LoadingPage'
import MainLayout from 'components/containers/main/Main'
import { get } from 'lodash'
import roles from 'utils/constants/roles'
import { Box, Stack, Typography, Card } from '@mui/material'
import { GET_ACTIVE_CONNECTEE_MATCH } from './shared/queries'
import { useQuery } from '@apollo/client'
import SentToProviderCards from './contents/SentToProviderCards'
import AcceptedCards from './contents/AcceptedCards'
import EngagedCards from './contents/EngagedCards'
import ExpiredCards from './contents/ExpiredCards'
import { useSnackbar } from 'notistack'
import CloseSnackbarAction from 'components/CloseSnackbarAction'
import { setFF } from 'store/modules/ff'
import {
  STATUSES_IN_PROGRESS,
  SENT_TO_PROVIDER,
  ACCEPTED,
  ENGAGED,
  EXPIRED,
} from './shared/constants'
import ClientConnectionsAvatar from 'components/avatar/ClientConnectionAvatar'

export default function ActiveConnection() {
  const [loading, setLoading] = useState(true)
  const [connecteeMatch, setConnecteeMatch] = useState()
  const tags = useSelector((state) => get(state, 'auth.user.tags', []))
  const currentUserRoles = useSelector((state) => get(state, 'auth.user.roles', []))
  const isProvider = currentUserRoles.some((role) => roles.PROVIDER_ROLES.includes(role))
  const navigate = useNavigate()
  const { enqueueSnackbar } = useSnackbar()
  const dispatch = useDispatch()
  const user = useSelector((state) => state?.auth?.user)
  const showExpiredConnecteeCards = user?.providerProfileInfo?.showExpiredConnecteeCards || false
  const openToNewClients = user?.providerProfileInfo?.openToNewClients

  useEffect(() => {
    if (
      !isProvider ||
      !openToNewClients ||
      !tags.some((tag) => tag.value === 'has_client_connection')
    ) {
      navigate('/')
    }
  }, [])

  useQuery(GET_ACTIVE_CONNECTEE_MATCH, {
    fetchPolicy: 'cache-and-network',
    variables: {
      sort: [['createdAt', 'DESC']],
      limit: 1,
      filter: {
        status: [...STATUSES_IN_PROGRESS, EXPIRED],
      },
    },
    onCompleted: (data) => {
      setConnecteeMatch(data.getConnecteeMatches[0])
      setLoading(false)
      const matchStatus = data.getConnecteeMatches[0]?.status
      const isMatchInProgress =
        STATUSES_IN_PROGRESS.includes(matchStatus) ||
        (matchStatus === EXPIRED && showExpiredConnecteeCards)
      dispatch(setFF({ showClientConnectionsBadge: isMatchInProgress }))
    },
    onError: (err) => {
      console.error(err)
      enqueueSnackbar('Failed to load client connections. Please try again', {
        variant: 'error',
        action: CloseSnackbarAction,
      })
      setLoading(false)
    },
  })

  return (
    <LoadingPage text="loading..." loading={loading}>
      <MainLayout
        title="Connections"
        tabs={[
          {
            text: 'Active Connections',
            url: '/connections/active',
            isActive: (pathname) => matchPath({ path: '/connections/active' }, pathname),
          },
          {
            text: 'History',
            url: '/connections/history',
            isActive: (pathname) => matchPath({ path: '/connections/history' }, pathname),
          },
        ]}
      >
        <Box p={2}>
          {!loading && connecteeMatch?.status === SENT_TO_PROVIDER && (
            <SentToProviderCards
              connecteeMatch={connecteeMatch}
              setConnecteeMatch={setConnecteeMatch}
              setLoading={setLoading}
            />
          )}
          {!loading && connecteeMatch?.status === ACCEPTED && (
            <AcceptedCards
              connecteeMatch={connecteeMatch}
              setConnecteeMatch={setConnecteeMatch}
              setLoading={setLoading}
            />
          )}
          {!loading && connecteeMatch?.status === ENGAGED && (
            <EngagedCards
              connecteeMatch={connecteeMatch}
              setConnecteeMatch={setConnecteeMatch}
              setLoading={setLoading}
            />
          )}
          {!loading && connecteeMatch?.status === EXPIRED && showExpiredConnecteeCards && (
            <ExpiredCards connecteeMatch={connecteeMatch} setLoading={setLoading} />
          )}
          {!loading &&
            (!connecteeMatch ||
              (connecteeMatch.status === EXPIRED && !showExpiredConnecteeCards)) && (
              <Card
                sx={{
                  margin: '8vh 12vw',
                  bgcolor: '#e9eff4',
                  borderRadius: '64px',
                  boxShadow: 'none',
                }}
              >
                <Stack direction="column" alignItems="center" spacing={2} py={4} px={10}>
                  <ClientConnectionsAvatar />
                  <Typography color="#3970ab" variant="h5" fontWeight={500} textAlign="center">
                    Stay tuned! We're working on connecting you with potential clients.
                  </Typography>
                  <Typography variant="subtitle1" textAlign="center">
                    We'll send you an email once we find a suitable client, and you can return here
                    to view their details and determine if you'd like to take them on.
                  </Typography>
                </Stack>
              </Card>
            )}
        </Box>
      </MainLayout>
    </LoadingPage>
  )
}
