import React, { useEffect, useState } from 'react'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import {
  Box,
  Chip,
  Divider,
  Stack,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Collapse,
  Typography,
  Table,
} from '@mui/material'
import { DATE_FORMATS } from 'utils/constants/formats'
import { format } from 'date-fns'
import ConnecteeSubmissionFields from './shared/ConnecteeSubmissionFields'
import ClientContactInfo from './shared/ClientContactInfo'
import { Done } from '@mui/icons-material'
import { useQuery } from '@apollo/client'
import { GET_CONNECTEE_MATCH_HISTORY } from './shared/queries'
import { useSnackbar } from 'notistack'
import CloseSnackbarAction from 'components/CloseSnackbarAction'
import LoadingPage from 'components/LoadingPage'
import MainLayout from 'components/containers/main/Main'
import { useSelector } from 'react-redux'
import roles from 'utils/constants/roles'
import { matchPath, useNavigate } from 'react-router'
import { get } from 'lodash'
import { STATUSES_FOR_HISTORY } from './shared/constants'

const LIMIT = 25 // quick fix until pagination is done
const DEFAULT_SORT = [['createdAt', 'DESC']]

function StatusChip({ status, filterStatuses, onClick, isTable }) {
  const statusColors = {
    Connected: '#6ee7b7',
    Declined: '#eb9486',
    Expired: '#ffac1c',
  }

  const getBackgroundColor = () => {
    if (filterStatuses?.length) {
      return filterStatuses.includes(status) ? statusColors[status] : '#fff'
    }

    return statusColors[status]
  }

  const isChecked = () => {
    if (filterStatuses?.length) {
      return filterStatuses.includes(status) ? <Done /> : null
    }
  }

  return (
    <Chip
      label={status}
      avatar={isChecked()}
      sx={{ m: 0.5, backgroundColor: getBackgroundColor() }}
      variant={isChecked() || isTable ? 'filled' : 'outlined'}
      onClick={onClick}
    />
  )
}

function formatName({ firstName, lastName }) {
  return firstName && lastName ? `${firstName} ${lastName}` : 'Info Not Available'
}

export default function ConnectionsHistory() {
  const [loading, setLoading] = useState(true)
  const [rows, setRows] = useState([])
  const [expandedRow, setExpandedRow] = useState(null)
  const [filterStatuses, setFilterStatuses] = useState(STATUSES_FOR_HISTORY)
  const [sort] = useState(DEFAULT_SORT)
  const { enqueueSnackbar } = useSnackbar()
  const tags = useSelector((state) => get(state, 'auth.user.tags', []))
  const currentUserRoles = useSelector((state) => get(state, 'auth.user.roles', []))
  const isProvider = currentUserRoles.some((role) => roles.PROVIDER_ROLES.includes(role))
  const navigate = useNavigate()
  const openToNewClients = useSelector(
    (state) => state.auth?.user?.providerProfileInfo?.openToNewClients
  )

  useEffect(() => {
    if (
      !isProvider ||
      !openToNewClients ||
      !tags.some((tag) => tag.value === 'has_client_connection')
    ) {
      navigate('/')
    }
  }, [])

  const { refetch } = useQuery(GET_CONNECTEE_MATCH_HISTORY, {
    fetchPolicy: 'cache-and-network',
    variables: {
      sort,
      limit: LIMIT,
      filter: {
        status: filterStatuses,
      },
    },
    onCompleted: (data) => {
      setRows(data.getConnecteeMatches)
      setLoading(false)
    },
    onError: (err) => {
      console.error(err)
      enqueueSnackbar('Failed to load client connections. Please try again', {
        variant: 'error',
        action: CloseSnackbarAction,
      })
      setLoading(false)
    },
  })

  const handleExpandClick = (id) => {
    setExpandedRow(expandedRow === id ? null : id)
  }

  const handleChipFilter = (status) => () => {
    const newFilterStatuses = filterStatuses.includes(status)
      ? filterStatuses.filter((s) => s !== status)
      : [...filterStatuses, status]

    setFilterStatuses(newFilterStatuses)
    setLoading(true)
    refetch()
  }

  return (
    <LoadingPage text="loading..." loading={loading}>
      <MainLayout
        title="Connections"
        tabs={[
          {
            text: 'Active Connections',
            url: '/connections/active',
            isActive: (pathname) => matchPath({ path: '/connections/active' }, pathname),
          },
          {
            text: 'History',
            url: '/connections/history',
            isActive: (pathname) => matchPath({ path: '/connections/history' }, pathname),
          },
        ]}
      >
        <Box p={2}>
          <Stack
            direction="row"
            divider={<Divider orientation="vertical" flexItem />}
            p={2}
            alignItems="center"
            justifyContent="flex-end"
          >
            <Typography variant="h6" className="mr-3">
              Filters
            </Typography>
            <Box className="ml-3">
              {STATUSES_FOR_HISTORY.map((status) => (
                <StatusChip
                  key={status}
                  status={status}
                  onClick={handleChipFilter(status)}
                  filterStatuses={filterStatuses}
                />
              ))}
            </Box>
          </Stack>
          {rows && (
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Name/ID</TableCell>
                    <TableCell>Status</TableCell>
                    <TableCell>Send Date</TableCell>
                    <TableCell />
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows.map((row) => (
                    <React.Fragment key={row.id}>
                      <TableRow>
                        <TableCell>
                          <Box>
                            <Typography>
                              <b>{formatName(row.connecteeSubmission)}</b>
                            </Typography>
                            <Typography>ID: {row.connecteeSubmission.uuid}</Typography>
                          </Box>
                        </TableCell>
                        <TableCell>
                          <StatusChip status={row.status} />
                        </TableCell>
                        <TableCell>
                          {format(new Date(row.createdAt), DATE_FORMATS.dateAndTime)}
                        </TableCell>
                        <TableCell>
                          <IconButton
                            aria-label="expand row"
                            size="small"
                            onClick={() => handleExpandClick(row.id)}
                          >
                            {expandedRow === row.id ? (
                              <KeyboardArrowUpIcon />
                            ) : (
                              <KeyboardArrowDownIcon />
                            )}
                          </IconButton>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={4}>
                          <Collapse in={expandedRow === row.id} timeout="auto" unmountOnExit>
                            {row.status === 'Connected' ? (
                              <Stack direction="row" justifyContent="space-around">
                                <Box p={2}>
                                  <ClientContactInfo
                                    connecteeSubmission={row.connecteeSubmission}
                                  />
                                </Box>
                                <Box p={2}>
                                  <ConnecteeSubmissionFields connecteeMatch={row} />
                                </Box>
                              </Stack>
                            ) : (
                              <Box p={2}>
                                <ConnecteeSubmissionFields connecteeMatch={row} />
                              </Box>
                            )}
                          </Collapse>
                        </TableCell>
                      </TableRow>
                    </React.Fragment>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </Box>
      </MainLayout>
    </LoadingPage>
  )
}
