import React, { useState } from 'react'
import { Button, Typography } from '@mui/material'
import { get } from 'lodash'
import ConfirmPopup from './confirmPopup'
import { useMutation } from '@apollo/client'
import { UNASSIGN_SLOT_FROM_USER } from 'views/users/clients/queries'
import { useSelector } from 'react-redux'
import { useOutletContext } from 'react-router'
import { maskValidationMessage } from 'utils/apollo/errors'

export default ({ user, updateUser, onUpdate }) => {
  const [pending, setPending] = useState(false)
  const [open, setOpen] = useState(false)
  const [unassignSlotFromUser] = useMutation(UNASSIGN_SLOT_FROM_USER)
  const { showNewSubscriptionPlan } = useSelector((state) => state.ff)
  const { refetchOne } = useOutletContext()

  if (!user.email) {
    return null
  }

  const [error, setError] = useState({ state: false, message: '' })

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const clientAssignedSlot = get(user, 'slots', []).filter((slot) => slot.status === 'assigned')
  const onSuspend = async () => {
    setPending(true)

    try {
      // suspend user
      const res = await updateUser({
        variables: { user: { id: user.id, isSuspended: !user.isSuspended } },
      })
      if (clientAssignedSlot.length) {
        for (const slot of clientAssignedSlot) {
          await unassignSlotFromUser({
            variables: {
              userId: user.id,
              productCategory: slot.category,
            },
          })
        }
      }

      await refetchOne({ filter: { ids: [user.id] } })

      // only close if successful
      if (res.data.updateUser.id) {
        setTimeout(async () => {
          handleClose()
        }, 3000)
      }
    } catch (errors) {
      const message = maskValidationMessage(errors, 'suspend')
      setError({ state: true, message })
    } finally {
      // stop loading in 3 seconds
      setTimeout(async () => {
        setPending(false)
      }, 3000)
    }
  }

  const loadingMessage = user.isSuspended ? 'unsuspending client...' : 'suspend client...'

  return (
    <>
      <Button
        className="m-1"
        data-test="suspend-client-button"
        color="secondary"
        loading={pending}
        onClick={user.roles.includes('client') && !user.isSuspended ? handleClickOpen : onSuspend}
      >
        {user.isSuspended
          ? user.roles.includes('client')
            ? 'Unsuspend Client'
            : 'unsuspend'
          : user.roles.includes('client')
          ? 'Suspend Client '
          : 'Suspend'}
      </Button>
      <ConfirmPopup
        title="Suspend this client?"
        body={
          <>
            <Typography variant="body2" gutterBottom>
              This will disable client access to MyUnyte and any programs. This client will not
              appear in your list of clients on mobile. You can restore access by unsuspending the
              client.
            </Typography>
            <Typography variant="body2" gutterBottom py={1}>
              Alternatively, you can disable program access via Manage{' '}
              {!showNewSubscriptionPlan && 'Remote'} Delivery.
            </Typography>
            {!!clientAssignedSlot.length && (
              <Typography variant="body2" gutterBottom py={1} fontWeight={500}>
                NOTE: This client has an assigned license which will be unassigned if suspended.
              </Typography>
            )}
          </>
        }
        buttonText="Yes, Suspend"
        open={open}
        onClose={handleClose}
        onConfirm={onSuspend}
        user={user}
        onUpdate={onUpdate}
        loading={pending}
        loadingMessage={loadingMessage}
        error={error}
      />
    </>
  )
}
