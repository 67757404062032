import React from 'react'
import { set } from 'utils'
import { Box, Divider, Grid, Stack, Typography } from 'components'
import { UsersSearch } from '../components/Search'
import { Chip, Tooltip } from '@mui/material'
import { Done } from '@mui/icons-material'
import { useSelector } from 'react-redux'

export default ({
  queryVars,
  setQueryVars,
  initialQuery,
  filterChips = [],
  filterState,
  setFilterState,
}) => {
  const { showNewSubscriptionPlan } = useSelector((state) => state.ff)
  const handleClick = (event) => {
    if (event.currentTarget?.dataset?.tag) {
      const newState = {
        ...filterState,
        [event.currentTarget.dataset.tag]: !filterState[event.currentTarget.dataset.tag],
      }

      const filter = filterChips
        .map(({ id, queryUp, queryDown }) =>
          newState[id] ? { id, query: queryUp } : { id, query: queryDown }
        )
        .reduce((accumulator, { id, query }) => {
          if (query) {
            if (['ssp', 'focus'].includes(id)) {
              const categories = [...accumulator.categories, query]
              return { ...accumulator, categories }
            } else {
              return { ...accumulator, ...query }
            }
          }
          return accumulator
        }, initialQuery)

      setFilterState(newState)
      setQueryVars({
        ...initialQuery,
        filter,
      })
    }
  }

  const isChipDisabled = (chipId) => {
    // since filters are AND condition, disable any combinations that are invalid
    return (
      (chipId === 'remote' && filterState['inPerson']) ||
      (chipId === 'inPerson' && filterState['remote']) ||
      (chipId === 'sspAssigned' && filterState['sspUse']) ||
      (chipId === 'sspUse' && filterState['sspAssigned']) ||
      (chipId === 'focusAssigned' && filterState['focusUse']) ||
      (chipId === 'focusUse' && filterState['focusAssigned'])
    )
  }

  return (
    <Grid container alignItems="center">
      <Grid item xs={12} sm={5} md={5} lg={5}>
        <UsersSearch
          onSearchChange={(val) => {
            setQueryVars(set(queryVars, 'filter.any', val))
          }}
          hideSwitch
          width="90%"
        />
      </Grid>
      <Grid container item xs={12} sm={7} md={7} lg={7} justifyContent="end">
        <Stack
          direction="row"
          divider={<Divider orientation="vertical" flexItem />}
          p={1}
          alignItems="center"
        >
          <Tooltip
            title="Click one or more filters to only view Clients with specified attributes"
            placement="top"
          >
            <Typography variant="h6" className="mr-3">
              Filters
            </Typography>
          </Tooltip>
          <Box className="ml-3">
            {filterChips.map((chip, index) => {
              const isChecked = filterState[chip.id]
              const chipColor = isChecked ? '#EEF7F3' : 'white'
              return (
                <Tooltip
                  title={chip.tooltipMessage({
                    showNewSubscriptionPlan,
                  })}
                  placement="top"
                  key={`${chip.id}${index}`}
                >
                  <Chip
                    data-tag={chip.id}
                    avatar={isChecked ? <Done /> : null}
                    label={chip.label({ showNewSubscriptionPlan })}
                    onClick={handleClick}
                    sx={{ backgroundColor: chipColor, m: 0.5 }}
                    variant="outlined"
                    disabled={isChipDisabled(chip.id)}
                  />
                </Tooltip>
              )
            })}
          </Box>
        </Stack>
      </Grid>
    </Grid>
  )
}
