/**
 * This component is only for Basic Info and was refactored out of wizard as there was a rerender bug when an error exists
 */

import React, { useContext } from 'react'
import { ProviderProfileContext, VariantWrapper } from '../MyProfile'
import { Grid, Typography } from '@mui/material'
import { isEmpty } from 'utils/isEmpty'
import { Link } from 'react-router-dom'
import { SingleAddressForm } from './Wizard'
import formatAddress from 'utils/formatAddress'
import { get } from 'lodash'

export default function BusinessAddress() {
  const { form, wizardState, setWizardState } = useContext(ProviderProfileContext)

  const isEditBasicInfo = wizardState?.includes('EDIT')
  const businessAddress = get(form, 'addresses[0]', {})
  const handleChangeBusinessAddress = () => {
    setWizardState('BASIC_INFO/EDIT_0')
  }

  const formattedBusinessAddress = formatAddress(businessAddress)
  return (
    <>
      {!isEditBasicInfo && (
        <VariantWrapper lastItem>
          <Grid container>
            <Grid item xs={12} sm={4}>
              <Typography sx={{ color: 'rgba(0, 0, 0, 0.42)' }} variant="body1">
                Business Address
              </Typography>
            </Grid>
            <Grid item xs={12} sm={8}>
              <Typography variant="body1" sx={{ color: 'gray !important' }}>
                {!isEmpty(businessAddress) && formattedBusinessAddress}
              </Typography>
              <Link to="/my/profile" onClick={handleChangeBusinessAddress}>
                <Typography variant="caption">
                  {!isEmpty(businessAddress) && formattedBusinessAddress ? 'Change' : 'Add'}{' '}
                  business address
                </Typography>
              </Link>
            </Grid>
          </Grid>
        </VariantWrapper>
      )}
      {isEditBasicInfo && (
        <>
          <Typography sx={{ color: 'rgba(0, 0, 0, 0.42)' }} variant="body1">
            Business Address
          </Typography>
          <SingleAddressForm openFromProps idx={0} address={businessAddress} />
        </>
      )}
    </>
  )
}
