import React, { useEffect, useState } from 'react'
import moment from 'moment'
import { useForm } from 'hooks'
import { useDispatch, useSelector } from 'react-redux'
import { CardContent, CardHeader, Grid, Button, TextField, FormHelperText } from 'components'
import makeStyles from '@mui/styles/makeStyles'
import Layout from './Layout'
import { createPurchase } from '../../../store/modules/new-purchase'
import GenerateContactInfoButton from '../../../components/testFeatures/GenerateContactInfoButton'
import useGTM from 'utils/hooks/useGTM'
import { PRODUCTS } from 'utils/constants/prices'
import useCart from '../utils/useCart'
import { get } from 'lodash'

const useStyles = makeStyles({
  cardHeader: {
    fontWeight: '500',
  },
})

export default ({ next, extendData }) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const seedDate = moment()

  // since this is now the first step, create user will always be empty
  const {
    adminFirstName,
    adminLastName,
    organizationName,
    adminEmail,
    onboardingProducts,
  } = useSelector((state) => state.newPurchase.data)
  const { discountItems } = useCart()

  const { form, setFormValue, isValid, errors } = useForm({
    data: {
      adminFirstName,
      adminLastName,
      organizationName,
      adminEmail,
      // clear customer data on edit
      stripeCustomerId: null,
      stripeClientSecret: null,
      uuid: null,
      stripePaymentMethodId: null,
    },
    validation: {
      adminFirstName: {
        required: { msg: 'First name is required.' },
      },
      adminLastName: {
        required: { msg: 'Last name is required.' },
      },
      adminEmail: {
        required: { msg: 'Admin email is required.' },
        email: { msg: 'Please enter a valid email address.' },
        len: { args: [null, 100], msg: 'Email must be no longer than 100 characters.' },
      },
    },
  })
  const [loading, setLoading] = useState(false)
  const [serviceError, setServiceError] = useState()

  const { BIData } = useSelector((state) => state.newPurchase.data)

  // on load: send virtual page load
  const { pageView, addToCart, beginCheckout } = useGTM()
  const onLoad = () => {
    if (onboardingProducts.length) {
      const items = onboardingProducts.map((sku, index) => ({
        item_id: PRODUCTS[sku].sku,
        item_name: PRODUCTS[sku].cartTitle,
        price: PRODUCTS[sku].price,
        item_category: PRODUCTS[sku].gtmCategory,
        item_brand: 'Unyte',
        quantity: 1,
        item_variant: PRODUCTS[sku].gtmVariant,
        discount: get(discountItems, index, 0) && discountItems[index].discountAmount,
      }))
      addToCart({
        items,
        value: items.reduce((sum, item) => sum + (item.price - item.discount), 0),
      })
    }
  }

  useEffect(() => {
    onLoad()
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    pageView({
      pageUrl: `${window.document.location.origin}${window.document.location.pathname}/create`,
      pageTitle: 'New Purchase - Enter contact info',
    })
    // eslint-disable-next-line
  }, [])

  // Create user
  const handlePurchaseService = async () => {
    setLoading(true)
    try {
      // if we have UUID (previous submit?), we can skip
      const newPurchase = {
        adminFirstName: form.adminFirstName,
        adminLastName: form.adminLastName,
        organizationName: form.organizationName,
        adminEmail: form.adminEmail,
      }
      await extendData({ ...newPurchase })
      const { error: createPurchaseError } = await dispatch(
        createPurchase({
          email: form.adminEmail,
          firstName: form.adminFirstName,
          lastName: form.adminLastName,
          organizationName: form.organizationName,
          BIData,
        })
      )
      await beginCheckout()
      if (!createPurchaseError) {
        await next()
      } else {
        setServiceError(createPurchaseError)
      }
    } catch (err) {
      console.error('create purchase handle purchase service error', err)
    } finally {
      setLoading(false)
    }
  }

  return (
    <Layout loading={loading} loadingText="initializing..." next={next}>
      <CardHeader
        title="1. Please enter your contact info"
        classes={{ title: classes.cardHeader }}
      />
      <CardContent>
        <TextField
          autoFocus={true}
          className="mb-5"
          label="First Name*"
          value={form.adminFirstName}
          error={errors.adminFirstName}
          onChange={setFormValue('adminFirstName')}
          name="first-name"
          data-test="first-name-field"
        />
        <TextField
          className="mb-5"
          label="Last Name*"
          value={form.adminLastName}
          error={errors.adminLastName}
          onChange={setFormValue('adminLastName')}
          name="last-name"
          data-test="last-name-field"
        />
        <TextField
          className="mb-5"
          label="Organization Name"
          value={form.organizationName}
          error={errors.organizationName}
          onChange={setFormValue('organizationName')}
          helperText="No organization name? Just use your First and Last Name"
          name="organization-name"
        />
        <TextField
          className="mb-5"
          label="Email*"
          value={form.adminEmail}
          error={errors.adminEmail}
          onChange={setFormValue('adminEmail')}
          name="email"
          data-test="email-field"
        />

        {errors.none && (
          <FormHelperText className="my-2" error={true}>
            {errors.none}
          </FormHelperText>
        )}
        {serviceError && (
          <FormHelperText className="my-2" error={true}>
            Sorry but we had an issue creating your payment method.
            <br />
            For reference: "{serviceError}"
            <br />
            Please try again or contact support if this issue persists.
          </FormHelperText>
        )}
        <Grid className="mt-5" container justifyContent="space-between">
          <GenerateContactInfoButton seedDate={seedDate} setFormValue={setFormValue} />
          <Button
            variant="contained"
            color="primary"
            disabled={!isValid}
            onClick={handlePurchaseService}
            data-test="confirm-contact-info"
          >
            Continue
          </Button>
        </Grid>
      </CardContent>
    </Layout>
  )
}
