/**
 * Skeleton:
 * 1. navbar
 * 2. select client
 * 3. TODO: demo
 *  - it will be a dialog to reuse previous component for now
 * 4. select assessments
 *  - we will not be reusing unyte table
 *
 * NOTE: we removed error and skip for now..
 * TODO: error message on submit demographic info
 * TODO TODAY:
 * - on save/ send exit, load new view with just clientID
 */
import React, { createContext, useContext, useEffect, useRef, useState } from 'react'
import {
  Box,
  Button,
  Collapse,
  FormControl,
  FormControlLabel,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Stack,
  Step,
  StepContent,
  StepLabel,
  Stepper,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material'
import { Link, useNavigate, useOutletContext } from 'react-router-dom'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import { useDispatch, useSelector } from 'react-redux'
import { get } from 'lodash'

// icons
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted'
import GroupsIcon from '@mui/icons-material/Groups'
import AccessTimeIcon from '@mui/icons-material/AccessTime'
import {
  ApolloCache,
  DefaultContext,
  MutationFunctionOptions,
  OperationVariables,
  useMutation,
} from '@apollo/client'
import {
  setDemographicInfo,
  setOpenSelect,
  setUser,
  setUpdateUserData,
} from 'store/modules/assessments'
import useGetAssessmentId from './utils/useGetAssessmentId'
import { ApolloQueryResult } from '@apollo/client'
import { ASSESSMENT_STATUS } from 'utils/constants/assessmentStatus'
import { CREATE_SESSION, UPDATE_DEMOGRAPHIC_INFO } from './constants/gql'
import { useSnackbar } from 'notistack'
import { ACTIONS } from './utils/constants'
import CloseSnackbarAction from 'components/CloseSnackbarAction'
import { AnimatedLeafLogoStartIcon } from 'components/PageLoader'
import DemographicInfoStep from './components/demographic-info-step'
import { AssessmentBackButton } from './components/assessment-back-button'

// Define the context type
type SessionContextType = {
  createSession: (
    options?: MutationFunctionOptions<any, OperationVariables, DefaultContext, ApolloCache<any>>
  ) => Promise<any>
  form: formProps
  setLoading: (loadingType: string, loadingState: boolean) => void // Add setLoading function type
  setShowError: (showErrorState: boolean) => void // Add setLoading function type
  refetch: any
} | null // Include `null` if you intend to provide a real value later through a Provider

// Create the context with an initial value that matches the type
const SelectAssessmentContext = createContext<SessionContextType>(null)

// Define the expected data structure returned by your query
interface QueryData {
  data: {
    getUsers: any[]
  }
}

interface QueryVariables {
  filter?: {
    anyRoles?: string
    ids?: number[]
  }
}

// Define the type for the refetch function
type RefetchFunction = (
  variables?: Partial<QueryVariables>
) => Promise<ApolloQueryResult<QueryData>>

// Define the interface for your outlet context
interface OutletContextType {
  getUsers: RefetchFunction
  refetch: any // TODO: put correct type
  onLoadKPI: any
}

interface GetResourceLinkProps {
  title?: string
  id?: string
}
export function getResourceLink({ title, id }: GetResourceLinkProps) {
  if (!title || !id) {
    return '/resources'
  }

  const formattedTitle = title
    .trim()
    .replace(/ /g, '-')
    .replace(/\//g, '-')

  return `/resources/${formattedTitle}/${id}`
}

// do not delete old values here
export const filterForm = (form) => {
  const newForm = Object.keys(form).reduce(
    (accumulator, k) => (form[k] ? { ...accumulator, [k]: form[k] } : { ...accumulator }),
    {}
  )
  return newForm
}

const CustomPaper = ({ ...props }) => <Paper elevation={0} variant="outlined" {...props} />

function Row({
  metadata,
  id: productEventId,
  onPostProgramSendOrSave,
  assessmentProductId,
  isLastRow,
}) {
  const [open, setOpen] = useState(false)
  const [checked, setChecked] = useState(false)
  const scrollToBottomRef = useRef<null | HTMLDivElement>(null)

  const navigate = useNavigate()
  const { enqueueSnackbar } = useSnackbar()

  const email = useSelector((state) => get(state, 'assessments.email', null))
  const userId = useSelector((state) => get(state, 'assessments.userId', null))
  const isBlindingOrganization = useSelector((state) =>
    get(state, 'auth.user.organization.settings.isBlinding', false)
  )

  const isDisablePostProgram =
    !email || isBlindingOrganization || metadata?.isIntakeForm || metadata?.isAceAssessment

  // initialize with select rows checked
  useEffect(() => {
    setChecked(
      !!email && !isBlindingOrganization && !metadata?.isIntakeForm && !metadata?.isAceAssessment
    )
  }, [userId, email])

  const resourceLink = getResourceLink({
    title: metadata?.assessmentType,
    id: metadata?.resourceId,
  })

  // get data from provider
  const selectAssessmentContext = useContext(SelectAssessmentContext)

  useEffect(() => {
    // Only scroll into view if this is the last row and it has been opened
    if (isLastRow && open) {
      scrollToBottomRef.current?.scrollIntoView({ behavior: 'smooth' })
    }
  }, [open, isLastRow])

  if (selectAssessmentContext === null) {
    return null
  }

  const {
    createSession,
    setLoading,
    setShowError,
    form,
    refetch = () => null,
  } = selectAssessmentContext

  const handleChange = (event) => {
    setChecked(event.target.checked)
  }

  /**
   * After sending, reload assessment table
   */
  const handleSend = async () => {
    if (form.loading.send || form.loading.start) {
      return
    }
    try {
      setLoading('send', true)
      if (!productEventId || !userId) {
        // throw error message here or something..
        return
      }
      onPostProgramSendOrSave({ productEventId, userId, checked })
      const status = ASSESSMENT_STATUS.SENT_TO_CLIENT
      const data = { status, currentPageNo: 0 }
      const session = {
        data: JSON.stringify(data),
        productId: assessmentProductId,
        productEventId,
        startedAt: new Date().toISOString(),
        type: 'answers',
        userId: parseInt(userId),
        metadata: { userAgent: navigator.userAgent },
      }
      await createSession({ variables: { session } })
      await refetch()
      enqueueSnackbar(ACTIONS[status].message, {
        variant: 'success',
        autoHideDuration: 10000,
        action: CloseSnackbarAction,
      })
      setOpen(false)
    } catch (error) {
      console.error(error)
      enqueueSnackbar('Something went wrong - please try again later', {
        variant: 'error',
        autoHideDuration: 10000,
        action: CloseSnackbarAction,
      })
    } finally {
      setLoading('send', false)
    }
  }

  const handleStart = async () => {
    try {
      if (form.loading.send || form.loading.start) {
        return
      } else if (!productEventId || !userId) {
        // throw error message here or something..
        setShowError(true)
        return
      }
      setLoading('start', true)
      onPostProgramSendOrSave({ productEventId, userId, checked })
      await navigate(`/assessments/create/${userId}/${productEventId}`)
    } catch (error) {
      console.error(error)
      enqueueSnackbar('Something went wrong - please try again later', {
        variant: 'error',
        autoHideDuration: 10000,
        action: CloseSnackbarAction,
      })
    } finally {
      setLoading('start', false)
    }
  }

  const handleOpenCollapse = (event) => {
    // Prevent click event from propagating to the parent TableRow
    event.stopPropagation()
    if (!userId || form.error.show) {
      // show error here
      setShowError(true)
    } else {
      setOpen(!open)
    }
  }

  const handleTableRowClick = (event) => {
    event.stopPropagation()
    if (!userId || form.error.show) {
      // show error here
      setShowError(true)
    } else {
      setOpen(!open)
    }
  }

  const assessmentNameMap = {
    'Child and Adolescent Trauma Screen (CATS) (Child)':
      'Child and Adolescent Trauma Screen (CATS)',
    'Child and Adolescent Trauma Screen (CATS) (Adolescent)':
      'Child and Adolescent Trauma Screen (CATS)',
    'Unyte SSP Intake Form (Adult)': 'Unyte SSP Intake Form',
    'Unyte SSP Intake Form (Child)': 'Unyte SSP Intake Form',
  }

  return (
    <>
      <TableRow
        hover
        selected={open}
        onClick={handleTableRowClick}
        sx={{
          '& > *': {
            // important is used here to overidde default tablecell CSS
            borderBottom: 'unset !important',
            borderTop: 'unset',
          },
          cursor: 'pointer',
        }}
      >
        <TableCell>
          {/* only intake forms don't have construct */}
          <strong>
            <span style={{ color: 'var(--gray-800)' }}>{metadata?.construct || 'Intake'}</span>
          </strong>
          <br />
          <span
            style={{
              color: 'var(--gray-600)',
            }}
          >
            {assessmentNameMap[metadata?.assessmentType] || metadata?.assessmentType}
          </span>
        </TableCell>
        <TableCell align="left">
          <Stack
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            spacing={1}
            sx={{
              color: 'var(--gray-600)',
              '& > *': {
                color: 'var(--gray-600)',
              },
            }}
          >
            <GroupsIcon fontSize="small" />
            <Typography variant="body2" component="p" color="inherit">
              Ages {metadata?.population}
            </Typography>
          </Stack>
        </TableCell>
        <TableCell align="left">
          <Stack
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            spacing={1}
            sx={{
              color: 'var(--gray-600)',
              '& > *': {
                color: 'var(--gray-600)',
              },
            }}
          >
            <FormatListBulletedIcon fontSize="small" />
            <Typography variant="body2" component="p" color="inherit">
              {metadata?.numberOfItems} Items
            </Typography>
          </Stack>
        </TableCell>

        <TableCell align="left">
          <Stack
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            spacing={1}
            sx={{
              color: 'var(--gray-600)',
              '& > *': {
                color: 'var(--gray-600)',
              },
            }}
          >
            <AccessTimeIcon fontSize="small" sx={{ color: 'var(--gray-600)' }} />
            <Typography variant="body2" component="p" color="inherit">
              {metadata?.timeToComplete}
            </Typography>
          </Stack>
        </TableCell>
        <TableCell align="left">
          <Link
            to={resourceLink}
            style={{
              color: 'var(--gray-600)',
            }}
          >
            Learn More
          </Link>
        </TableCell>
        <TableCell align="center">
          <IconButton aria-label="expand row" size="small" onClick={handleOpenCollapse}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
      </TableRow>
      <TableRow
        selected={open}
        sx={{
          '& > *': { borderTop: 'unset' },
          height: isLastRow && open ? '100px' : 'inherit',
        }}
      >
        <TableCell sx={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            {!isDisablePostProgram && (
              <FormControl component="fieldset" variant="standard" disabled={isDisablePostProgram}>
                <FormControlLabel
                  control={
                    <Switch
                      checked={checked}
                      onChange={handleChange}
                      inputProps={{ 'aria-label': 'controlled' }}
                    />
                  }
                  label={
                    <Typography variant="body2">
                      <strong>Post-Program Assessment: </strong>
                      Automatically resend this assessment after completion of Hour 5 of SSP Core
                    </Typography>
                  }
                />
              </FormControl>
            )}
            <Stack direction="row" py={2} spacing={1}>
              <Tooltip title={!email && 'Client does not have a MyUnyte Account'}>
                <Box>
                  <span />
                  <Button
                    variant="contained"
                    disabled={!email}
                    onClick={handleSend}
                    startIcon={
                      form.loading.send && email && <AnimatedLeafLogoStartIcon fill="#fff" />
                    }
                  >
                    {form.loading.send ? 'Loading..' : 'Send to Client'}
                  </Button>
                </Box>
              </Tooltip>
              <Box>
                <Button
                  variant="contained"
                  onClick={handleStart}
                  startIcon={form.loading.start && <AnimatedLeafLogoStartIcon fill="#fff" />}
                >
                  {form.loading.start ? 'Loading..' : 'Begin Assessment'}
                </Button>
              </Box>
            </Stack>
          </Collapse>
        </TableCell>
      </TableRow>
      {isLastRow && open && <div ref={scrollToBottomRef}></div>}
    </>
  )
}

interface formProps {
  clientId: string | undefined
  loading: {
    send: boolean
    start: boolean
    demographicInfo: boolean
  }
  error: {
    show: boolean
    message: string
  }
}

export default function SelectAssessmentPage() {
  // initialize hooks
  const dispatch = useDispatch()
  const theme = useTheme()

  const [updateDemographicInfo] = useMutation(UPDATE_DEMOGRAPHIC_INFO)

  const { assessmentProductId } = useGetAssessmentId()
  const { getUsers, onLoadKPI, refetch } = useOutletContext() as OutletContextType
  const [showDemograhicInfo, setShowDemographicInfo] = useState(false)

  // useState
  const [form, setForm] = useState<formProps>({
    clientId: '',
    loading: { send: false, start: false, demographicInfo: false },
    error: {
      show: false,
      message: '',
    },
  })
  const setShowError = (showErrorState) =>
    setForm({ ...form, error: { show: showErrorState, message: '' } })

  // useSelector
  const clientOptions = useSelector((state) => get(state, 'assessments.userData', []))
  const assessments = useSelector((state) => get(state, 'assessments', {}))
  //   we want to filter out disabled assessmentOptions
  const assessmentOptions = useSelector((state) =>
    get(state, 'assessments.productAssessments', [])
  ).filter(({ metadata }) => !get(metadata, 'isInactive', false))

  const { productAssessments, products, email } = assessments
  const assessmentUserId = get(assessments, 'id', form.clientId)

  // HANDLERS
  const handleAssessmentUserIdChange = (userId) => {
    /**
     * 1. find user
     * 2. optional: open demographic info
     */

    // find user
    const user = clientOptions.find(({ id }) => id === userId)
    if (user) {
      dispatch(
        setUser({
          ...user,
          userId: user.id,
        })
      )
      setForm({ ...form, clientId: user.id, error: { show: false, message: '' } })
      const formSkipAssessmentDemographicsRequest = get(
        user,
        `productPreferences.${assessmentProductId}.skipAssessmentDemographicsRequest`,
        false
      )

      // open demographic info
      if (
        !formSkipAssessmentDemographicsRequest &&
        (!user.birthYear || !user.gender || !user.country)
      ) {
        setShowDemographicInfo(true)
      } else {
        setShowDemographicInfo(false)
      }
      setActiveStep(1)
    }
  }

  const handleSaveDemoGraphic = ({
    country,
    gender,
    birthYear,
    productPreferences,
  }) => async () => {
    setForm({ ...form, loading: { ...form.loading, demographicInfo: true } })
    const getSkipAssessmentDemographicRequest = get(
      productPreferences,
      `${assessmentProductId}.skipAssessmentDemographicsRequest`,
      false
    )

    // if form is not filled show error message instead
    if (!(country && birthYear && gender) && !getSkipAssessmentDemographicRequest) {
      setForm({ ...form, error: { show: true, message: '' } })
      return
    }

    try {
      const newForm = filterForm({
        country,
        gender,
        birthYear,
        id: assessmentUserId,
        productPreferences,
      })

      await updateDemographicInfo({
        variables: {
          user: newForm,
        },
      })

      // if there are no errors go to the next step
      await dispatch(setDemographicInfo({ country, gender, birthYear, productPreferences }))
      await dispatch(setUpdateUserData(newForm))
      setForm({ ...form, error: { show: false, message: '' } })
      setActiveStep(2)
    } catch (error) {
      console.error(error)
      const errorMessage = (error as Error).message
      setForm({ ...form, error: { show: true, message: errorMessage } })
    } finally {
      setForm({ ...form, loading: { ...form.loading, demographicInfo: false } })
    }
  }

  const handleCancelDemographicInfo = () => {
    setForm({
      clientId: '',
      loading: { send: false, start: false, demographicInfo: false },
      error: { show: false, message: '' },
    })
    setActiveStep(0)
  }

  /**
   *
   * SentPostProgramAssessmentProductEventId is an array
   *
   * Note: we don't send Post Program Assessment to Intake Forms
   *
   * SEND POST ASSESSMENT EMAIL
   * 1. get product id from `productInternalName`
   * 2. update productPreference object
   * 3. update user
   *
   */
  const isBlindingOrganization = useSelector((state) =>
    get(state, 'auth.user.organization.settings.isBlinding', false)
  )
  const onPostProgramSendOrSave = async ({ productEventId, userId, checked }) => {
    // don't run this function if the user does not need to send post assessment email
    const metadata = productAssessments.find(({ id }) => id === productEventId)?.metadata
    const isDisablePostProgram =
      !email || isBlindingOrganization || metadata?.isIntakeForm || metadata?.isAceAssessment

    // don't update post assessment fields
    if (isDisablePostProgram) {
      return
    }

    try {
      // 1. get products id from productInternalName; using reduce because we want a `flatmap`
      const sspProductIds = products?.reduce((a, c) => {
        if (
          ['ssp_adult', 'ssp_child', 'ssp_core_classical', 'ssp_core_freely'].includes(
            c?.metadata?.productInternalName
          )
        ) {
          a.push(c.id)
        }
        return a
      }, [])

      const { data } = await getUsers({ filter: { ids: [userId] } })
      const prevProductPreference = get(data, 'getUsers[0].productPreferences', {})

      const newProductPreferences = sspProductIds?.reduce(
        (productPreferences, id) => {
          const sendPostProgramAssessmentArray = get(
            productPreferences,
            `${id}.sendPostProgramAssessmentProductEventId`,
            []
          )
          const sendPostProgramAssessmentSet = new Set(sendPostProgramAssessmentArray)

          // we use Set here to avoid duplicates
          if (checked) {
            sendPostProgramAssessmentSet.add(productEventId)
          } else {
            sendPostProgramAssessmentSet.delete(productEventId)
          }

          return {
            ...productPreferences,
            [id]: {
              ...productPreferences[id],
              sendPostProgramAssessmentProductEventId: [...sendPostProgramAssessmentSet],
            },
          }
        },
        { ...prevProductPreference }
      )
      // update user
      await updateDemographicInfo({
        variables: {
          user: { productPreferences: newProductPreferences, id: userId },
        },
      })
      // update currently selected User in redux in case we want to edit contact again
      await dispatch(setUser({ ...assessments, productPreferences: newProductPreferences }))

      // restart state and close
      await dispatch(setOpenSelect(false))

      // update all user in assessments sessions in case we switch users
      await getUsers({ filter: { anyRoles: 'client' } })
    } catch (error) {
      console.error(error)
      const errorMessage = (error as Error).message
      setForm({ ...form, error: { show: true, message: errorMessage } })
    }
  }

  const [createSession] = useMutation(CREATE_SESSION, {
    onCompleted: () => {
      onLoadKPI()
    },
  })
  const [activeStep, setActiveStep] = useState(0)

  return (
    <SelectAssessmentContext.Provider
      value={{
        createSession,
        form,
        setLoading: (loadingType, loadingState) =>
          setForm({ ...form, loading: { ...form.loading, [loadingType]: loadingState } }),
        setShowError,
        refetch,
      }}
    >
      <AssessmentBackButton />
      <Stepper
        activeStep={activeStep}
        orientation="vertical"
        sx={{
          '& strong': {
            fontWeight: 500,
          },
          paddingLeft: 4,
        }}
      >
        <Step
          key="step-select-client"
          active
          sx={{
            maxWidth: theme.breakpoints.values.sm,
          }}
        >
          <StepLabel
            optional={<Typography variant="caption">Select a client to get started</Typography>}
          >
            <Typography variant="h5" component="h5">
              Select Client
            </Typography>
          </StepLabel>
          <StepContent>
            <FormControl color="primary" sx={{ width: '100%' }}>
              <InputLabel id="demo-simple-select-label" variant="outlined">
                Select Client
              </InputLabel>
              <Select
                labelId="select-client-label"
                id="select-client-label"
                value={form.clientId}
                label="Select Client"
                variant="outlined"
              >
                {clientOptions.map(({ id, fullName, email }, index) => (
                  <MenuItem
                    data-test={`header-select-client${index}`}
                    key={`client-id-${id}`}
                    value={id}
                    onClick={() => handleAssessmentUserIdChange(id)}
                  >
                    {fullName}
                    {email && <> ({email})</>}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </StepContent>
        </Step>
        {showDemograhicInfo && (
          <Step key="step-demographic-info" sx={{ maxWidth: theme.breakpoints.values.md }}>
            <StepLabel
              optional={<Typography variant="caption">Confirm Client Information</Typography>}
            >
              <Typography variant="h5" component="h5">
                Demographic Info
              </Typography>
            </StepLabel>
            <DemographicInfoStep
              showError={form.error.show}
              onSave={handleSaveDemoGraphic}
              onCancel={handleCancelDemographicInfo}
              errorMessage={form.error.message}
              loading={form.loading.demographicInfo}
            />
          </Step>
        )}
        <Step key="step-select-assessment">
          <StepLabel
            optional={
              <Typography variant="caption">Select an assessment to send or start</Typography>
            }
          >
            <Typography variant="h5" component="h5">
              Select Assessment
            </Typography>
          </StepLabel>
          <StepContent>
            <TableContainer component={CustomPaper}>
              <Table aria-label="collapsible table">
                <TableHead>
                  <TableRow>
                    <TableCell>Assessment</TableCell>
                    <TableCell>Population</TableCell>
                    <TableCell># of Items</TableCell>
                    <TableCell>Estimated Time to Complete</TableCell>
                    <TableCell />
                    <TableCell />
                  </TableRow>
                </TableHead>
                <TableBody>
                  {assessmentOptions
                    .sort((a, b) =>
                      (
                        a.metadata?.construct || (a.metadata?.isIntakeForm ? 'Intake' : '')
                      ).localeCompare(
                        b.metadata?.construct || (b.metadata?.isIntakeForm ? 'Intake' : '')
                      )
                    )
                    .map(({ id, metadata }, index) => (
                      <Row
                        key={`assessments-${id}`}
                        id={id}
                        metadata={metadata}
                        onPostProgramSendOrSave={onPostProgramSendOrSave}
                        assessmentProductId={assessmentProductId}
                        isLastRow={index === assessmentOptions.length - 1}
                      />
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
          </StepContent>
        </Step>
      </Stepper>
    </SelectAssessmentContext.Provider>
  )
}
