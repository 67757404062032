import { get } from 'lodash'
import { useSelector } from 'react-redux'
import { ASSESSMENT_STATUS } from 'utils/constants/assessmentStatus'
import { parseMetadata } from './parse-metadata'

/**
 * This function is called at the beginning of loading assessments
 * and it will load all assessments
 */
export default function useGetTransformedClientData() {
  const productEvents = useSelector((state) => state.assessments.productAssessments)
  const sessionsData = useSelector((state) => state.assessments.sessions)

  const filterUserId = useSelector((state) => get(state, 'assessments.filter.userId', null))
  const filterStatus = useSelector((state) => get(state, 'assessments.filter.status', null))
  const filterProductEventId = useSelector((state) =>
    get(state, 'assessments.filter.productEventId', null)
  )
  const filterCreatedDate = useSelector((state) => get(state, 'assessments.filter.createdAt', null))
  const filterUpdatedDate = useSelector((state) => get(state, 'assessments.filter.updatedAt', null))

  const transformSessionData = (data) => {
    return data.map(({ id, type, productEventId, user, createdAt, data, updatedAt }) => {
      //get number of questions for survey

      const productEvent = productEvents.find(({ id }) => id === productEventId)
      const metadata = get(productEvent, 'metadata', {})
      const survey = get(productEvent, 'metadata.survey.pages', [])
      const productName = get(productEvent, 'metadata.assessmentType', [])
      const shortTitle = get(productEvent, 'metadata.shortTitle', [])
      const populationLabels = get(productEvent, 'metadata.populationLabels', [])
      const numberOfQuestions = survey?.reduce(
        (accumulator, page) => accumulator + page.elements.length,
        0
      )
      const answers = get(data, 'answers', {})
      const numberOfAnsweredQuestions = Object.keys(answers).length
      const status = data.status

      return {
        user,
        id,
        userId: user.id,
        progress: Math.min(Math.round((numberOfAnsweredQuestions * 100) / numberOfQuestions), 100),
        metadata,
        productName,
        type,
        createdAt,
        data,
        updatedAt,
        status,
        fullName: user.fullName,
        productEventId,
        sortAssessmentName: `${shortTitle}${populationLabels}${user.id}`,
      }
    })
  }

  // get and filter out intake forms
  const transformedData = productEvents.length ? transformSessionData(sessionsData) : []
  const assessmentNames = productEvents.map(({ metadata }) => {
    const { variant, assessmentType } = metadata
    return variant ? `${assessmentType} - ${variant}` : assessmentType
  })

  const filteredData = transformedData
    ?.filter(({ userId }) => userId === filterUserId || !filterUserId)
    .filter(
      ({ productEventId }) => !filterProductEventId || productEventId === filterProductEventId
    )
    // placeholder to filter for status
    .filter(
      ({ data }) =>
        !filterStatus ||
        data?.status === filterStatus ||
        (data?.status.includes('In Progress') && filterStatus === 'In Progress')
    )
    .filter(
      ({ createdAt }) =>
        !filterCreatedDate?.start ||
        (Date.parse(createdAt) >= filterCreatedDate.start &&
          Date.parse(createdAt) <= filterCreatedDate.end)
    )
    .filter(
      ({ updatedAt }) =>
        !filterUpdatedDate?.start ||
        (Date.parse(updatedAt) >= filterUpdatedDate.start &&
          Date.parse(updatedAt) <= filterUpdatedDate.end)
    )

  const filteredCompletedData = filteredData.filter(({ status }) =>
    ASSESSMENT_STATUS.isCompleted(status)
  )
  const filterIntakeFormData = filteredData.filter(
    ({ metadata }) => !get(metadata, 'isIntakeForm', false)
  )

  const getLinePlot = (filteredData, metadata) => {
    const {
      subScales,
      subScaleOrder,
      variant,
      assessmentName,
      dataMax,
      meanMax,
      isCATS,
      isAce,
      isBBCSS,
      catsScoring,
    } = parseMetadata(metadata)
    const searchTerm = `${assessmentName}${variant ? ` - ${variant}` : ''}`
    const totalName = `Total for ${searchTerm}`
    const totalScoreName = !subScales ? searchTerm : totalName

    const processedData = filteredData.map((data) => {
      const answers = data.data?.answers
      let subTotal = !isCATS
        ? Object.values(answers)
            .map(parseFloat)
            .reduce((sum, val) => sum + (!isNaN(val) ? val : 0), 0)
        : Object.entries(answers).reduce((acc, curr) => {
            if (catsScoring.includes(curr[0])) {
              return acc + curr[1]
            }
            return acc
          }, 0)

      // customization of ACE assessment questions to exclude from the rating system
      subTotal = subTotal % 10000

      const newScale = {}
      if (subScales && isBBCSS) {
        Object.entries(subScales).forEach(([scale, questions]) => {
          const nonZeroQuestions = questions.filter((question) =>
            get(data, `data.answers.${question}`, 0)
          )
          const subTotal =
            nonZeroQuestions.reduce(
              (sum, question) => sum + get(data, `data.answers.${question}`, 0),
              0.0
            ) / nonZeroQuestions.length

          // customization of ACE assessment questions to exclude from the rating system
          newScale[scale] = subTotal
        })
      } else if (subScales && !isBBCSS) {
        Object.entries(subScales).forEach(([scale, questions]) => {
          /**
           * 1. filter out 0, because not applicable should not be counted
           * 2. calculate mean score
           * 3. transform score (eg Ace)
           */

          const subTotal = questions.reduce(
            (sum, question) => sum + get(data, `data.answers.${question}`, 0),
            0.0
          )
          if (isAce) {
            newScale[scale] = subTotal % 10000
          }
          newScale[scale] = subTotal
        })
      }

      return {
        ...newScale,
        [totalScoreName]: subTotal,
        x: Date.parse(data.updatedAt),
        fullName: data.fullName,
        productName: data.productName,
        subScaleOrder: [...subScaleOrder],
        isBBCSS,
        meanMax,
      }
    })

    return {
      data: processedData,
      dataMax,
      scales: !subScales ? [searchTerm] : [...Object.keys(subScales)],
      subScaleOrder: [...subScaleOrder],
      isBBCSS,
      meanMax,
    }
  }

  /* using a forEach here is cleaner
   * @params  data       list of completed assessments
   * @returns [ output ] format: [{date, [..assessmentVariant]: count}, ..]
   */
  const getStackedBarChart = (data) => {
    const output = {}
    const bars = new Set()

    // we always want length to be 10 or more -- add ghost bars
    const ghostBarCount = data?.length ? 30 - data.length : 0

    data.reverse().forEach((row) => {
      const date = new Date(row.updatedAt)
      date.setMilliseconds(0)
      date.setMinutes(0)
      date.setSeconds(0)
      date.setHours(0)
      const assessmentVariant = `${row.productName}${
        row.metadata?.variant ? ` - ${row.metadata.variant}` : ''
      }`
      const getDate = get(output, date, {})
      output[date] = {
        ...getDate,
        [assessmentVariant]: get(output, `${date}.${assessmentVariant}`, 0) + 1,
        [`${row.userId}|${row.fullName}|${row.email}`]: get(output, `${date}.${row.userId}`, 0) + 1,
      }
      bars.add(assessmentVariant)
    })

    // add ghost bars
    const ghostBars = Array.from({ length: ghostBarCount }, (_, index) => {
      const date = new Date(data.length && data[data.length - 1].updatedAt)
      date.setMilliseconds(0)
      date.setMinutes(0)
      date.setSeconds(0)
      date.setHours(0)
      date.setDate(date.getDate() + index + 1)

      return { date }
    })

    // return relevent data
    return {
      data: [...Object.entries(output).map(([key, val]) => ({ date: key, ...val })), ...ghostBars],
      bars: Array.from(bars),
    }
  }

  const getAssessmentData = ({ sessionId, productEventId }) => {
    // get data
    const data = sessionsData.find(({ id }) => sessionId === id)
    const sessionData = get(data, 'data', {})
    const sessionProductEventId = get(data, 'productEventId', productEventId)

    // get survey data
    const productEvent = productEvents.find(({ id }) => sessionProductEventId === id)

    const survey = get(productEvent, 'metadata.survey', {})
    const isIntakeForm = get(productEvent, 'metadata.isIntakeForm', false)
    const productId = get(productEvent, 'productId', null)
    const variant = get(productEvent, 'metadata.variant', null)
    const assessmentType = get(productEvent, 'metadata.assessmentType', null)
    const title = variant ? `${assessmentType} - ${variant}` : assessmentType

    // modify the title ( we have our own header)
    const newSurvey = {
      ...survey,
      title: '',
    }
    const updatedAt = get(data, 'updatedAt', undefined)

    return { survey: newSurvey, title, sessionData, isIntakeForm, updatedAt, productId }
  }

  return {
    data: transformedData,
    filteredData,
    filteredCompletedData,
    filterIntakeFormData,
    assessmentNames,
    getLinePlot: (_data, metadata) => getLinePlot(_data, metadata),
    getStackedBarChart: () => getStackedBarChart(filteredCompletedData),
    getAssessmentData: ({ sessionId, productEventId }) =>
      getAssessmentData({ sessionId, productEventId }),
    transformSessionData,
  }
}
