import React from 'react'
import { Button, Container, Grid, Stack, Typography } from '@mui/material'
import { useSelector } from 'react-redux'
import { get } from 'lodash'
import { useOutletContext } from 'react-router'

import { AcademyContextType } from './academy-layout'
import { CertificationCard } from './(components)/certification-card'
import roles from 'utils/constants/roles'

export default function LiveEvents() {
  // grab state from outlet
  const {
    hasSspCertification,
    hasCompletedSspCertification,
    hasCompletedFocusCertification,
  } = useOutletContext<AcademyContextType>()

  // grab state from redux
  const user = useSelector((state) => get(state, 'auth.user', {}))

  // parse state
  const isProvider = roles.PROVIDER_ROLES.some((role) => user.roles.includes(role))
  const hasCompletedCertification = hasCompletedSspCertification || hasCompletedFocusCertification

  // output
  return (
    <Stack direction="column" spacing={2} px={2}>
      <Typography variant="h4" component="h5" px={4} pt={2}>
        Continue Your Learning
      </Typography>
      <Container maxWidth="lg">
        <Grid container direction="row" spacing={2}>
          {hasSspCertification && isProvider && (
            <CertificationCard
              title="Professional Events"
              content="Come learn from Dr. Porges, Unyte providers, and other thought leaders as we explore how Polyvagal Theory and Unyte products have evolved their approach and understanding."
            >
              <Button
                variant="contained"
                sx={{
                  marginX: 'auto',
                }}
                href="https://integratedlistening.com/events/"
                target="_blank"
                rel="noopener"
              >
                View Events Calendar
              </Button>
            </CertificationCard>
          )}
          {hasCompletedCertification && isProvider && (
            <CertificationCard
              title="Success Roundtables"
              content="Enhance your expertise and foster community while gaining valuable insights from experienced providers, Unyte's Clinical Advisory Board, and your peers."
            >
              <Button
                variant="contained"
                sx={{
                  marginX: 'auto',
                }}
                href="https://integratedlistening.com/resources/success-roundtable/"
                target="_blank"
                rel="noopener"
              >
                View Upcoming Roundtables
              </Button>
            </CertificationCard>
          )}
          {hasSspCertification && isProvider && (
            <CertificationCard
              title="Mentor-Led Offerings"
              content="Increase your confidence and effectiveness in SSP delivery through Unyte Mentor's live offerings."
            >
              <Button
                variant="contained"
                sx={{
                  marginX: 'auto',
                }}
                href="https://integratedlistening.com/about/mentorship/"
                target="_blank"
                rel="noopener"
              >
                Connect with a Mentor
              </Button>
            </CertificationCard>
          )}
        </Grid>
      </Container>
    </Stack>
  )
}
