import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { get } from 'lodash'
import {
  IntroDialog,
  FormHelperText,
  Typography,
  Stack,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Button,
  Box,
} from 'components'
import { useLocation } from 'react-router-dom'

import ROLES from 'utils/constants/roles'
import FILES from 'utils/constants/files'
import LINKS from 'utils/constants/links'

import { setIntroVideoState, setProduct } from 'store/modules/app'
import useGetUser from '../utils/hooks/useGetUser'

export default () => {
  const dispatch = useDispatch()
  const showModal = useSelector((state) => state.app.showIntroVideo)
  const [hide, setHide] = useState(false)

  const product = useSelector((state) => {
    return state.app.product === 'Focus' ? 'ILS' : state.app.product
  })
  const firstName = useSelector((state) => get(state, 'auth.user.firstName'))
  const currentUserRoles = useSelector((state) => get(state, 'auth.user.roles', []))
  const isProvider = currentUserRoles.some((role) => ROLES.PROVIDER_ROLES.includes(role))
  const isClient = currentUserRoles.some((role) => ROLES.CLIENT_ROLES.includes(role))

  // check if user is new as we don't want old users to see the welcome screen
  const userId = useSelector((state) => get(state, 'auth.user.id', ''))

  // use hook to determine whether to show intro video
  const {
    selectedUser: {
      hasCompletedSspCertification,
      hasCompletedFocusCertification,
      sspInTrainingOnly,
      focusInTrainingOnly,
    },
  } = useGetUser()

  const { hasPaidSspSubscription, hasPaidFocusSubscription } = useSelector((state) =>
    get(state, 'organization', {})
  )
  const { hasSspProducts, hasFocusProducts } = useSelector((state) => state.ff)

  // get location
  let location = useLocation()

  useEffect(() => {
    const sspValue = localStorage.getItem(`unyteShowedIntroSSP-${userId}`)
    const focusValue = localStorage.getItem(`unyteShowedIntroFocus-${userId}`)
    const lsValue =
      localStorage.getItem(`unyteShowedIntro-${userId}`) ||
      localStorage.getItem(`unyteShowedIntro16Mar2023-${userId}`)

    // user has seen it once, return prematurely
    //   OR, page does not equal dashboard
    if (
      lsValue ||
      location.pathname !== '/' ||
      (((hasPaidSspSubscription && hasCompletedSspCertification && isProvider) ||
        (isClient && hasSspProducts)) &&
        sspValue) ||
      (((hasPaidFocusSubscription && hasCompletedFocusCertification && isProvider) ||
        (isClient && hasFocusProducts)) &&
        focusValue)
    ) {
      return
    }

    // user has not seen intro yet, show modal and and set to true
    // we need to add organization created date in here as well,
    //   because we don't want the client to see this if they already have....
    // - show video to providers that have paid and completed
    // - show video to all clients
    if (
      ((hasPaidSspSubscription &&
        hasCompletedSspCertification &&
        isProvider &&
        !sspInTrainingOnly) ||
        (isClient && hasSspProducts)) &&
      !sspValue
    ) {
      dispatch(setIntroVideoState(true))
      dispatch(setProduct('SSP'))
    } else if (
      ((hasPaidFocusSubscription &&
        hasCompletedFocusCertification &&
        isProvider &&
        !focusInTrainingOnly) ||
        (isClient && hasFocusProducts)) &&
      !focusValue
    ) {
      dispatch(setIntroVideoState(true))
      dispatch(setProduct('Focus'))
    }
    // eslint-disable-next-line
  }, [
    hasPaidSspSubscription,
    hasCompletedSspCertification,
    hasPaidFocusSubscription,
    hasCompletedFocusCertification,
  ])

  const title = (
    <>
      {isProvider && (
        <>
          Welcome {firstName}, you are {product} certified and have an active {product}{' '}
          subscription.
          <br />
        </>
      )}
      {isClient && <span>Welcome to the {product}!</span>}
    </>
  )

  let iframeTitle
  let iframeSrc

  if (isClient) {
    iframeTitle = 'client video'
    iframeSrc = FILES.introVideo[`${product.toLowerCase()}Client`]
  } else if (isProvider) {
    iframeTitle = 'provider video'
    iframeSrc = FILES.introVideo[`${product.toLowerCase()}Provider`]
  }

  const onClose = () => {
    if (hide) {
      if (
        (hasPaidSspSubscription && hasCompletedSspCertification && isProvider) ||
        (isClient && hasSspProducts)
      ) {
        localStorage.setItem(`unyteShowedIntroSSP-${userId}`, true)
      } else if (
        (hasPaidFocusSubscription && hasCompletedFocusCertification && isProvider) ||
        (isClient && hasFocusProducts)
      ) {
        localStorage.setItem(`unyteShowedIntroFocus-${userId}`, true)
      }
    }
    dispatch(setIntroVideoState(false))
  }
  const onCheckbox = () => {
    setHide(!hide)
  }

  return (
    <IntroDialog
      open={showModal}
      title={title}
      content={
        <Stack spacing={1}>
          {isProvider && (
            <Box m={3}>
              <Typography variant="body1" textAlign="center">
                Let us guide you with your onboarding.
              </Typography>
              <Typography variant="body1" textAlign="center">
                Sign up for your{' '}
                <span>
                  <a
                    href={`${LINKS.support.youcanbookme}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ color: 'var(--green)', fontWeight: 500 }}
                  >
                    one-on-one Guidepost consultation session here
                  </a>
                  !
                </span>
              </Typography>
            </Box>
          )}
          {!isProvider && (
            <FormHelperText sx={{ textAlign: 'center' }}>
              Please watch this short intro video to get started.
            </FormHelperText>
          )}
          {iframeTitle && iframeSrc && (
            <iframe
              title={iframeTitle}
              src={iframeSrc}
              style={{ width: '100%', height: '360px', border: 'none' }}
              allow="autoplay; fullscreen"
            />
          )}
          <Stack direction="row" justifyContent="space-between" pb={2} px={7}>
            <FormGroup>
              <FormControlLabel
                control={<Checkbox value={hide} onClick={onCheckbox} />}
                label="Do not show again"
              />
            </FormGroup>
            <Button color="primary" onClick={onClose} data-test="close-intro-modal">
              Close
            </Button>
          </Stack>
        </Stack>
      }
    />
  )
}
