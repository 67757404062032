/**
 * Component will look like this:
 * Total | Completed | In Progress | Sent | Completion Rate
 */
import { Card, Grid, Stack, CircularProgress, Typography, Box } from '@mui/material'
import React, { useCallback, useState } from 'react'
import { useOutletContext } from 'react-router'
import { PieChart, useDrawingArea } from '@mui/x-charts'
import { useOnValueChange } from 'utils/hooks/useOnValueChange'
import { debounce } from 'utils/debounce'

interface KpiProps {
  totalCount: number
  completedCount: number
  inProgressCount: number
  sentCount: number
  inProgressByClientCount: number
  inProgressByProviderCount: number
}

const CustomCard = ({ ...props }) => <Card elevation={0} variant="outlined" {...props} />

interface KPILayoutProps {
  title: string
  value: number | string
  color?: string
  loading?: boolean
}
const KPILayout: React.FC<KPILayoutProps> = ({ title, value, loading, color }) => {
  return (
    <Stack direction="column" spacing={3} justifyContent="center" alignItems="center">
      <Stack alignItems="center" direction="row" spacing={2}>
        {color && (
          <span
            style={{
              backgroundColor: color,
              borderRadius: '9999px',
              aspectRatio: '1/1',
              width: '1rem',
              height: '1rem',
            }}
          ></span>
        )}
        <Stack alignItems="center" direction="column">
          {loading && <CircularProgress />}
          {!loading && (
            <Typography variant="h5" fontSize="2.5rem" textAlign="center">
              {value}
            </Typography>
          )}
          <Typography
            variant="body1"
            sx={{
              display: 'flex',
              alignItems: 'center' /* Vertically center the contents */,
              justifyContent:
                'start' /* Align contents to the start, keeping the bullet and text close together */,
              gap: '0.5rem',
            }}
            textAlign="center"
          >
            {title}
          </Typography>
        </Stack>
      </Stack>
    </Stack>
  )
}

const CustomGridItem = ({ children, ...props }) => (
  <Grid item xs={3} {...props}>
    <Box sx={{ height: '4rem' }} />
    {children}
    <Box sx={{ height: '4rem' }} />
  </Grid>
)

function PieCenterLabel({ total }) {
  const { width, height, left, top } = useDrawingArea()

  // Note: SVG does not use 'rem' units, so you'll need to convert this to an equivalent in 'px' or use 'em' with respect to the base font size if possible.
  // Assuming the base font size is 16px, 1rem would be 16px and 2.5rem would be 40px.
  // Adjust the values as necessary based on your application's base font size.

  return (
    <text x={left + width / 2} y={top + height / 2} textAnchor="middle" dominantBaseline="central">
      <tspan x={left + width / 2} dy="-0.4em" style={{ fontSize: '40px' }}>
        {total}
      </tspan>
      <tspan x={left + width / 2} style={{ fontSize: '16px' }} dy="2.5em">
        Total
      </tspan>
    </text>
  )
}

// default start with everything loading
export function AssessmentsKPIs() {
  const {
    kpi,
    data,
    loading: _loading,
  }: { kpi: KpiProps; loading: boolean; data: [] } = useOutletContext()

  const [loading, setLoading] = useState<boolean | undefined>(!!_loading)
  const debouncedSetLoading = useCallback(debounce(setLoading, 1000), [setLoading])

  useOnValueChange(JSON.stringify({ _loading }), () => {
    if (_loading) {
      setLoading(true)
    } else {
      debouncedSetLoading(false)
    }
  })

  return (
    // height is hardcoded here to prevent flickering when loading data
    <Grid container direction="row" px={2}>
      <Grid
        item
        container
        component={CustomCard}
        xs={12}
        sm={12}
        md={9}
        direction="row"
        justifyContent="space-between"
      >
        <CustomGridItem container justifyContent="center" alignItems="center" pt={2}>
          {loading && <CircularProgress />}
          {!loading && (
            <PieChart
              width={150}
              height={150}
              margin={{ top: 25, right: 25, bottom: 25, left: 25 }}
              viewBox={{ x: 0, y: 0, height: 150, width: 150 }}
              slotProps={{ legend: { hidden: true } }}
              series={[
                {
                  data: [
                    {
                      id: 0,
                      value: kpi.completedCount,
                      label: 'Completed',
                      color: 'var(--completedGreen)',
                    },
                    {
                      id: 1,
                      value: kpi.inProgressByClientCount + kpi.inProgressByProviderCount,
                      label: 'In Progress',
                      color: 'var(--orange)',
                    },
                    { id: 2, value: kpi.sentCount, label: 'Sent', color: 'var(--dark-blue)' },
                  ],
                  innerRadius: 45,
                  outerRadius: 65,
                },
              ]}
            >
              <PieCenterLabel total={kpi.totalCount} />
            </PieChart>
          )}
        </CustomGridItem>
        <CustomGridItem>
          <KPILayout
            title="Completed"
            value={kpi.completedCount}
            color="var(--completedGreen)"
            loading={loading}
          />
        </CustomGridItem>
        <CustomGridItem>
          <KPILayout
            title="In Progress"
            value={kpi.inProgressByProviderCount + kpi.inProgressByClientCount}
            color="var(--orange)"
            loading={loading}
          />
        </CustomGridItem>
        <CustomGridItem>
          <KPILayout
            title="Sent"
            value={kpi.sentCount}
            color="var(--dark-blue)"
            loading={loading}
          />
        </CustomGridItem>
      </Grid>
      <Grid
        item
        container
        sx={{
          display: { xs: 'none', sm: 'none', md: 'inherit' },
          height: '100%',
        }}
        sm={3}
        alignContent="stretch"
        direction="column"
      >
        <Card
          elevation={0}
          variant="outlined"
          sx={{
            marginLeft: '1rem',
            height: '100%',
          }}
        >
          <Grid
            item
            sm={12}
            sx={{
              display: { xs: 'none', sm: 'grid' },
              px: 2,
            }}
            direction="column"
            container
          >
            <Box sx={{ height: '4rem' }} />
            <KPILayout
              title="Assessment Completion Rate"
              value={`${Math.round((kpi.completedCount / kpi.totalCount) * 100) || 0}%`}
              loading={loading}
            />
            <Stack
              sx={{
                color: 'var(--yellow)',
                height: '4rem',
                px: 1,
                pb: '0.5rem',
                display: 'flex',
              }}
              flexDirection="row"
              alignItems="center"
              justifyContent="center"
            >
              {(Math.round((kpi.completedCount / kpi.totalCount) * 100) || 0) < 70 &&
                !!data.length && (
                  <Typography
                    sx={{
                      color: 'var(--yellow)',
                    }}
                    textAlign="center"
                    variant="caption"
                  >
                    Kindly remind clients to complete assessments promptly.
                  </Typography>
                )}
            </Stack>
          </Grid>
        </Card>
      </Grid>
    </Grid>
  )
}
