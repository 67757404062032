import { React, useEffect, useState } from 'react'

import {
  Box,
  Button,
  Divider,
  Grid,
  Card as MuiCard,
  Stack,
  Typography,
  capitalize,
} from '@mui/material'

import formatAddress from 'utils/formatAddress'

import { AddressForm } from './AddressForm'
import { format } from 'date-fns'
import { DATE_FORMATS } from 'utils/constants/formats'

export const IN_PERSON_LOCATIONS_MAP = {
  Yes: 'I see clients in-person here',
  No: 'I do not see clients in-person here',
}

export function AddressList({
  inPersonSelected = false,
  handleChange,
  handleSave,
  handleCancel,
  values: addresses = [],
  onError,
  lastUpdatedAt,
}) {
  const [showForm, setShowForm] = useState(false)

  const [formIndex, setFormIndex] = useState(addresses.length)

  const handleFormSave = (form) => {
    const currentAddressForm = { ...form, isInPersonLocation: 'Yes' }
    const newAddresses =
      formIndex === addresses.length
        ? [...addresses, currentAddressForm] // add new address
        : addresses.map((address, idx) => (idx === formIndex ? currentAddressForm : address)) // update existing

    setShowForm(false)
    handleChange(null, newAddresses)
    handleSave({ addresses: newAddresses })
  }

  const handleEditAddress = (index) => () => {
    setFormIndex(index)
    setShowForm(true)
  }

  const handleAddNewAddress = () => {
    setFormIndex(addresses.length)
    setShowForm(true)
  }

  // on Load show form should be false
  useEffect(() => {
    setShowForm(false)
  }, [])

  const handleFormCancel = () => {
    setShowForm(false)
    setFormIndex(addresses.length)
    handleCancel()
  }

  const handleRemoveAddress = (index) => () => {
    if (index !== 0) {
      const newAddresses = addresses.filter((_, idx) => idx !== index)
      handleChange(null, newAddresses)
      handleSave({ addresses: newAddresses })
    } else {
      const newAddresses = [
        { ...addresses[0], isInPersonLocation: 'No' },
        ...addresses.filter((_, idx) => idx !== index),
      ]
      handleChange(null, newAddresses)
      handleSave({ addresses: newAddresses })
    }
    setShowForm(false)
  }

  return (
    <MuiCard variant="outlined">
      <Stack direction="row" alignItems="center" justifyContent="space-between" m={2} p={2}>
        <Typography id="address-info" variant="h4">
          In-Person Delivery Info
        </Typography>
        {lastUpdatedAt && (
          <Typography
            id="address-info-last-updated-at"
            variant="caption"
            sx={{ color: 'gray !important' }}
          >
            Last Updated: {format(new Date(lastUpdatedAt), DATE_FORMATS.dateAndTime)}
          </Typography>
        )}
      </Stack>
      {showForm ? (
        <AddressForm
          inPersonSelected={inPersonSelected}
          value={{
            ...addresses[formIndex],
          }}
          onSave={handleFormSave}
          onCancel={handleFormCancel}
          onRemove={handleRemoveAddress(formIndex)}
          showRemoveButton={!!addresses[formIndex]}
        />
      ) : (
        <>
          <Stack direction="column" mx={2} px={2}>
            {addresses.map((address, idx) => {
              if (!(address?.isInPersonLocation === 'Yes')) {
                return null
              }
              return (
                <Grid item key={idx}>
                  <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                    className="my-4"
                  >
                    <Box
                      sx={{ flex: 1, maxWidth: '25%' }}
                      display="flex"
                      justifyContent="flex-start"
                    >
                      <Stack direction="column">
                        {address.locationName && (
                          <Typography variant="subtitle1" fontWeight={500}>
                            {capitalize(address.locationName)}
                          </Typography>
                        )}
                      </Stack>
                    </Box>

                    <Box
                      sx={{ flex: 1, maxWidth: '45%' }}
                      display="flex"
                      justifyContent="flex-start"
                    >
                      <Typography variant="body1">{formatAddress(address)}</Typography>
                    </Box>

                    <Box sx={{ flex: 1, maxWidth: '25%' }} display="flex" justifyContent="flex-end">
                      <Button variant="text" onClick={handleEditAddress(idx)}>
                        Edit Address
                      </Button>
                    </Box>
                  </Stack>

                  <Divider />
                </Grid>
              )
            })}
          </Stack>
          <Box display="flex" justifyContent="flex-start" m={2} px={1}>
            <Button onClick={handleAddNewAddress}>Add In-Person Delivery Addresses</Button>
          </Box>
        </>
      )}
    </MuiCard>
  )
}
