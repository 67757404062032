import { gql } from '@apollo/client'

export const GET_ACTIVE_CLIENTS_BY_IDS = gql`
  query getActiveClientsByUserIds {
    getActiveClientsByUserIds
  }
`

export const PRODUCTS_QUERY = gql`
  query getProductsForClientsTable {
    getProducts {
      id
      name
      order
      category
    }
  }
`

export const USER_QUERY_WITH_SLOTS = gql`
  query getUsersForClientsTableWithSlots(
    $filter: FilterUsersInput
    $sort: [[String!]]
    $limit: Int
    $offset: Int
    $includeCount: Boolean
  ) {
    getUsers(
      filter: $filter
      sort: $sort
      limit: $limit
      offset: $offset
      includeCount: $includeCount
    ) {
      address1
      address2
      birthYear
      city
      country
      createdAt
      dob
      email
      firstName
      fullName
      gender
      id
      isArchived
      isSuspended
      lastLoginAt
      lastName
      phone
      seats {
        id
        status
        productId
      }
      slots {
        id
        status
        category
        expiredAt
        assignedAt
        usedAt
      }
      state
      roles
      zip
      productPreferences
      provider {
        id
        fullName
      }
    }
  }
`

export const GET_AVAILABLE_SLOTS = gql`
  query GetAvailableSlots_ClientTable($filter: FilterSlotsInput) {
    getSlots(filter: $filter) {
      assignedAt
      category
      createdAt
      expiredAt
      id
      organizationId
      status
      type
      usedAt
      usedBySessionId
      userId
      updatedAt
    }
  }
`
