import { useState, useEffect } from 'react'
import { get, keys } from 'lodash'
import deepTrim from '../../utils/deepTrim'

export const validators = {
  required: (val, args) => !!val && !!val.length,
  email: (val, args) => {
    if (!val) {
      return true
    }

    // eslint-disable-next-line
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(val)
  },
  len: (val, [min, max]) => {
    const len = (val || '').length
    return (!min || len >= min) && (!max || len <= max)
  },
  password: (val, args) => {
    if (!val) {
      return true
    }
    return validators.len(val, [8]) && /[0-9]/.test(val) && /[a-z]/.test(val)
  },
  phone: (val, args) => {
    if (!val) {
      return true
    }
    // eslint-disable-next-line
    return /^\+{0,1}[\s0-9\-\(\)]*$/.test(val)
  },
  equal: (val, args, form) => {
    return val === form[args.field]
  },
  notEqual: (val, args, form) => {
    return val !== form[args.field]
  },
  custom: (val, _args) => {
    const { fn: _fn, ...args } = _args
    const fn = _fn || (() => true)
    return fn(val, args)
  },
}

export default ({ data, validation: _validation = {}, onSubmit: _onSubmit }) => {
  const [form, setFormData] = useState(data)

  const [trimmedForm, setTrimmedForm] = useState(data)

  const [validation] = useState(_validation)
  const [errors, setErrors] = useState({})
  const [isValid, setIsValid] = useState(false)

  const reset = () => {
    setFormData(data)
    setErrors({})
  }

  const checkIsValid = () => {
    const isValid = keys(form)
      .filter((key) => !!validation[key])
      .every((key) =>
        keys(validation[key]).every((validatorKey) => {
          const { args } = validation[key][validatorKey]
          const val = form[key]

          const check = validators[validatorKey]
          const valid = check(val, args, form)
          return valid
        })
      )
    setIsValid(isValid)
  }

  const validateField = (key, formData = form) => {
    const shouldValidate = !!validation[key]
    if (!shouldValidate) {
      return
    }

    const frontEndErrors = keys(validation[key])
      .map((validatorKey) => {
        const { args, msg } = validation[key][validatorKey]

        const check = validators[validatorKey]
        const valid = check(formData[key], args, form)
        return valid ? null : msg
      })
      .filter(Boolean)

    if (frontEndErrors.length) {
      setErrors(({ ...old }) => ({ ...old, [key]: frontEndErrors.join(' ') }))
    } else {
      setErrors(({ [key]: validField, ...old }) => ({ ...old }))
    }
  }

  const setFormValue = (key, { path = 'target.value' } = {}, overrideValues) => (e) => {
    if (overrideValues) {
      setFormData({ ...form, ...overrideValues })
      return
    }
    const val = path === '' ? e : get(e, path)

    const nextForm = { ...form, [key]: val }
    setFormData(nextForm)
    validateField(key, nextForm)
  }

  const [pending, setPending] = useState(false)
  const onSubmit = async (...args) => {
    setPending(true)
    try {
      await _onSubmit(...args)
    } catch (err) {
      if (err?.graphQLErrors) {
        const gqlErrors = err.graphQLErrors || []
        const validationErrors = gqlErrors
          .reduce((res, item) => {
            return get(item, 'extensions.exception.errors')
              ? res.concat(item.extensions.exception.errors)
              : res.concat({
                  message: item.message,
                })
          }, [])
          .reduce((res, err) => {
            const path = Object.keys(form).includes(err.path) ? err.path : 'none'
            const old = res[path] || ''
            res[path] = old + err.message
            return res
          }, {})
        setErrors(validationErrors)
      } else {
        const none = err.message
        setErrors({ none })
      }
    } finally {
      setPending(false)
    }
  }

  useEffect(() => {
    setTrimmedForm(deepTrim(form))
  }, [form])
  // eslint-disable-next-line
  useEffect(checkIsValid, [form])
  // eslint-disable-next-line
  useEffect(checkIsValid, [])

  return {
    form,
    trimmedForm,
    setFormValue,
    setFormData,
    checkIsValid,
    onSubmit,
    pending,
    errors,
    setErrors,
    isValid,
    validateField,
    reset,
  }
}
