/**
 *
 * @param {string} name
 * @returns a string with the error
 */
const validateName = (type, name) => {
  const types = {
    firstName: 'First Name',
    lastName: 'Last Name',
  }
  if (!name?.trim()) {
    return `${types[type]} is required`
  } else if (name.length > 100) {
    return `${types[type]} must be no longer than 100 characters`
  }
  return ''
}

/**
 * @param {string} phoneNumber
 * @returns a boolean whether it is a valid phone number or not
 */
function isValidPhoneNumber(str) {
  // The following regular expression matches strings that begin with an optional '+'
  // sign and then have between 7 and 15 digits (allowing for spaces, dashes or parentheses between the digits).
  if (!str) {
    return false
  }
  return /^(\+)?([0-9\s-]{7,15})$/.test(str?.replace(/[\s-()]/g, '').trim())
}

/**
 * @param {string} urlString
 * @return {boolean}
 */
function isValidURL(urlString) {
  if (!urlString) {
    return true
  }

  const pattern = new RegExp(
    '^(https?:\\/\\/)?' + // protocol
    '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
    '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
    '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
    '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
      '(\\#[-a-z\\d_]*)?$',
    'i' // fragment locator
  )
  return !!pattern.test(urlString)
}

/**
 * @param {string} bio
 * @return {string}
 */
function isValidBio(bio) {
  if (!bio) {
    return true
  }

  return bio.length <= 300
}

export { validateName, isValidPhoneNumber, isValidURL, isValidBio }
