import { React, useContext } from 'react'

import {
  Divider,
  FormControlLabel,
  Card as MuiCard,
  Radio,
  RadioGroup,
  Stack,
  Typography,
} from '@mui/material'
import { get } from 'lodash'
import { format } from 'date-fns'
import { DATE_FORMATS } from 'utils/constants/formats'
import { ProviderProfileContext } from './MyProfile'
import RemoteDeliveryRestriction from './components/RemoteDeliveryRestriction'

export function RemoteRestrictionsList({ lastUpdatedAt }) {
  const { form, handleLists, handleSave, wizardState } = useContext(ProviderProfileContext)
  const remoteRestrictions = get(form, 'remoteRestrictions', [])

  const showRestrictions = form.deliveryModels?.includes('Remote')

  const handleIsWorkingRemotelyChange = async (event) => {
    if (event.target.value === 'Yes') {
      const newValues = new Set([...form.deliveryModels, 'Remote'])
      await handleLists('deliveryModels')('', [...newValues])
      await handleSave({ deliveryModels: [...newValues] })
    } else if (event.target.value === 'No') {
      const newDeliveryModels = form.deliveryModels.filter((key) => key !== 'Remote')
      await handleLists('deliveryModels')('', newDeliveryModels)
      await handleSave({
        remoteRestrictions: [],
        deliveryModels: newDeliveryModels,
      })
    }
  }
  const isEditMode = wizardState?.includes('EDIT')

  return (
    <MuiCard variant="outlined" sx={{ pb: 4 }}>
      <Stack direction="row" alignItems="center" justifyContent="space-between" m={2} p={2}>
        <Typography id="remote-delivery-restrictions-info" variant="h4">
          Remote Delivery Info
        </Typography>
        {lastUpdatedAt && (
          <Typography
            id="remote-restrictions-last-updated-at"
            variant="caption"
            sx={{ color: 'gray !important' }}
          >
            Last Updated: {format(new Date(lastUpdatedAt), DATE_FORMATS.dateAndTime)}
          </Typography>
        )}
      </Stack>

      <Stack direction="column" sx={{ mx: 4 }}>
        <Typography variant="subtitle1" sx={{ fontWeight: 600, color: 'rgba(0, 0, 0, 0.87)' }}>
          Do you work with clients remotely?
        </Typography>
        <RadioGroup
          row
          name="is-working-remotely-radio-buttons-group"
          value={showRestrictions ? 'Yes' : 'No'}
          onChange={handleIsWorkingRemotelyChange}
        >
          <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
          <FormControlLabel value="No" control={<Radio />} label="No" />
        </RadioGroup>
      </Stack>

      {showRestrictions && (
        <Stack direction="column" mx={2} px={2} spacing={1}>
          {!isEditMode && !remoteRestrictions.length && (
            <Typography variant="subtitle1" sx={{ fontWeight: 600, color: 'rgba(0, 0, 0, 0.87)' }}>
              Specify any location restrictions for remote services, such as limited licensing to
              certain states/provinces/regions.
            </Typography>
          )}
          <Stack
            divider={remoteRestrictions.length > 1 && !isEditMode ? <Divider /> : null}
            spacing={remoteRestrictions.length > 1 && !isEditMode ? 2 : 1}
          >
            {remoteRestrictions.map((restriction, idx) => (
              <RemoteDeliveryRestriction idx={idx} restriction={restriction} />
            ))}
            <RemoteDeliveryRestriction idx={remoteRestrictions.length} restriction={{}} />
          </Stack>
        </Stack>
      )}
    </MuiCard>
  )
}
