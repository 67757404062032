/**
 *   <LineCharts/>
 *   <ScoringGuide/>
 *   <Responses/>
 *
 */
import React, { useState } from 'react'
import { Card, Link, Typography, Stack } from 'components'
import moment from 'moment'
import useGetTransformedClientData from './utils/useGetTransformedClientData'

import {
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  Legend,
  LineChart,
  Line,
} from 'recharts'
import { useSelector } from 'react-redux'
import { Navigate, useOutletContext, useParams } from 'react-router'
import LINKS from 'utils/constants/links'
import ScoringGuide from './components/reports/ScoringGuide'
import CompletionDateCard from './components/reports/CompletionDate'
import { round } from 'lodash'
import { AssessmentBackButton } from './components/assessment-back-button'
import { useLazyQuery, useMutation } from '@apollo/client'
import {
  GET_COMPLETED_ASSESSMENTS_FOR_REPORTS,
  UPDATE_ASSESSMENTS_SCORE_FROM_REPORTS,
} from './constants/gql'
import { get } from 'lodash'
import { useOnValueChange } from 'utils/hooks/useOnValueChange'
import { isEmpty } from 'utils/isEmpty'
import { getAssessmentScore } from './utils/get-assessment-score'
import { parseMetadata } from './utils/parse-metadata'
import ResponseTable from './ResponseTable'

const COLORS = [
  '#E0003C',
  '#00CD6C',
  '#A247AE',
  '#FFD147',
  '#E7770D',
  '#1637CA',
  '#FF70DE',
  '#38EEFF',
  '#577590',
  '#43aa8b',
  '#f8961e',
]

/**
 * if we don't have BBCSS, we want to show the total..
 */
const AreaChartToolTip = ({ active, payload, subScaleOrder, isBBCSS }) => {
  if (active && payload && payload.length) {
    const hasSubScale = !!subScaleOrder.length
    return (
      <Card>
        <p className="m-3 font-semibold">{moment(payload[0].payload.x).format('MMMM Do YYYY')}</p>
        {hasSubScale &&
          isBBCSS &&
          subScaleOrder.map((scale) => {
            // we should not see nan here
            const roundedScore = round(payload.find(({ name }) => name === scale)?.value, 1)
            const nanFilteredRoundedScore = isNaN(roundedScore) ? '-' : roundedScore
            return (
              <p className="m-3" key={`${scale}-${payload.x}`}>
                {scale}: {nanFilteredRoundedScore}
              </p>
            )
          })}

        {hasSubScale && !isBBCSS && (
          <p className="m-3" key={`total-${payload.x}`}>
            Total:{' '}
            {subScaleOrder.reduce(
              (a, scale) => a + round(payload.find(({ name }) => name === scale)?.value, 1),
              0
            )}
          </p>
        )}
        {!hasSubScale &&
          payload.map(({ name, payload }, index) => (
            <p className="m-3" key={`${payload.x}${index}`}>
              {name}: {payload[name]}
            </p>
          ))}
      </Card>
    )
  }

  return null
}

const CustomizedLegend = ({ subScaleOrder, payload }) => {
  return (
    <Stack component="ul" spacing={2} direction="row">
      {!subScaleOrder.length &&
        payload.map((entry, index) => (
          <Stack
            key={`item-${index}`}
            component="li"
            spacing={2}
            direction="row"
            alignItems="center"
          >
            <div
              style={{
                backgroundColor: entry?.color,
                width: '30px',
                height: '30px',
                borderRadius: '9999px',
              }}
            />
            <Typography variant="body2">{entry?.value}</Typography>
          </Stack>
        ))}
      {!!subScaleOrder.length &&
        subScaleOrder?.map((subscale, index) => {
          const entry = payload.find(({ dataKey }) => dataKey === subscale)
          return (
            <Stack
              key={`item-${index}`}
              component="li"
              spacing={2}
              direction="row"
              alignItems="center"
            >
              <div
                style={{
                  backgroundColor: entry?.color,
                  width: '30px',
                  height: '30px',
                  borderRadius: '9999px',
                }}
              />
              <Typography variant="body2">{entry?.value}</Typography>
            </Stack>
          )
        })}
    </Stack>
  )
}

/**
 * 1. useQuery to get the latest completed data
 * 2. if we don't have mean/ median score then calculate and save into metadata for next time
 * TODO:
 * - refactor out useGetTransformedClientData
 * - getLinePlot
 */
export default function Reports() {
  const { userId } = useParams()
  const { loading } = useOutletContext()
  const { getLinePlot } = useGetTransformedClientData()

  // redux variables
  const filterAssessmentName = useSelector((state) => state.assessments.selectedAssessmentName)
  const filterProductEventId = useSelector((state) =>
    get(state, 'assessments.filter.productEventId', null)
  )
  const productEvents = useSelector((state) => state.assessments.productAssessments)
  const metadata = get(
    productEvents.find(({ id }) => id === filterProductEventId),
    'metadata',
    {}
  )

  // state variables
  const [completedAssessments, setCompletedAssessments] = useState([])
  const [tooltipPosition, setTooltipPosition] = useState({ x: 0, y: 0 })

  // gql
  const [loadAssessments] = useLazyQuery(GET_COMPLETED_ASSESSMENTS_FOR_REPORTS)
  const [updateAssessment] = useMutation(UPDATE_ASSESSMENTS_SCORE_FROM_REPORTS)

  useOnValueChange(JSON.stringify({ userId, filterProductEventId, productEvents }), async () => {
    if (userId && filterProductEventId && productEvents) {
      const { data } = await loadAssessments({
        variables: {
          filter: {
            userIds: parseInt(userId),
            types: 'answers',
            status: 'Completed',
          },
          sort: [['updatedAt', 'DESC']],
        },
      })
      // for each completed assessments, check their score, if it is not present
      const filteredCompletedAssessments = get(data, 'getSessions', []).filter(
        ({ productEventId }) => productEventId === filterProductEventId
      )
      const completedAssessmentsWithScores = await filteredCompletedAssessments.map(
        (assessment) => {
          const hasScore = !isEmpty(get(assessment, 'data.score', {}))
          const status = get(assessment, 'data.status', {})
          if (!hasScore && status === 'Completed') {
            const answers = assessment?.data.answers
            const score = getAssessmentScore(answers, metadata)
            updateAssessment({
              variables: { session: { id: assessment.id, score } },
            })
            return { ...assessment, data: { ...assessment.data, score } }
          }
          return assessment
        }
      )
      setCompletedAssessments(completedAssessmentsWithScores)
    }
  })

  // URL should ALWAYS have a userID when we get here or else return
  if (!userId && !filterProductEventId) {
    return <Navigate to={'/assessments'} />
  }

  const { data, scales = [] } = getLinePlot(completedAssessments, metadata)
  const subScores = data.sort((a, b) => b.x - a.x)
  const { isIntakeForm, isBBCSS, meanMax, subScaleOrder, maximumScore } = parseMetadata(metadata)

  const lastUpdatedAssessment = get(completedAssessments, '[0]', {})
  return (
    <>
      <AssessmentBackButton />
      {isIntakeForm && !loading && (
        <>
          <Typography
            paragraph
            variant="body1"
            fontWeight={500}
            component="p"
            pt={10}
            px={15}
            textAlign="center"
          >
            Please use 'View Responses' option under <Link to={'/assessments'}>Assessments</Link> to
            view your client’s intake form responses.
          </Typography>
          <Typography className="text-link" variant="body1" component="p" textAlign="center">
            Still having trouble?{' '}
            <a
              className="underline pr-1 text-base"
              target="_blank"
              rel="noopener noreferrer"
              href={LINKS.support.homepageUrl}
            >
              We’re always here to help
            </a>
          </Typography>
        </>
      )}
      {!isIntakeForm && !loading && !completedAssessments.length && (
        <>
          <Typography
            paragraph
            variant="body1"
            component="p"
            fontWeight={500}
            textAlign="center"
            pt={10}
            px={15}
          >
            We didn't find any completed assessments associated with the client(s), assessment,
            and/or dates you've selected. Please try a different combination of filters or fill out
            a New Assessment.
          </Typography>
          <Typography className="text-link" variant="body1" component="p" textAlign="center">
            Still having trouble?{' '}
            <a
              className="underline pr-1 text-base"
              target="_blank"
              rel="noopener noreferrer"
              href={LINKS.support.homepageUrl}
            >
              We’re always here to help
            </a>
          </Typography>
        </>
      )}
      {!isIntakeForm && !loading && !!completedAssessments.length && (
        <>
          <CompletionDateCard assessment={lastUpdatedAssessment} metadata={metadata} />
          <Typography textAlign="center" pt={3} variant="h5">
            {filterAssessmentName}
          </Typography>
          <ResponsiveContainer width="95%" height="50%" debounce={300}>
            <LineChart
              width={730}
              height={250}
              data={subScores}
              margin={{
                top: 20,
                right: 20,
                bottom: 20,
                left: 20,
              }}
              onMouseMove={(e) => {
                const x = e.chartX
                const y = e.chartY
                const newX = x > 365 ? x - 120 : x // Adjusting 120 pixels left if more than half the chart width
                const newY = y > 125 ? y - 60 : y + 20 // Adjust up or down based on the height
                setTooltipPosition({ x: newX, y: newY })
              }}
            >
              <XAxis
                dataKey="x"
                name="Time"
                type="number"
                domain={['auto', 'auto']}
                tickFormatter={(unixTime) => moment(unixTime).format('MMM DD, YYYY')}
                label={{ value: 'Date', position: 'bottom' }}
                minTickGap={30}
              />
              <YAxis
                type="number"
                dataKey="y"
                name={subScaleOrder?.length && isBBCSS ? 'Mean Score' : 'Score (Total)'}
                domain={[0, subScaleOrder?.length && isBBCSS ? meanMax : maximumScore]}
                label={{
                  value: subScaleOrder?.length && isBBCSS ? 'Mean Score' : 'Score (Total)',
                  angle: -90,
                  position: 'insideLeft',
                }}
                allowDataOverflow={true}
              />
              <CartesianGrid />
              <Tooltip
                position={tooltipPosition}
                content={<AreaChartToolTip isBBCSS={isBBCSS} subScaleOrder={subScaleOrder} />}
              />
              <Legend
                layout="horizontal"
                verticalAlign="top"
                iconType="line"
                align="center"
                wrapperStyle={{
                  padding: '1rem',
                }}
                content={<CustomizedLegend subScaleOrder={subScaleOrder} />}
              />
              {scales.map((key, index) => (
                <Line
                  key={`line${index}`}
                  type="monotone"
                  dataKey={key}
                  stroke={key.includes('Total') ? '#111827' : COLORS[index]}
                  activeDot={{ r: 8 }}
                  strokeWidth={key.includes('Total') ? 4 : 2}
                  dot={{ r: 8 }}
                  legendType="rect"
                />
              ))}
            </LineChart>
          </ResponsiveContainer>

          <ScoringGuide />
          <ResponseTable
            subScores={subScores}
            assessment={completedAssessments[0]}
            completedAssessments={completedAssessments}
            metadata={metadata}
          />
        </>
      )}
    </>
  )
}
