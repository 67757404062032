/**
 * This is a page for view only assessment
 */
import { Container } from '@mui/material'
import React, { useState } from 'react'
import * as Survey from 'survey-react'
import { get } from 'lodash'
import { useMutation } from 'utils/apollo'
import Header from './components/Header'
import MainLayout from 'components/containers/main/Main'
import useGetAssessments from './utils/useGetAssessments'
import { PageLoader } from '../../components'
import { useGetUserWithoutRefresh } from '../../utils/hooks/useGetUser'
import { useDispatch, useSelector } from 'react-redux'
import { useSnackbar } from 'notistack'
import { useNavigate, useParams, useLocation, matchPath } from 'react-router-dom'
import { ACTIONS, INTAKE_CSS, SURVEY_CSS } from './utils/constants'
import 'survey-react/modern.css'
import './styles/survey.css'
import { setAssessmentStatus } from '../../store/modules/sessions'
import { autoSaveAssessment } from '../../store/modules/assessments'
import { useGetAssessmentSessions } from 'utils/hooks/useGetSessions'
import CloseSnackbarAction from 'components/CloseSnackbarAction'
import { ASSESSMENT_STATUS } from 'utils/constants/assessmentStatus'
import { getAssessmentScore } from './utils/get-assessment-score'
import { UPDATE_SESSION } from './constants/gql'

const useGetClientUpdateAssessments = ({ userId, sessionId }) => {
  const [updateSession, { loading: updateLoading }] = useMutation(UPDATE_SESSION)
  const { selectedUser, loading: loadingGetUser } = useGetUserWithoutRefresh(userId)
  const loading = updateLoading || loadingGetUser

  if (selectedUser && updateSession) {
    return {
      loading,
      selectedUser,
      updateSession,
    }
  } else {
    return { loading }
  }
}

export default function ClientUpdateAssessments() {
  const navigate = useNavigate()
  const location = useLocation()
  const params = useParams()

  const { IN_PROGRESS, SENT_TO_CLIENT, COMPLETED, isInProgress, isSent } = ASSESSMENT_STATUS

  const sessionId = get(params, 'sessionId', -1)
  const userId = get(params, 'userId', -1)
  const assessmentPathname = location?.pathName

  const { enqueueSnackbar } = useSnackbar()
  const dispatch = useDispatch()
  const [onClickLoading, setOnClickLoading] = useState(false)

  const assessmentSessions = useSelector((state) => get(state, 'assessments.sessions', []))
  const filterSession = assessmentSessions.find(({ id }) => id === parseInt(sessionId))
  const sessionData = get(filterSession, 'data', {})
  const productEventId = get(filterSession, 'productEventId', {})

  const productEvents = useSelector((state) => state.assessments.productAssessments)
  const selectedAssessment = productEvents.find(({ id }) => id === productEventId)

  // hooks
  const { loading: loadingGetAssessments } = useGetAssessments({})
  const { loading: loadingHook, selectedUser, updateSession } = useGetClientUpdateAssessments({
    userId,
    sessionId,
  })
  useGetAssessmentSessions()

  const loading = onClickLoading || loadingHook || loadingGetAssessments

  const onClick = (eventStatus) => async () => {
    setOnClickLoading(true)
    const currentPageNo = isInProgress(eventStatus) ? model.currentPageNo : 0
    const status =
      isSent(sessionData.status) && isInProgress(eventStatus) ? SENT_TO_CLIENT : eventStatus

    const score = status === 'Completed' ? getAssessmentScore(model.data, metadata) : undefined
    const data = { answers: { ...model.data }, status, currentPageNo, score }
    try {
      const session = {
        data: JSON.stringify(data).replace(/'/g, '`'),
        id: parseInt(sessionId),
        metadata: { userAgent: navigator.userAgent },
      }
      await updateSession({ variables: { session } })
      await dispatch(setAssessmentStatus({ sessionId, status }))
      await navigate('/')

      // after completing assessment, client will be redirected to dashboard
      await enqueueSnackbar(ACTIONS[eventStatus].message, {
        variant: ACTIONS[eventStatus].variant,
        action: CloseSnackbarAction,
      })
    } catch (error) {
      await enqueueSnackbar(ACTIONS['error'].message, {
        variant: ACTIONS['error'].variant,
        action: CloseSnackbarAction,
      })
      console.error(error)
    }
  }

  Survey.StylesManager.applyTheme('modern')
  Survey.surveyLocalization.getCurrentStrings().progressText = '{0} of {1}'

  // surveyJS does not know the correct progress text unless we specifically specify it
  const handleProgressText = (sender, options) => {
    // eslint-disable-next-line
    options.text = options.text
  }

  const metadata = get(selectedAssessment, 'metadata', {})
  const survey = get(selectedAssessment, 'metadata.survey', {})
  const newSurvey = { ...survey, title: '' }
  const title = get(selectedAssessment, 'metadata.assessmentType', '')
  const isIntakeForm = get(selectedAssessment, 'metadata.isIntakeForm', false)
  const model = new Survey.Model(newSurvey)

  const lastVisitedPage = sessionData?.currentPageNumber || sessionData?.currentPageNo
  model.showNavigationButtons = isIntakeForm && lastVisitedPage === 0 ? 'none' : 'bottom'

  const onAfterRenderQuestionInput = (sender, options) => {
    // show nav button after input
    options.htmlElement.onclick = (click) => {
      const targetClassName = get(click, 'target.className', null)
      if (targetClassName === 'sv-visuallyhidden') {
        const parsedInt = parseInt(click.target.value)
        model.data = { ...model.data, [click.target.name]: isNaN(parsedInt) ? false : parsedInt }

        // do not show navigation on intake form
        model.showNavigationButtons = isIntakeForm && lastVisitedPage === 0 ? 'none' : 'bottom'

        if (!isIntakeForm) {
          if (model.isLastPage) {
            model.doComplete()
          } else {
            model.nextPage()
          }
        } else {
          const clickValue = get(click, 'target.value', null)
          if (clickValue === 'Start') {
            model.nextPage()
          }
        }
      }
    }
  }
  const currentUserId = useSelector((state) => get(state, 'auth.user.id', -1))
  const isOwnDashboard = parseInt(userId) === currentUserId
  const tabs = [
    {
      text: isOwnDashboard ? (
        'My dashboard'
      ) : (
        <>
          <b>{selectedUser.fullName}</b> {!!selectedUser.email && <>({selectedUser.email})</>}
        </>
      ),

      url: isOwnDashboard ? '/' : `/dashboard/${userId}`,
      isActive: (pathname) =>
        matchPath({ path: isOwnDashboard ? '/' : `/dashboard/${userId}` }, pathname),
    },
    {
      text: 'View Assessment',
      url: assessmentPathname,
      isActive: (pathname) => matchPath({ path: assessmentPathname }, pathname),
    },
  ]

  const autoSave = async (event) => {
    model.showNavigationButtons = model.isFirstPage && isIntakeForm ? 'none' : 'bottom'
    try {
      const surveyData = get(event, 'valuesHash', {})
      const score = getAssessmentScore(surveyData, metadata)
      const data = {
        answers: { ...surveyData },
        status: IN_PROGRESS,
        currentPageNo: model.currentPageNo,
        score,
      }
      const session = {
        data: JSON.stringify(data).replace(/'/g, '`'),
        id: parseInt(sessionId),
        metadata: { userAgent: navigator.userAgent },
      }
      await dispatch(autoSaveAssessment({ session }))
    } catch (err) {
      enqueueSnackbar('Unable to save assessment, please refresh page and try again', {
        variant: 'error',
        action: CloseSnackbarAction,
      })
      console.error(err)
    }
  }

  return (
    <MainLayout title="View Assessment" tabs={tabs} hideHeader>
      <Container className="py-5">
        {loading && <PageLoader />}
        {!loading && (
          <>
            <Header title={title} onSave={onClick(IN_PROGRESS)} data={sessionData} />
            <Survey.Survey
              model={model}
              data={sessionData?.answers}
              mode={isSent(sessionData?.status) ? 'default' : 'display'}
              onComplete={onClick(COMPLETED)}
              questionsOnPageMode="questionPerPage"
              currentPageNo={lastVisitedPage}
              onAfterRenderQuestionInput={onAfterRenderQuestionInput}
              css={isIntakeForm ? INTAKE_CSS : SURVEY_CSS}
              onCurrentPageChanged={autoSave}
              onProgressText={handleProgressText}
            />
          </>
        )}
        <div style={{ height: '100px' }} />
      </Container>
    </MainLayout>
  )
}
