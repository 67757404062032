import React, { useState } from 'react'
import { Button, Card, Grid, MenuItem, Select, Stack, TextField, Typography } from 'components'
import { differenceInHours, addHours, startOfHour } from 'date-fns'
import { useSnackbar } from 'notistack'
import CloseSnackbarAction from 'components/CloseSnackbarAction'
import ConnecteeSubmissionFields from '../shared/ConnecteeSubmissionFields'
import { SENT_TO_PROVIDER_DECLINE_REASONS } from '../shared/rejectionReasons'
import { useNavigate } from 'react-router'
import { UPDATE_CONNECTEE_MATCH } from '../shared/queries'
import { useMutation } from '@apollo/client'
import CardHeader from 'views/purchase/components/PurchaseCardHeader'
import { Chip } from '@mui/material'
import { useDispatch } from 'react-redux'
import { setFF } from 'store/modules/ff'
import { DECLINED, ACCEPTED } from '../shared/constants'
import ClientConnectionsAvatar from 'components/avatar/ClientConnectionAvatar'

const STATE_COLOR = '#3784AD'

function calculateHoursLeft(isoTimestamp) {
  const currentDate = new Date()
  //  The expiration date is set for two days, but we schedule the function to start at the beginning of every next hour.
  //  This is why we add 48 + 1 hours
  const futureDate = startOfHour(addHours(new Date(isoTimestamp), 49))

  const hoursLeft = differenceInHours(futureDate, currentDate)

  if (hoursLeft > 1) {
    return `${hoursLeft} hours left to respond`
  } else if (hoursLeft === 1) {
    return '1 hour left to respond'
  } else {
    return 'Less than an hour left to respond'
  }
}

export default function SentToProviderCards({ connecteeMatch, setConnecteeMatch, setLoading }) {
  const [showRejectionReasons, setShowRejectionReasons] = useState(false)
  const [rejectionReason, setRejectionReason] = useState('')
  const [otherRejectionReason, setOtherRejectionReason] = useState()
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const isDisabledDeclineButton =
    !rejectionReason || (rejectionReason === 'Other' && !otherRejectionReason?.length)

  const [updateConnecteeMatch] = useMutation(UPDATE_CONNECTEE_MATCH, {
    onCompleted: (data) => {
      if (data?.updateConnecteeMatch) {
        setConnecteeMatch(data?.updateConnecteeMatch)
      }
    },
  })

  const { enqueueSnackbar } = useSnackbar()

  const acceptConnecteeSubmission = async () => {
    try {
      setLoading(true)
      await updateConnecteeMatch({
        variables: {
          connecteeMatch: { id: connecteeMatch.id, status: ACCEPTED },
        },
      })
    } catch (err) {
      console.error(err)
      enqueueSnackbar('Failed to accept client. Please try again', {
        variant: 'error',
        action: CloseSnackbarAction,
      })
    } finally {
      setLoading(false)
    }
  }

  const rejectConnecteeSubmission = async () => {
    try {
      setLoading(true)
      await updateConnecteeMatch({
        variables: {
          connecteeMatch: {
            id: connecteeMatch.id,
            status: DECLINED,
            rejectReason: rejectionReason,
            otherRejectReason: otherRejectionReason,
          },
        },
      })
      dispatch(setFF({ showClientConnectionsBadge: false }))

      navigate('/connections/history')
    } catch (err) {
      console.error(err)
      enqueueSnackbar('Failed to reject client. Please try again', {
        variant: 'error',
        action: CloseSnackbarAction,
      })
    } finally {
      setLoading(false)
    }
  }

  const cancelReject = () => {
    setRejectionReason()
    setOtherRejectionReason()
    setShowRejectionReasons(false)
  }

  const handleRejectionReasonChange = (event) => {
    setRejectionReason(event.target.value)
  }

  const handleOtherRejectionReasonChange = (event) => {
    setOtherRejectionReason(event.target.value)
  }

  return (
    <Grid container>
      <Grid item xs={12} md={6}>
        <Stack spacing={2}>
          <Card sx={{ backgroundColor: '#acd8c4' }}>
            <CardHeader
              title="NEW POTENTIAL CLIENT"
              subheader={
                <Typography sx={{ color: STATE_COLOR }}>
                  Please let us know within 48 hours if you're interested in contacting this client.
                </Typography>
              }
              avatar={<ClientConnectionsAvatar />}
              titleTypographyProps={{
                color: STATE_COLOR,
                variant: 'h5',
              }}
              sx={{ p: 2, m: 0 }}
              action={
                <Stack direction="column" m={1.2} alignItems="center">
                  <Typography variant="body2" color={STATE_COLOR} fontWeight={500}>
                    Remaining Time
                  </Typography>
                  <Chip
                    mt={0.5}
                    sx={{ backgroundColor: STATE_COLOR }}
                    label={
                      <Typography variant="body2" color="#fff" fontWeight={500}>
                        {calculateHoursLeft(connecteeMatch.createdAt)}
                      </Typography>
                    }
                  />
                </Stack>
              }
            />
          </Card>

          <ConnecteeSubmissionFields connecteeMatch={connecteeMatch} />

          <Card>
            <Stack direction="column" padding={2} alignItems="center">
              {!showRejectionReasons && (
                <Stack direction="column">
                  <Typography variant="h5">
                    Would you like to contact this client to determine whether they are a fit for
                    your practice?
                  </Typography>
                  <Stack
                    className="w-full"
                    spacing={2}
                    flexDirection="row"
                    justifyContent="space-evenly"
                    alignItems="flex-end"
                  >
                    <Button
                      variant="contained"
                      color="secondary"
                      onClick={() => setShowRejectionReasons(true)}
                    >
                      No, thank you
                    </Button>
                    <Button variant="contained" color="primary" onClick={acceptConnecteeSubmission}>
                      Yes, please provide their contact info
                    </Button>
                  </Stack>
                </Stack>
              )}

              {showRejectionReasons && (
                <Stack direction="column">
                  <Typography variant="h5">Why are you declining this potential match?</Typography>
                  <Select
                    className="w-full"
                    labelId="reject-connectee-select-label"
                    id="reject-connectee-select-label"
                    value={rejectionReason || ''}
                    renderValue={() => rejectionReason}
                    onChange={handleRejectionReasonChange}
                    label="Rejection Reason"
                    variant="standard"
                  >
                    {SENT_TO_PROVIDER_DECLINE_REASONS.map(({ key, additionalInfo }) => (
                      <MenuItem key={key} value={key}>
                        <Typography fontWeight={500}>{key}:</Typography>
                        &nbsp;
                        <Typography color="textSecondary">{additionalInfo}</Typography>
                      </MenuItem>
                    ))}
                  </Select>
                  <Stack
                    spacing={2}
                    flexDirection="row"
                    justifyContent="space-between"
                    alignItems="flex-end"
                  >
                    <TextField
                      onChange={handleOtherRejectionReasonChange}
                      label={rejectionReason === 'Other' ? 'Other Reason' : 'Additional info'}
                    />
                  </Stack>
                  <Stack
                    spacing={2}
                    flexDirection="row"
                    justifyContent="space-between"
                    alignItems="flex-end"
                  >
                    <Button variant="contained" color="secondary" onClick={cancelReject}>
                      Reconsider this Match
                    </Button>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={rejectConnecteeSubmission}
                      disabled={isDisabledDeclineButton}
                    >
                      Decline this Match
                    </Button>
                  </Stack>
                </Stack>
              )}
            </Stack>
          </Card>
        </Stack>
      </Grid>
    </Grid>
  )
}
