import React from 'react'
import { BulletLink, DashboardCard, Typography } from './DashboardCard2'
import EMAILS from 'utils/constants/emails'

export default function CompleteTrainingCard({
  type = '',
  isSspCompletedUser,
  isFocusCompletedUser,
  isBilling,
  ...gridProps
}) {
  const isCompletedUser = isSspCompletedUser || isFocusCompletedUser
  const title = isCompletedUser
    ? `You are ${type} certified!`
    : `A Provider in your Organization is ${type} Certified!`

  return (
    <DashboardCard
      {...gridProps}
      key={`${type.toLowerCase()}-certified-providers`}
      title={title}
      dataTestLabel={`dashboard-card-${type}-certified-non-billing`}
    >
      {isCompletedUser && (
        <Typography>Congratulations on obtaining your {type} certification!</Typography>
      )}
      {isBilling && (
        <>
          <Typography pt={4}>
            Please purchase an {type} subscription to begin delivering {type} to clients.
          </Typography>
          <BulletLink internalURI={`/store/${type.toLowerCase()}`}>
            for {type} Subscription purchase options.
          </BulletLink>
        </>
      )}
      {!isBilling && (
        <Typography pt={4}>
          For assistance, please contact our Client Success team at{' '}
          <a className="text-link" href={`mailto:${EMAILS.supportEmail}`}>
            {EMAILS.supportEmail}
          </a>
          .
        </Typography>
      )}
    </DashboardCard>
  )
}
